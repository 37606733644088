import {
  createStyles,
  Grid,
  Switch,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    selectedOption: {
      fontWeight: 800
    },
    container: {
      width: '100%',
      margin: 0
    }
  });

interface Props extends WithStyles<typeof styles> {
  offLabel?: string;
  onLabel?: string;
  setToggle: React.Dispatch<any>;
  switchColor: 'primary' | 'secondary';
  toggle: boolean;
}

const LabelledSwitch = ({
  classes,
  offLabel,
  onLabel,
  setToggle,
  switchColor,
  toggle
}: Props) => {
  return (
    <Typography component="div" variant="body1">
      <Grid
        container
        alignItems="center"
        justify="center"
        spacing={1}
        className={classes.container}
      >
        <Grid item className={!toggle ? classes.selectedOption : ''}>
          {offLabel}
        </Grid>
        <Grid item>
          <Switch
            checked={toggle}
            onChange={() => setToggle(!toggle)}
            value="checkedC"
            color={switchColor}
          />
        </Grid>
        <Grid item className={toggle ? classes.selectedOption : ''}>
          {onLabel}
        </Grid>
      </Grid>
    </Typography>
  );
};

export default withStyles(styles)(LabelledSwitch);
