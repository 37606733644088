import Auth from '@aws-amplify/auth';
import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import * as log from 'loglevel';
import { updateCustomer } from '../graphql/mutations';
import { urlPatternValidation } from './DataHelper';

export type OnStateChange = undefined | ((newState: string, user: any) => {});

interface CheckContactProps {
  onStateChange: OnStateChange;
  user: any;
}
export const checkContact = ({ onStateChange, user }: CheckContactProps) => {
  if (!onStateChange) return;
  if (!Auth || typeof Auth.verifiedContact !== 'function') {
    throw new Error(
      'No Auth module found, please ensure @aws-amplify/auth is imported'
    );
  }
  Auth.verifiedContact(user).then(data => {
    if (!isEmpty(data.verified)) {
      onStateChange('signedIn', user);
    } else {
      user = Object.assign(user, data);
      onStateChange('verifyContact', user);
    }
  });
};

export const signUpNavigate = (onStateChange: OnStateChange) => {
  if (!onStateChange) return;
  onStateChange('signUp', {});
};

export const signInNavigate = (onStateChange: OnStateChange) => {
  if (!onStateChange) return;
  onStateChange('signIn', {});
};

export const forgotPasswordNavigate = (onStateChange: OnStateChange) => {
  if (!onStateChange) return;
  onStateChange('forgotPassword', {});
};

export const changePasswordNavigate = (onStateChange: OnStateChange) => {
  if (!onStateChange) return;
  onStateChange('changePassword', {});
};

interface SignInProps {
  username: string;
  password: string;
  onStateChange: OnStateChange;
  setAlertUsername: React.Dispatch<string>;
  setAlertPassword: React.Dispatch<string>;
  setLoading: React.Dispatch<any>;
  setPageError: React.Dispatch<any>;
}
export const signInAction = async (props: SignInProps) => {
  const {
    username,
    password,
    onStateChange,
    setAlertUsername,
    setAlertPassword,
    setLoading,
    setPageError
  } = props;
  let signInError = false;
  if (username.length <= 0) {
    setAlertUsername('Please enter a valid email address');
    signInError = true;
  }
  if (password.length <= 0) {
    setAlertPassword('Password is a required field');
    signInError = true;
  }
  if (signInError || !onStateChange) return;
  if (!Auth || typeof Auth.signIn !== 'function') {
    throw new Error(
      'No Auth module found, please ensure @aws-amplify/auth is imported'
    );
  }
  setLoading(true);
  try {
    const user = await Auth.signIn(username, password);
    log.debug(user);
    if (
      user.challengeName === 'SMS_MFA' ||
      user.challengeName === 'SOFTWARE_TOKEN_MFA'
    ) {
      log.debug('confirm user with ' + user.challengeName);
      onStateChange('confirmSignIn', user);
    } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      log.debug('require new password', user.challengeParam);
      onStateChange('requireNewPassword', user);
    } else if (user.challengeName === 'MFA_SETUP') {
      log.debug('TOTP setup', user.challengeParam);
      onStateChange('TOTPSetup', user);
    } else if (
      user.challengeName === 'CUSTOM_CHALLENGE' &&
      user.challengeParam &&
      user.challengeParam.trigger === 'true'
    ) {
      log.debug('custom challenge', user.challengeParam);
      onStateChange('customConfirmSignIn', user);
    } else {
      checkContact({ onStateChange, user });
    }
  } catch (err) {
    if (
      err.code === 'InvalidParameterException' &&
      err.message === 'Username should be an email.'
    ) {
      setAlertUsername('Please enter a valid email address');
    } else if (err.code === 'UserNotConfirmedException') {
      log.debug('the user is not confirmed');
      setPageError('Your user is not confirmed');
      onStateChange('confirmSignUp', { username });
    } else if (err.code === 'PasswordResetRequiredException') {
      log.debug('the user requires a new password');
      setPageError('Your password will need to be changed');
      onStateChange('forgotPassword', { username });
    } else {
      log.error('Login error', err);
      setPageError(err.message);
    }
  } finally {
    setLoading(false);
  }
};

interface SignUpProps {
  username: string;
  password: string;
  acceptTerms: boolean;
  onStateChange: OnStateChange;
  setAlertUsername: React.Dispatch<string>;
  setAlertPassword: React.Dispatch<string>;
  setAlertAcceptTerms: React.Dispatch<string>;
  setLoading: React.Dispatch<any>;
  setPageError: React.Dispatch<any>;
}
export const signUpAction = async (props: SignUpProps) => {
  const {
    username,
    password,
    acceptTerms,
    onStateChange,
    setAlertUsername,
    setAlertPassword,
    setAlertAcceptTerms,
    setLoading,
    setPageError
  } = props;
  let signUpError = false;
  if (username.length <= 0) {
    setAlertUsername('Please enter a valid email address');
    signUpError = true;
  }
  if (password.length < 8) {
    setAlertPassword('Password must be at least 8 characters');
    signUpError = true;
  }
  if (!acceptTerms) {
    setAlertAcceptTerms('Please accept the Privacy Policy and Terms of Use');
    signUpError = true;
  }
  if (!onStateChange) return;
  if (signUpError) {
    setPageError('One or more error has occurred.');
    return;
  }
  if (!Auth || typeof Auth.signUp !== 'function') {
    throw new Error(
      'No Auth module found, please ensure @aws-amplify/auth is imported'
    );
  }
  try {
    setLoading(true);
    const signupRequestBody = {
      username,
      password,
      attributes: {}
    };

    Auth.signUp(signupRequestBody)
      .then(data => {
        onStateChange('confirmSignUp', username);
      })
      .catch(err => {
        if (
          err.code === 'InvalidParameterException' &&
          err.message === 'Username should be an email.'
        ) {
          setAlertUsername('Please enter a valid email address');
        } else {
          log.error('Sign up error', err);
          setPageError(err.message);
        }
      });
  } catch (err) {
    log.error('Sign up error', err);
    setPageError(err.message);
  } finally {
    setLoading(false);
  }
};

interface ChangePasswordProps {
  oldPassword: string;
  newPassword: string;
  newPassword2: string;
  setAlertOldPassword: React.Dispatch<string>;
  setAlertNewPassword: React.Dispatch<string>;
  setAlertNewPassword2: React.Dispatch<string>;
  setLoading: React.Dispatch<any>;
  setPageError: React.Dispatch<any>;
  onSuccess: () => void;
  alertRef?: any;
}

interface UpdateCustomReportSettingsProps {
  client?: ApolloClient<Object>;
  customerId: string;
  companyName: string;
  logoImage: string;
  setAlertLogoImage: React.Dispatch<string>;
  setLoading: React.Dispatch<any>;
  setPageError: React.Dispatch<any>;
  onSuccess: () => void;
  alertRef?: any;
}

const changePasswordErrors: { [key: string]: string } = {
  LimitExceededException:
    'Attempt limit exceeded, please try again after some time.',
  TooManyFailedAttemptsException:
    'Too many failed attempts, please try again after some time.'
};

export const changePasswordAction = async (props: ChangePasswordProps) => {
  const {
    oldPassword,
    newPassword,
    newPassword2,
    setAlertOldPassword,
    setAlertNewPassword,
    setAlertNewPassword2,
    setLoading,
    setPageError,
    onSuccess,
    alertRef
  } = props;
  let errorState = false;
  if (oldPassword.length < 8) {
    setAlertOldPassword('Required');
    errorState = true;
  }
  if (newPassword.length < 8) {
    setAlertNewPassword('Required and must be at least 8 characters');
    errorState = true;
  }
  if (newPassword2.length < 8) {
    setAlertNewPassword2('Required and must match new password');
    errorState = true;
  }
  if (!errorState && newPassword !== newPassword2) {
    setAlertNewPassword('New Passwords must match');
    setAlertNewPassword2('New Passwords must match');
    errorState = true;
  }
  if (!errorState && oldPassword === newPassword) {
    setAlertNewPassword(
      'Your new password cannot be the same as your old password.'
    );
    errorState = true;
  }
  if (errorState) {
    setPageError('Sorry, at least one error has occurred.');
    alertRef.current.querySelector('input').focus();
    return;
  }
  if (!Auth || typeof Auth.signIn !== 'function') {
    throw new Error(
      'No Auth module found, please ensure @aws-amplify/auth is imported'
    );
  }
  setLoading(true);
  try {
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then(data => {
        onSuccess();
      })
      .catch(err => {
        log.error('Error while changing password', err);
        let errorMessage =
          changePasswordErrors[err.code] || 'Error while changing password';
        if (err.code === 'NotAuthorizedException') {
          setAlertOldPassword('Incorrect password');
        } else {
          setPageError(errorMessage);
        }
      });
  } finally {
    setLoading(false);
  }
};

export const updateCustomReportSettingsAction = async (
  props: UpdateCustomReportSettingsProps
) => {
  const {
    companyName,
    customerId,
    client,
    logoImage,
    setAlertLogoImage,
    setPageError,
    setLoading,
    onSuccess,
    alertRef
  } = props;
  let errorState = false;

  if (logoImage && !urlPatternValidation(logoImage)) {
    setAlertLogoImage('Please Enter a Valid Image URL');
    errorState = true;
  }

  if (errorState) {
    setPageError('Sorry, at least one error has occurred.');
    alertRef.current.querySelector('input').focus();
    return;
  }

  if (!client) {
    throw new Error('No Apollo Client module found');
  }

  setLoading(true);
  try {
    await client
      .mutate({
        mutation: gql(updateCustomer),
        variables: {
          input: {
            id: customerId,
            companyName,
            logoImage
          }
        }
      })
      .then(data => {
        onSuccess();
      })
      .catch(err => {
        log.error('Error while changing password', err);
        let errorMessage = 'Error while updating Custom Report Settings';
        setPageError(errorMessage);
      });
  } finally {
    setLoading(false);
  }
};
