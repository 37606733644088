import {
  Badge,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Popover,
  Theme
} from '@material-ui/core';
import { format } from 'date-fns';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { routeSingleDashboardUrl } from '../../../helpers/RouteHelper';
import { ReportContext } from '../../../hooks/ReportContext';
import {
  KeyboardArrowRightIcon,
  NotificationsIcon,
  TrendingUpIcon
} from '../../../images/Icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuPaper: {
      width: 344
    },
    menuButton: {
      marginLeft: theme.spacing(-1)
    },
    menuStorageUsage: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    menuStoragePercentage: {
      borderRadius: 2,
      marginBottom: theme.spacing(0.75),
      background: theme.palette.grey[600]
    },
    menuStoragePercentageBar: {
      borderRadius: 2
    },
    menuTierName: {
      textTransform: 'uppercase'
    },
    menuDivider: {
      paddingLeft: '100%',
      margin: theme.spacing(1.5, 0)
    },
    subheader: {
      fontSize: 20,
      color: theme.palette.grey[800],
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.grey[200]
    }
  })
);

const NotificationsMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const classes = useStyles();
  const reportContext = useContext(ReportContext);
  if (!reportContext) {
    throw new Error(
      'Header component must be used within a Report Context Provider'
    );
  }
  const { reportNotifications, removeReportNotification } = reportContext;

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const generateNotificationsClick = (dashboardId: string) => () => {
    removeReportNotification(dashboardId);
    closeMenu();
  };

  return (
    <>
      <IconButton
        aria-label="show new notifications"
        color="inherit"
        onClick={openMenu}
      >
        <Badge
          color="primary"
          badgeContent={reportNotifications.length}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <NotificationsIcon color="inherit" />
        </Badge>
      </IconButton>
      <Popover
        id="notifications-popover"
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        classes={{ paper: classes.menuPaper }}
      >
        <List
          component="nav"
          aria-labelledby="notifications-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="notifications-list-subheader"
              className={classes.subheader}
            >
              Notifications
            </ListSubheader>
          }
        >
          {reportNotifications.length > 0 ? (
            reportNotifications.map((reportNotification, notificationIndex) => (
              <ListItem
                key={`notification-item-${notificationIndex}`}
                component={Link}
                button
                to={routeSingleDashboardUrl(reportNotification.id)}
                onClick={generateNotificationsClick(reportNotification.id)}
              >
                <ListItemIcon>
                  <TrendingUpIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      {reportNotification.isNew ? 'A new' : 'The'} report{' '}
                      <b>{reportNotification.name}</b> was generated
                      {/* {reportNotification.isNew ? 'generated' : 'updated'} */}
                    </>
                  }
                  secondary={`At ${format(reportNotification.date, 'h:mm a')}`}
                />
                <KeyboardArrowRightIcon />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText>No Notifications</ListItemText>
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
};
export default NotificationsMenu;
