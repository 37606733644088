import {
  Button,
  createStyles,
  Paper,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import classnames from 'classnames';
import { get } from 'lodash';
import React, { useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { pluralizeString } from '../../../helpers/DataHelper';
import { routeAccountBilling } from '../../../helpers/RouteHelper';
import { AuthContext } from '../../../hooks/AuthContext';
import { StripeSessionContext } from '../../../hooks/UseStripeSessionApi/StripeSessionContext';

const styles = (theme: Theme) =>
  createStyles({
    card: {
      margin: theme.spacing(1),
      width: 255,
      borderRadius: 4
    },
    cardInner: {
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
      textAlign: 'center'
    },
    recommendedCard: {
      border: `1px solid ${theme.palette.grey[900]}`
    },
    recommendedText: {
      background: theme.palette.grey[900],
      color: theme.palette.common.white,
      textAlign: 'center',
      padding: theme.spacing(1),
      textTransform: 'uppercase'
    },
    planTitle: {
      marginBottom: theme.spacing(1.5)
    },
    planButton: {
      minWidth: 190,
      padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
      margin: `${theme.spacing(2)}px 0 0`,
      boxShadow: 'none'
    },
    paymentGroup: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid #979797`
    },
    paymentAmount: {
      marginRight: theme.spacing(1)
    },
    planBenefits: {
      lineHeight: 2.33,
      letterSpacing: '0.39px',
      textAlign: 'center'
    },
    capitalize: {
      textTransform: 'capitalize'
    }
  });

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  disableCta: boolean;
  setLoading: React.Dispatch<boolean>;
}

const PlanCard = ({ classes, disableCta, setLoading }: Props) => {
  const authContext = useContext(AuthContext);
  const stripeSessionContext = useContext(StripeSessionContext);
  if (!authContext) {
    throw new Error(
      'App component must be used within a Auth Context Provider'
    );
  }
  if (!stripeSessionContext) {
    throw new Error('Stripe session context is missing');
  }
  const { switchBilling } = stripeSessionContext;
  const onCtaClick = () => {
    setLoading(true);
    switchBilling(`${window.location.origin}${routeAccountBilling()}`, () => {
      setLoading(false);
    });
  };

  const { customerData } = authContext;
  const customerTierId = get(customerData, 'tierId');
  const customerCost = get(customerData, 'billingCost');
  const customerFrequency = get(customerData, 'billingFrequency');
  const capLinkedSensors = get(customerData, 'tier.deviceMaxCount');
  const capStorageGb = get(customerData, 'tier.storageLimitGb');
  const capAlerts = get(customerData, 'tier.alertMaxCount');
  const hasApiAccess = get(customerData, 'tier.hasApiAccess');
  const reportTemplate = get(customerData, 'tier.reportTemplate', 'standard');

  const paymentPeriod = customerFrequency.replace('ly', '');

  const capAlertText = capAlerts >= 0 ? capAlerts : 'Unlimited';
  let capStorage = `${capStorageGb} GB`;
  if (capStorageGb > 1000) {
    capStorage = `${capStorageGb / 1000} TB`;
  }

  return (
    <Paper
      elevation={0}
      className={classnames(classes.card, classes.recommendedCard)}
    >
      <Typography
        component="h3"
        variant="h3"
        align="center"
        className={classes.recommendedText}
      >
        Plan You Were On
      </Typography>
      <div className={classes.cardInner}>
        <Typography variant="h3" component="h3" className={classes.planTitle}>
          {customerTierId}
        </Typography>
        <div className={classes.paymentGroup}>
          <Typography
            variant="h1"
            component="h3"
            className={classes.paymentAmount}
          >
            ${customerCost}
          </Typography>
          <Typography variant="body1" component="span">
            / {paymentPeriod}
          </Typography>
        </div>
        <Typography variant="body1" className={classes.planBenefits}>
          {capLinkedSensors < 0 ? 'Unlimited' : capLinkedSensors} Linked{' '}
          {pluralizeString('Sensor', capLinkedSensors)}
          <br />
          {capStorage} Storage
          <br />
          {capAlertText} Custom {pluralizeString('Alert', capAlerts)}
          <br />
          {hasApiAccess ? 'API Access' : 'No API Access'}
          <br />
          <span className={classes.capitalize}> {reportTemplate}</span> Report
          Views
        </Typography>
        <Button
          color="primary"
          size="large"
          variant="contained"
          className={classes.planButton}
          onClick={onCtaClick}
          disabled={disableCta}
        >
          Update Billing Info
        </Button>
      </div>
    </Paper>
  );
};

export default withRouter(withStyles(styles)(PlanCard));
