/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUniqueTagList = /* GraphQL */ `
  query GetUniqueTagList {
    getUniqueTagList {
      value
    }
  }
`;
export const getFiles = /* GraphQL */ `
  query GetFiles(
    $sortingField: String
    $sortDirection: SortDirection
    $numberSort: Boolean
    $tags: [String]
    $limit: Int
    $nextCursor: String
  ) {
    getFiles(
      sortingField: $sortingField
      sortDirection: $sortDirection
      numberSort: $numberSort
      tags: $tags
      limit: $limit
      nextCursor: $nextCursor
    ) {
      nextCursor
      totalCount
      files {
        id
        user_id
        serial_number_id
        sensor_name
        sensor_type
        file_name
        file_size
        s3_bucket_name
        s3_object_key
        device_meta
        recording_length
        recording_ts
        created_ts
        modified_ts
        archived_ts
        file_uploaded
        createdAt
        updatedAt
        attributes {
          nextToken
        }
        device {
          id
          org_id
          s3_bucket_name
          serial_number_id
          status
          user_id
          customerId
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
    }
  }
`;
export const getAlert = /* GraphQL */ `
  query GetAlert($id: ID!) {
    getAlert(id: $id) {
      id
      owner
      customerId
      name
      tagName
      enabled
      createdAt
      updatedAt
      customer {
        id
        owner
        storageConsumed
        storageSpaceRemaining
        tierId
        createdAt
        updatedAt
        companyName
        logoImage
        apiKeys {
          nextToken
        }
        billing {
          id
          stripe_session_id
          owner
          stripe_customer_id
          stripe_subscription_id
          subscription_status
          stripe_plan_id
          suspended_at
          next_billing_at
          createdAt
          updatedAt
        }
        deviceAssociations {
          nextToken
        }
        tier {
          id
          costMonthly
          costYearly
          deviceMaxCount
          storageLimitGb
          alertMaxCount
          hasApiAccess
          billingCycles
          reportMaxCount
          reportTemplate
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listAlerts = /* GraphQL */ `
  query ListAlerts(
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        customerId
        name
        tagName
        enabled
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
      }
      nextToken
    }
  }
`;
export const getApiKey = /* GraphQL */ `
  query GetApiKey($id: ID!) {
    getApiKey(id: $id) {
      id
      name
      owner
      revoked
      createdAt
      updatedAt
    }
  }
`;
export const listApiKeys = /* GraphQL */ `
  query ListApiKeys(
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApiKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        revoked
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getApiKeyByOwner = /* GraphQL */ `
  query GetApiKeyByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getApiKeyByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        owner
        revoked
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAttribute = /* GraphQL */ `
  query GetAttribute($id: ID!) {
    getAttribute(id: $id) {
      id
      user_id
      attributeFileId
      name
      type
      value
      thing
      mutable
      createdAt
      updatedAt
    }
  }
`;
export const listAttributes = /* GraphQL */ `
  query ListAttributes(
    $filter: ModelAttributeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttributes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        attributeFileId
        name
        type
        value
        thing
        mutable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAttributeByUser = /* GraphQL */ `
  query GetAttributeByUser(
    $user_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelAttributeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAttributeByUser(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        attributeFileId
        name
        type
        value
        thing
        mutable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listBillings = /* GraphQL */ `
  query ListBillings(
    $filter: ModelBillingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stripe_session_id
        owner
        stripe_customer_id
        stripe_subscription_id
        subscription_status
        stripe_plan_id
        suspended_at
        next_billing_at
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
      }
      nextToken
    }
  }
`;
export const getBilling = /* GraphQL */ `
  query GetBilling($id: ID!) {
    getBilling(id: $id) {
      id
      stripe_session_id
      owner
      stripe_customer_id
      stripe_subscription_id
      subscription_status
      stripe_plan_id
      suspended_at
      next_billing_at
      createdAt
      updatedAt
      customer {
        id
        owner
        storageConsumed
        storageSpaceRemaining
        tierId
        createdAt
        updatedAt
        companyName
        logoImage
        apiKeys {
          nextToken
        }
        billing {
          id
          stripe_session_id
          owner
          stripe_customer_id
          stripe_subscription_id
          subscription_status
          stripe_plan_id
          suspended_at
          next_billing_at
          createdAt
          updatedAt
        }
        deviceAssociations {
          nextToken
        }
        tier {
          id
          costMonthly
          costYearly
          deviceMaxCount
          storageLimitGb
          alertMaxCount
          hasApiAccess
          billingCycles
          reportMaxCount
          reportTemplate
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const getBillingByStripeSessionId = /* GraphQL */ `
  query GetBillingByStripeSessionId(
    $stripe_session_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelBillingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBillingByStripeSessionId(
      stripe_session_id: $stripe_session_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stripe_session_id
        owner
        stripe_customer_id
        stripe_subscription_id
        subscription_status
        stripe_plan_id
        suspended_at
        next_billing_at
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
      }
      nextToken
    }
  }
`;
export const getBillingByStripeCustomerId = /* GraphQL */ `
  query GetBillingByStripeCustomerId(
    $stripe_customer_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelBillingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBillingByStripeCustomerId(
      stripe_customer_id: $stripe_customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stripe_session_id
        owner
        stripe_customer_id
        stripe_subscription_id
        subscription_status
        stripe_plan_id
        suspended_at
        next_billing_at
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
      }
      nextToken
    }
  }
`;
export const getBillingByStripeSubscriptionId = /* GraphQL */ `
  query GetBillingByStripeSubscriptionId(
    $stripe_subscription_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelBillingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBillingByStripeSubscriptionId(
      stripe_subscription_id: $stripe_subscription_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stripe_session_id
        owner
        stripe_customer_id
        stripe_subscription_id
        subscription_status
        stripe_plan_id
        suspended_at
        next_billing_at
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
      }
      nextToken
    }
  }
`;
export const getBillingByStripeOwner = /* GraphQL */ `
  query GetBillingByStripeOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelBillingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBillingByStripeOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stripe_session_id
        owner
        stripe_customer_id
        stripe_subscription_id
        subscription_status
        stripe_plan_id
        suspended_at
        next_billing_at
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
      }
      nextToken
    }
  }
`;
export const listCustomScripts = /* GraphQL */ `
  query ListCustomScripts(
    $filter: ModelCustomScriptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomScripts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        error {
          code
          detail
          message
          status
          transactionId
          type
        }
        lines
        status
        title
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomScript = /* GraphQL */ `
  query GetCustomScript($id: ID!) {
    getCustomScript(id: $id) {
      id
      owner
      error {
        code
        detail
        message
        status
        traces {
          filename
          lineno
          name
          offset
          text
        }
        transactionId
        type
      }
      lines
      status
      title
      type
      createdAt
      updatedAt
    }
  }
`;
export const getCustomScriptByOwner = /* GraphQL */ `
  query GetCustomScriptByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomScriptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCustomScriptByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        error {
          code
          detail
          message
          status
          transactionId
          type
        }
        lines
        status
        title
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomScriptByType = /* GraphQL */ `
  query GetCustomScriptByType(
    $type: CustomScriptType
    $sortDirection: ModelSortDirection
    $filter: ModelCustomScriptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCustomScriptByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        error {
          code
          detail
          message
          status
          transactionId
          type
        }
        lines
        status
        title
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        storageConsumed
        storageSpaceRemaining
        tierId
        createdAt
        updatedAt
        companyName
        logoImage
        apiKeys {
          nextToken
        }
        billing {
          id
          stripe_session_id
          owner
          stripe_customer_id
          stripe_subscription_id
          subscription_status
          stripe_plan_id
          suspended_at
          next_billing_at
          createdAt
          updatedAt
        }
        deviceAssociations {
          nextToken
        }
        tier {
          id
          costMonthly
          costYearly
          deviceMaxCount
          storageLimitGb
          alertMaxCount
          hasApiAccess
          billingCycles
          reportMaxCount
          reportTemplate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      owner
      storageConsumed
      storageSpaceRemaining
      tierId
      createdAt
      updatedAt
      companyName
      logoImage
      apiKeys {
        items {
          id
          name
          owner
          revoked
          createdAt
          updatedAt
        }
        nextToken
      }
      billing {
        id
        stripe_session_id
        owner
        stripe_customer_id
        stripe_subscription_id
        subscription_status
        stripe_plan_id
        suspended_at
        next_billing_at
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
      }
      deviceAssociations {
        items {
          id
          org_id
          s3_bucket_name
          serial_number_id
          status
          user_id
          customerId
          createdAt
          updatedAt
        }
        nextToken
      }
      tier {
        id
        costMonthly
        costYearly
        deviceMaxCount
        storageLimitGb
        alertMaxCount
        hasApiAccess
        billingCycles
        reportMaxCount
        reportTemplate
        createdAt
        updatedAt
        stripePlans {
          nextToken
        }
      }
    }
  }
`;
export const getCustomerByOwnerId = /* GraphQL */ `
  query GetCustomerByOwnerId(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCustomerByOwnerId(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        storageConsumed
        storageSpaceRemaining
        tierId
        createdAt
        updatedAt
        companyName
        logoImage
        apiKeys {
          nextToken
        }
        billing {
          id
          stripe_session_id
          owner
          stripe_customer_id
          stripe_subscription_id
          subscription_status
          stripe_plan_id
          suspended_at
          next_billing_at
          createdAt
          updatedAt
        }
        deviceAssociations {
          nextToken
        }
        tier {
          id
          costMonthly
          costYearly
          deviceMaxCount
          storageLimitGb
          alertMaxCount
          hasApiAccess
          billingCycles
          reportMaxCount
          reportTemplate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDashboardByOwner = /* GraphQL */ `
  query GetDashboardByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDashboardByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        customScriptId
        defaultScript
        error {
          code
          message
          status
          transactionId
          scriptError
        }
        output_logs
        lastTransactionId
        maxFiles
        status
        tags
        file_ids
        title
        createdAt
        updatedAt
        customScript {
          id
          owner
          lines
          status
          title
          type
          createdAt
          updatedAt
        }
        notes
        sequence
        modules {
          nextToken
        }
        shares {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getDashboard = /* GraphQL */ `
  query GetDashboard($id: ID!) {
    getDashboard(id: $id) {
      id
      owner
      customScriptId
      defaultScript
      error {
        code
        message
        status
        transactionId
        scriptError
      }
      output_logs
      lastTransactionId
      maxFiles
      status
      tags
      file_ids
      title
      createdAt
      updatedAt
      customScript {
        id
        owner
        error {
          code
          detail
          message
          status
          transactionId
          type
        }
        lines
        status
        title
        type
        createdAt
        updatedAt
      }
      notes
      sequence
      modules {
        items {
          id
          owner
          dashboardId
          sequence
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      shares {
        items {
          id
          dashboardId
          token
          expires
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const listDashboards = /* GraphQL */ `
  query ListDashboards(
    $filter: ModelDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        customScriptId
        defaultScript
        error {
          code
          message
          status
          transactionId
          scriptError
        }
        output_logs
        lastTransactionId
        maxFiles
        status
        tags
        file_ids
        title
        createdAt
        updatedAt
        customScript {
          id
          owner
          lines
          status
          title
          type
          createdAt
          updatedAt
        }
        notes
        sequence
        modules {
          nextToken
        }
        shares {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listDashboardModules = /* GraphQL */ `
  query ListDashboardModules(
    $filter: ModelDashboardModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboardModules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        dashboardId
        error {
          code
          message
          status
          transactionId
        }
        sequence
        title
        createdAt
        updatedAt
        dashboard {
          id
          owner
          customScriptId
          defaultScript
          output_logs
          lastTransactionId
          maxFiles
          status
          tags
          file_ids
          title
          createdAt
          updatedAt
          notes
          sequence
        }
        data {
          nextToken
        }
        frames {
          nextToken
        }
        layout {
          id
          owner
          json
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDashboardModule = /* GraphQL */ `
  query GetDashboardModule($id: ID!) {
    getDashboardModule(id: $id) {
      id
      owner
      dashboardId
      error {
        code
        message
        status
        transactionId
      }
      sequence
      title
      createdAt
      updatedAt
      dashboard {
        id
        owner
        customScriptId
        defaultScript
        error {
          code
          message
          status
          transactionId
          scriptError
        }
        output_logs
        lastTransactionId
        maxFiles
        status
        tags
        file_ids
        title
        createdAt
        updatedAt
        customScript {
          id
          owner
          lines
          status
          title
          type
          createdAt
          updatedAt
        }
        notes
        sequence
        modules {
          nextToken
        }
        shares {
          nextToken
        }
      }
      data {
        items {
          id
          owner
          moduleId
          json
          sequence
          createdAt
          updatedAt
        }
        nextToken
      }
      frames {
        items {
          id
          owner
          moduleId
          json
          sequence
          createdAt
          updatedAt
        }
        nextToken
      }
      layout {
        id
        owner
        json
        createdAt
        updatedAt
      }
    }
  }
`;
export const getDashboardModuleByDashboard = /* GraphQL */ `
  query GetDashboardModuleByDashboard(
    $dashboardId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDashboardModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDashboardModuleByDashboard(
      dashboardId: $dashboardId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        dashboardId
        error {
          code
          message
          status
          transactionId
        }
        sequence
        title
        createdAt
        updatedAt
        dashboard {
          id
          owner
          customScriptId
          defaultScript
          output_logs
          lastTransactionId
          maxFiles
          status
          tags
          file_ids
          title
          createdAt
          updatedAt
          notes
          sequence
        }
        data {
          nextToken
        }
        frames {
          nextToken
        }
        layout {
          id
          owner
          json
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDashboardModuleByDashboardSorted = /* GraphQL */ `
  query GetDashboardModuleByDashboardSorted(
    $dashboardId: ID
    $sequence: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDashboardModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDashboardModuleByDashboardSorted(
      dashboardId: $dashboardId
      sequence: $sequence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        dashboardId
        error {
          code
          message
          status
          transactionId
        }
        sequence
        title
        createdAt
        updatedAt
        dashboard {
          id
          owner
          customScriptId
          defaultScript
          output_logs
          lastTransactionId
          maxFiles
          status
          tags
          file_ids
          title
          createdAt
          updatedAt
          notes
          sequence
        }
        data {
          nextToken
        }
        frames {
          nextToken
        }
        layout {
          id
          owner
          json
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDashboardModuleData = /* GraphQL */ `
  query GetDashboardModuleData($id: ID!) {
    getDashboardModuleData(id: $id) {
      id
      owner
      moduleId
      json
      sequence
      createdAt
      updatedAt
      module {
        id
        owner
        dashboardId
        error {
          code
          message
          status
          transactionId
        }
        sequence
        title
        createdAt
        updatedAt
        dashboard {
          id
          owner
          customScriptId
          defaultScript
          output_logs
          lastTransactionId
          maxFiles
          status
          tags
          file_ids
          title
          createdAt
          updatedAt
          notes
          sequence
        }
        data {
          nextToken
        }
        frames {
          nextToken
        }
        layout {
          id
          owner
          json
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listDashboardModuleDatas = /* GraphQL */ `
  query ListDashboardModuleDatas(
    $filter: ModelDashboardModuleDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboardModuleDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        moduleId
        json
        sequence
        createdAt
        updatedAt
        module {
          id
          owner
          dashboardId
          sequence
          title
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDashboardModuleDataByDashboardModule = /* GraphQL */ `
  query GetDashboardModuleDataByDashboardModule(
    $moduleId: ID
    $sequence: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDashboardModuleDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDashboardModuleDataByDashboardModule(
      moduleId: $moduleId
      sequence: $sequence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        moduleId
        json
        sequence
        createdAt
        updatedAt
        module {
          id
          owner
          dashboardId
          sequence
          title
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDashboardModuleFrame = /* GraphQL */ `
  query GetDashboardModuleFrame($id: ID!) {
    getDashboardModuleFrame(id: $id) {
      id
      owner
      moduleId
      json
      sequence
      createdAt
      updatedAt
      module {
        id
        owner
        dashboardId
        error {
          code
          message
          status
          transactionId
        }
        sequence
        title
        createdAt
        updatedAt
        dashboard {
          id
          owner
          customScriptId
          defaultScript
          output_logs
          lastTransactionId
          maxFiles
          status
          tags
          file_ids
          title
          createdAt
          updatedAt
          notes
          sequence
        }
        data {
          nextToken
        }
        frames {
          nextToken
        }
        layout {
          id
          owner
          json
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listDashboardModuleFrames = /* GraphQL */ `
  query ListDashboardModuleFrames(
    $filter: ModelDashboardModuleFrameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboardModuleFrames(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        moduleId
        json
        sequence
        createdAt
        updatedAt
        module {
          id
          owner
          dashboardId
          sequence
          title
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDashboardModuleFrameByDashboardModule = /* GraphQL */ `
  query GetDashboardModuleFrameByDashboardModule(
    $moduleId: ID
    $sequence: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDashboardModuleFrameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDashboardModuleFrameByDashboardModule(
      moduleId: $moduleId
      sequence: $sequence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        moduleId
        json
        sequence
        createdAt
        updatedAt
        module {
          id
          owner
          dashboardId
          sequence
          title
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listDashboardModuleLayouts = /* GraphQL */ `
  query ListDashboardModuleLayouts(
    $filter: ModelDashboardModuleLayoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboardModuleLayouts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        json
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDashboardModuleLayout = /* GraphQL */ `
  query GetDashboardModuleLayout($id: ID!) {
    getDashboardModuleLayout(id: $id) {
      id
      owner
      json
      createdAt
      updatedAt
    }
  }
`;
export const getDashboardShare = /* GraphQL */ `
  query GetDashboardShare($id: ID!) {
    getDashboardShare(id: $id) {
      id
      dashboardId
      token
      expires
      createdAt
      updatedAt
      owner
      dashboard {
        id
        owner
        customScriptId
        defaultScript
        error {
          code
          message
          status
          transactionId
          scriptError
        }
        output_logs
        lastTransactionId
        maxFiles
        status
        tags
        file_ids
        title
        createdAt
        updatedAt
        customScript {
          id
          owner
          lines
          status
          title
          type
          createdAt
          updatedAt
        }
        notes
        sequence
        modules {
          nextToken
        }
        shares {
          nextToken
        }
      }
    }
  }
`;
export const listDashboardShares = /* GraphQL */ `
  query ListDashboardShares(
    $filter: ModelDashboardShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboardShares(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dashboardId
        token
        expires
        createdAt
        updatedAt
        owner
        dashboard {
          id
          owner
          customScriptId
          defaultScript
          output_logs
          lastTransactionId
          maxFiles
          status
          tags
          file_ids
          title
          createdAt
          updatedAt
          notes
          sequence
        }
      }
      nextToken
    }
  }
`;
export const getDashboardShareByOwner = /* GraphQL */ `
  query GetDashboardShareByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelDashboardShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDashboardShareByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dashboardId
        token
        expires
        createdAt
        updatedAt
        owner
        dashboard {
          id
          owner
          customScriptId
          defaultScript
          output_logs
          lastTransactionId
          maxFiles
          status
          tags
          file_ids
          title
          createdAt
          updatedAt
          notes
          sequence
        }
      }
      nextToken
    }
  }
`;
export const getDashboardShareByToken = /* GraphQL */ `
  query GetDashboardShareByToken(
    $token: String
    $sortDirection: ModelSortDirection
    $filter: ModelDashboardShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDashboardShareByToken(
      token: $token
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dashboardId
        token
        expires
        createdAt
        updatedAt
        owner
        dashboard {
          id
          owner
          customScriptId
          defaultScript
          output_logs
          lastTransactionId
          maxFiles
          status
          tags
          file_ids
          title
          createdAt
          updatedAt
          notes
          sequence
        }
      }
      nextToken
    }
  }
`;
export const getDashboardShareByDashboard = /* GraphQL */ `
  query GetDashboardShareByDashboard(
    $dashboardId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDashboardShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDashboardShareByDashboard(
      dashboardId: $dashboardId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dashboardId
        token
        expires
        createdAt
        updatedAt
        owner
        dashboard {
          id
          owner
          customScriptId
          defaultScript
          output_logs
          lastTransactionId
          maxFiles
          status
          tags
          file_ids
          title
          createdAt
          updatedAt
          notes
          sequence
        }
      }
      nextToken
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $serial_number_id: String
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDevices(
      serial_number_id: $serial_number_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        serial_number_id
        name
        description
        part_number
        hardware_revision
        firmware_revision
        last_calibration_ts
        thing_name
        thing_arn
        cert_arn
        manufacture_date
        last_uploaded_ts
        createdAt
        updatedAt
        associations {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($serial_number_id: String!) {
    getDevice(serial_number_id: $serial_number_id) {
      serial_number_id
      name
      description
      part_number
      hardware_revision
      firmware_revision
      last_calibration_ts
      thing_name
      thing_arn
      cert_arn
      manufacture_date
      last_uploaded_ts
      createdAt
      updatedAt
      associations {
        items {
          id
          org_id
          s3_bucket_name
          serial_number_id
          status
          user_id
          customerId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listDeviceAssociations = /* GraphQL */ `
  query ListDeviceAssociations(
    $filter: ModelDeviceAssociationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceAssociations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        org_id
        s3_bucket_name
        serial_number_id
        status
        user_id
        customerId
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
        device {
          serial_number_id
          name
          description
          part_number
          hardware_revision
          firmware_revision
          last_calibration_ts
          thing_name
          thing_arn
          cert_arn
          manufacture_date
          last_uploaded_ts
          createdAt
          updatedAt
        }
        files {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getDeviceAssociation = /* GraphQL */ `
  query GetDeviceAssociation($id: ID!) {
    getDeviceAssociation(id: $id) {
      id
      org_id
      s3_bucket_name
      serial_number_id
      status
      user_id
      customerId
      createdAt
      updatedAt
      customer {
        id
        owner
        storageConsumed
        storageSpaceRemaining
        tierId
        createdAt
        updatedAt
        companyName
        logoImage
        apiKeys {
          nextToken
        }
        billing {
          id
          stripe_session_id
          owner
          stripe_customer_id
          stripe_subscription_id
          subscription_status
          stripe_plan_id
          suspended_at
          next_billing_at
          createdAt
          updatedAt
        }
        deviceAssociations {
          nextToken
        }
        tier {
          id
          costMonthly
          costYearly
          deviceMaxCount
          storageLimitGb
          alertMaxCount
          hasApiAccess
          billingCycles
          reportMaxCount
          reportTemplate
          createdAt
          updatedAt
        }
      }
      device {
        serial_number_id
        name
        description
        part_number
        hardware_revision
        firmware_revision
        last_calibration_ts
        thing_name
        thing_arn
        cert_arn
        manufacture_date
        last_uploaded_ts
        createdAt
        updatedAt
        associations {
          nextToken
        }
      }
      files {
        items {
          id
          user_id
          serial_number_id
          sensor_name
          sensor_type
          file_name
          file_size
          s3_bucket_name
          s3_object_key
          device_meta
          recording_length
          recording_ts
          created_ts
          modified_ts
          archived_ts
          file_uploaded
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getDeviceAssociationBySerial = /* GraphQL */ `
  query GetDeviceAssociationBySerial(
    $serial_number_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceAssociationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeviceAssociationBySerial(
      serial_number_id: $serial_number_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        org_id
        s3_bucket_name
        serial_number_id
        status
        user_id
        customerId
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
        device {
          serial_number_id
          name
          description
          part_number
          hardware_revision
          firmware_revision
          last_calibration_ts
          thing_name
          thing_arn
          cert_arn
          manufacture_date
          last_uploaded_ts
          createdAt
          updatedAt
        }
        files {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getDeviceAssociationByUser = /* GraphQL */ `
  query GetDeviceAssociationByUser(
    $user_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceAssociationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeviceAssociationByUser(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        org_id
        s3_bucket_name
        serial_number_id
        status
        user_id
        customerId
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
        device {
          serial_number_id
          name
          description
          part_number
          hardware_revision
          firmware_revision
          last_calibration_ts
          thing_name
          thing_arn
          cert_arn
          manufacture_date
          last_uploaded_ts
          createdAt
          updatedAt
        }
        files {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      user_id
      serial_number_id
      sensor_name
      sensor_type
      file_name
      file_size
      s3_bucket_name
      s3_object_key
      device_meta
      recording_length
      recording_ts
      created_ts
      modified_ts
      archived_ts
      file_uploaded
      createdAt
      updatedAt
      attributes {
        items {
          id
          user_id
          attributeFileId
          name
          type
          value
          thing
          mutable
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        id
        org_id
        s3_bucket_name
        serial_number_id
        status
        user_id
        customerId
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
        device {
          serial_number_id
          name
          description
          part_number
          hardware_revision
          firmware_revision
          last_calibration_ts
          thing_name
          thing_arn
          cert_arn
          manufacture_date
          last_uploaded_ts
          createdAt
          updatedAt
        }
        files {
          nextToken
        }
      }
      tags {
        items {
          id
          user_id
          tagFileId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        serial_number_id
        sensor_name
        sensor_type
        file_name
        file_size
        s3_bucket_name
        s3_object_key
        device_meta
        recording_length
        recording_ts
        created_ts
        modified_ts
        archived_ts
        file_uploaded
        createdAt
        updatedAt
        attributes {
          nextToken
        }
        device {
          id
          org_id
          s3_bucket_name
          serial_number_id
          status
          user_id
          customerId
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getFileByUser = /* GraphQL */ `
  query GetFileByUser(
    $user_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFileByUser(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        serial_number_id
        sensor_name
        sensor_type
        file_name
        file_size
        s3_bucket_name
        s3_object_key
        device_meta
        recording_length
        recording_ts
        created_ts
        modified_ts
        archived_ts
        file_uploaded
        createdAt
        updatedAt
        attributes {
          nextToken
        }
        device {
          id
          org_id
          s3_bucket_name
          serial_number_id
          status
          user_id
          customerId
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getFileByS3Key = /* GraphQL */ `
  query GetFileByS3Key(
    $s3_object_key: String
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFileByS3Key(
      s3_object_key: $s3_object_key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        serial_number_id
        sensor_name
        sensor_type
        file_name
        file_size
        s3_bucket_name
        s3_object_key
        device_meta
        recording_length
        recording_ts
        created_ts
        modified_ts
        archived_ts
        file_uploaded
        createdAt
        updatedAt
        attributes {
          nextToken
        }
        device {
          id
          org_id
          s3_bucket_name
          serial_number_id
          status
          user_id
          customerId
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getFileBySerialAndUser = /* GraphQL */ `
  query GetFileBySerialAndUser(
    $user_id: String
    $serial_number_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFileBySerialAndUser(
      user_id: $user_id
      serial_number_id: $serial_number_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        serial_number_id
        sensor_name
        sensor_type
        file_name
        file_size
        s3_bucket_name
        s3_object_key
        device_meta
        recording_length
        recording_ts
        created_ts
        modified_ts
        archived_ts
        file_uploaded
        createdAt
        updatedAt
        attributes {
          nextToken
        }
        device {
          id
          org_id
          s3_bucket_name
          serial_number_id
          status
          user_id
          customerId
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getFileByUserSorted = /* GraphQL */ `
  query GetFileByUserSorted(
    $user_id: String
    $created_ts: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFileByUserSorted(
      user_id: $user_id
      created_ts: $created_ts
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        serial_number_id
        sensor_name
        sensor_type
        file_name
        file_size
        s3_bucket_name
        s3_object_key
        device_meta
        recording_length
        recording_ts
        created_ts
        modified_ts
        archived_ts
        file_uploaded
        createdAt
        updatedAt
        attributes {
          nextToken
        }
        device {
          id
          org_id
          s3_bucket_name
          serial_number_id
          status
          user_id
          customerId
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getFileBySerialSorted = /* GraphQL */ `
  query GetFileBySerialSorted(
    $serial_number_id: String
    $created_ts: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFileBySerialSorted(
      serial_number_id: $serial_number_id
      created_ts: $created_ts
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        serial_number_id
        sensor_name
        sensor_type
        file_name
        file_size
        s3_bucket_name
        s3_object_key
        device_meta
        recording_length
        recording_ts
        created_ts
        modified_ts
        archived_ts
        file_uploaded
        createdAt
        updatedAt
        attributes {
          nextToken
        }
        device {
          id
          org_id
          s3_bucket_name
          serial_number_id
          status
          user_id
          customerId
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchFiles = /* GraphQL */ `
  query SearchFiles(
    $filter: SearchableFileFilterInput
    $sort: SearchableFileSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchFiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        user_id
        serial_number_id
        sensor_name
        sensor_type
        file_name
        file_size
        s3_bucket_name
        s3_object_key
        device_meta
        recording_length
        recording_ts
        created_ts
        modified_ts
        archived_ts
        file_uploaded
        createdAt
        updatedAt
        attributes {
          nextToken
        }
        device {
          id
          org_id
          s3_bucket_name
          serial_number_id
          status
          user_id
          customerId
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getStripePlan = /* GraphQL */ `
  query GetStripePlan($id: ID!) {
    getStripePlan(id: $id) {
      id
      tierId
      billingCycle
      cost
      createdAt
      updatedAt
      tier {
        id
        costMonthly
        costYearly
        deviceMaxCount
        storageLimitGb
        alertMaxCount
        hasApiAccess
        billingCycles
        reportMaxCount
        reportTemplate
        createdAt
        updatedAt
        stripePlans {
          nextToken
        }
      }
    }
  }
`;
export const listStripePlans = /* GraphQL */ `
  query ListStripePlans(
    $filter: ModelStripePlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStripePlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tierId
        billingCycle
        cost
        createdAt
        updatedAt
        tier {
          id
          costMonthly
          costYearly
          deviceMaxCount
          storageLimitGb
          alertMaxCount
          hasApiAccess
          billingCycles
          reportMaxCount
          reportTemplate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      user_id
      tagFileId
      value
      createdAt
      updatedAt
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        tagFileId
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTagByUser = /* GraphQL */ `
  query GetTagByUser(
    $user_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTagByUser(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        tagFileId
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTiers = /* GraphQL */ `
  query ListTiers(
    $filter: ModelTierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTiers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        costMonthly
        costYearly
        deviceMaxCount
        storageLimitGb
        alertMaxCount
        hasApiAccess
        billingCycles
        reportMaxCount
        reportTemplate
        createdAt
        updatedAt
        stripePlans {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTier = /* GraphQL */ `
  query GetTier($id: ID!) {
    getTier(id: $id) {
      id
      costMonthly
      costYearly
      deviceMaxCount
      storageLimitGb
      alertMaxCount
      hasApiAccess
      billingCycles
      reportMaxCount
      reportTemplate
      createdAt
      updatedAt
      stripePlans {
        items {
          id
          tierId
          billingCycle
          cost
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
