export const listAlertsShort = `query ListAlerts(
  $filter: ModelAlertFilterInput
  $limit: Int
  $nextToken: String
) {
  listAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      tagName
      enabled
      createdAt
    }
    nextToken
  }
}
`;

export const listDeviceAssociationsShort = `query ListDeviceAssociations(
  $filter: ModelDeviceAssociationFilterInput
  $limit: Int
  $nextToken: String
) {
  listDeviceAssociations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      org_id
      serial_number_id
      status
      files {
        nextToken
      }
      device {
        thing_name
        name
      }
    }
    nextToken
  }
}
`;

export const listUserTagsShort = `query GetUniqueTagLists{
  getUniqueTagList {
    value
  }
}`;

export const getDashboardShort = /* GraphQL */ `
  query GetDashboard($id: ID!) {
    getDashboard(id: $id) {
      id
      owner
      maxFiles
      status
      tags
      title
      createdAt
      updatedAt
      customScriptId
      defaultScript
      file_ids
      notes
      output_logs
      customScript {
        id
        title
      }
      shares {
        items {
          token
          id
          expires
        }
      }
      error {
        code
        message
        status
        transactionId
        scriptError
      }
      modules {
        items {
          data {
            items {
              json
              createdAt
              updatedAt
            }
          }
          frames {
            items {
              moduleId
              json
              sequence
              createdAt
              updatedAt
            }
            nextToken
          }
          layout {
            json
            createdAt
            updatedAt
          }
          sequence
          title
        }
        nextToken
      }
    }
  }
`;

export const getListOfDashboards = /* GraphQL */ `
  query getListOfDashboards(
    $filter: ModelDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        customScriptId
        defaultScript
        error {
          code
          message
          status
          transactionId
          scriptError
        }
        output_logs
        lastTransactionId
        maxFiles
        status
        tags
        file_ids
        title
        sequence
        createdAt
        updatedAt
        customScript {
          id
          owner
          lines
          status
          title
          type
          createdAt
          updatedAt
        }
        notes
        modules {
          nextToken
        }
        shares {
          items {
            token
            id
            expires
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listCustomScriptsShort = /* GraphQL */ `
  query ListCustomScripts(
    $filter: ModelCustomScriptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomScripts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        error {
          code
          message
          status
        }
        lines
        status
        title
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
