import {
  Button,
  ButtonTypeMap,
  createStyles,
  Paper,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import classnames from 'classnames';
import { get, startCase } from 'lodash';
import React, { useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { pluralizeString } from '../../helpers/DataHelper';
import { routeAccountBilling, routeDashboard } from '../../helpers/RouteHelper';
import { AuthContext } from '../../hooks/AuthContext';
import { StripeSessionContext } from '../../hooks/UseStripeSessionApi/StripeSessionContext';

const styles = (theme: Theme) =>
  createStyles({
    card: {
      margin: theme.spacing(1),
      width: 255,
      borderRadius: 4
    },
    cardInner: {
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
      textAlign: 'center'
    },
    recommendedCard: {
      border: `1px solid ${theme.palette.grey[900]}`
    },
    recommendedText: {
      background: theme.palette.grey[900],
      color: theme.palette.common.white,
      textAlign: 'center',
      padding: theme.spacing(1),
      textTransform: 'uppercase'
    },
    planTitle: {
      marginBottom: theme.spacing(1.5)
    },
    planButton: {
      minWidth: 190,
      padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
      margin: `${theme.spacing(2)}px 0 0`,
      boxShadow: 'none'
    },
    paymentGroup: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid #979797`
    },
    paymentAmount: {
      marginRight: theme.spacing(1)
    },
    planBenefits: {
      lineHeight: 2.33,
      letterSpacing: '0.39px',
      textAlign: 'center'
    },
    capitalize: {
      textTransform: 'capitalize'
    }
  });

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  tierId: string;
  costMonthly: number;
  costYearly: number;
  yearlyToggled: boolean;
  capLinkedSensors: number;
  capStorageGb: number;
  capAlerts: number;
  hasApiAccess: boolean;
  reportTemplate: string;
  generateOnCtaClick: (
    tierId: string,
    paymentPeriod: string,
    cost: number
  ) => () => void;
  disableCta: boolean;
  recommended?: boolean;
}

const PlanCard = ({
  classes,
  tierId,
  costMonthly,
  costYearly,
  yearlyToggled,
  capLinkedSensors,
  capStorageGb,
  capAlerts,
  hasApiAccess,
  reportTemplate,
  generateOnCtaClick,
  disableCta,
  history,
  recommended
}: Props) => {
  const authContext = useContext(AuthContext);
  const stripeSessionContext = useContext(StripeSessionContext);
  if (!authContext) {
    throw new Error(
      'App component must be used within a Auth Context Provider'
    );
  }
  if (!stripeSessionContext) {
    throw new Error('Stripe session context is missing');
  }
  const { customerData, isSuspended } = authContext;
  const { switchBilling } = stripeSessionContext;

  const customerTier = get(customerData, 'tier');
  const customerFrequency = get(customerData, 'billingFrequency');

  const cost = yearlyToggled ? costYearly : costMonthly;
  const paymentPeriod = yearlyToggled ? 'year' : 'month';
  const paymentPeriodName = yearlyToggled ? 'Yearly' : 'Monthly';
  let titleText = tierId;
  const ctaClasses = classes.planButton;
  let ctaVariant: ButtonTypeMap['props']['variant'] = 'contained';
  let ctaText = 'Choose Plan';

  let onCtaClick = generateOnCtaClick(tierId, paymentPeriod, cost);
  if (customerTier) {
    const currentTier = tierId === get(customerData, 'tierId');
    const currentFrequency = yearlyToggled !== (customerFrequency === 'yearly');
    const newTierIsUpgrade = costMonthly > customerTier.costMonthly;
    const newTierIsDowngrade = costMonthly < customerTier.costMonthly;
    if (cost === 0) {
      ctaVariant = 'outlined';
    }
    if (currentTier) {
      if (cost > 0 && currentFrequency) {
        ctaText = `Switch to ${paymentPeriodName}`;
        titleText = `${startCase(tierId)} (Current Plan)`;
      } else if (isSuspended) {
        ctaText = 'Update Billing Info';
        onCtaClick = () => {
          switchBilling(`${window.location.origin}${routeAccountBilling()}`);
        };
      } else {
        ctaText = 'Stay with Current Plan';
        titleText = `${startCase(tierId)} (Current Plan)`;
        onCtaClick = () =>
          history.push({
            pathname: routeDashboard()
          });
      }
    } else if (newTierIsUpgrade) {
      ctaText = `Upgrade to ${startCase(tierId)}`;
    } else if (newTierIsDowngrade) {
      ctaText = `Downgrade to ${startCase(tierId)}`;
    }
  }
  const capAlertText = capAlerts >= 0 ? capAlerts : 'Unlimited';

  let capStorage = `${capStorageGb} GB`;
  if (capStorageGb > 1000) {
    capStorage = `${capStorageGb / 1000} TB`;
  }

  return (
    <Paper
      elevation={0}
      className={classnames(
        classes.card,
        recommended ? classes.recommendedCard : ''
      )}
    >
      {recommended && (
        <Typography
          component="h3"
          variant="h3"
          align="center"
          className={classes.recommendedText}
        >
          Recommended
        </Typography>
      )}
      <div className={classes.cardInner}>
        <Typography variant="h3" component="h3" className={classes.planTitle}>
          {titleText}
        </Typography>
        <div className={classes.paymentGroup}>
          <Typography
            variant="h1"
            component="h3"
            className={classes.paymentAmount}
          >
            ${cost}
          </Typography>
          <Typography variant="body1" component="span">
            / {paymentPeriod}
          </Typography>
        </div>
        <Typography variant="body1" className={classes.planBenefits}>
          {capLinkedSensors < 0 ? 'Unlimited' : capLinkedSensors} Linked{' '}
          {pluralizeString('Sensor', capLinkedSensors)}
          <br />
          {capStorage} Storage
          <br />
          {capAlertText} Custom {pluralizeString('Alert', capAlerts)}
          <br />
          {hasApiAccess ? 'API Access' : 'No API Access'}
          <br />
          <span className={classes.capitalize}> {reportTemplate}</span> Report
          Views
        </Typography>
        <Button
          color="primary"
          size="large"
          variant={ctaVariant}
          className={ctaClasses}
          onClick={onCtaClick}
          disabled={disableCta}
        >
          {ctaText}
        </Button>
      </div>
    </Paper>
  );
};

export default withRouter(withStyles(styles)(PlanCard));
