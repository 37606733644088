export const dashboardApiName = 'dashboard';
export const shareDashboardApiName = 'shareDashboard';
export const dashboardApiPathRoot = '/dashboard';

export interface DashboardShareData {
  id: string;
  dashboardId: string;
  token: string;
  expires?: number | null;
  owner: string;
}

export interface DashboardData {
  error: null | {
    code: null | number;
    message: string;
    status: string;
    scriptError: boolean | null;
    __typename: string;
  };
  modules: { items: any[] };
  status: string;
  title: string;
  tags: string[];
  maxFiles: number;
  createdAt: string;
  id: string;
  owner: string;
  updatedAt: string;
  customScriptId?: string;
  defaultScript?: string;
  customScript: {
    id: string;
    title: string;
  };
  file_ids?: string[];
  notes?: string;
  shares?: {
    items?: Array<DashboardShareData | null> | null;
  } | null;
  sequence?: number;
  output_logs?: string;
  __typename: string;
}

export interface PostParams {
  create: {};
}

export enum ApiTypes {
  CREATE = 'create',
  GENERATE = 'generate',
  REVOKE = 'revoke',
  VIEW = 'view',
  DELETE = 'delete'
}

export type ApiCallback = () => string;

export type ApiUrlsSignature = { [key in ApiTypes]: ApiCallback };

export const apiUrls: ApiUrlsSignature = {
  create: () => `/generate`,
  generate: () => '/share/create',
  revoke: () => '/share/revoke',
  view: () => '/share/view',
  delete: () => '/remove'
};
