import { API } from 'aws-amplify';
import { useMemo } from 'react';
import {
  ApiCallback,
  ApiTypes,
  apiUrls,
  dashboardApiName,
  shareDashboardApiName
} from './ApiHelper';

const UseDashboardApi = (apiType: ApiTypes, isShared: boolean) => {
  const requestPost = useMemo(
    () => async (body?: unknown) => {
      const url: ApiCallback = apiUrls[apiType];
      const apiName = isShared ? shareDashboardApiName : dashboardApiName;
      const path = url();
      const init = {
        body: body || {}
      };
      const response = await API.post(apiName, path, init);
      return response;
    },
    [apiType, isShared]
  );

  const requestDelete = useMemo(
    () => async (body?: unknown) => {
      const url: ApiCallback = apiUrls[apiType];
      const apiName = dashboardApiName;
      const path = url();
      const init = {
        body: body || {}
      };
      const response = await API.del(apiName, path, init);
      return response;
    },
    [apiType]
  );

  return {
    requestPost,
    requestDelete
  };
};

export default UseDashboardApi;
