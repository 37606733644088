/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:0f0d132a-7e88-4d4a-847f-c6669a3df008",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_JONz3OyEM",
    "aws_user_pools_web_client_id": "3bprknvpqkic38kh9bar7aur00",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://euceou6l7na6zofaunzwrrvrni.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
