import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext, useEffect } from 'react';
import Helmet from 'react-helmet';
import PlanList from '../../../components/PlanList';
import { getPageTitle } from '../../../helpers/DataHelper';
import { AuthContext } from '../../../hooks/AuthContext';
import { LayoutContext } from '../../../hooks/LayoutContext';
import { ReactComponent as Logotype } from '../../../images/Logos/Logotype.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logotype: {
      display: 'flex',
      flexShrink: 0,
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      margin: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(9, 4, 6)
      }
    },
    logotypeWrapper: {
      paddingTop: 3,
      width: 270,
      marginBottom: theme.spacing(2.75)
    },
    bodyText: {
      marginTop: theme.spacing(1)
    }
  })
);

const PlansList = () => {
  const pageTitle = 'Select a Plan';
  const classes = useStyles();

  const authContext = useContext(AuthContext);
  if (!authContext) throw new Error('Auth context is missing');
  const { customerNeedsTier } = authContext;

  const layoutContext = useContext(LayoutContext);
  if (!layoutContext) throw new Error('Layout context is missing');
  const { setDarkerBackground, setPageTitle } = layoutContext;

  useEffect(() => {
    setDarkerBackground(true);
    return () => setDarkerBackground(false);
  });
  useEffect(() => {
    setPageTitle('');
  }, [setPageTitle]);

  return (
    <>
      <Helmet>
        <title>{getPageTitle(pageTitle)}</title>
      </Helmet>
      <div className={classes.logotype}>
        {!customerNeedsTier ? (
          <>
            <Typography variant="h1">enDAQ Cloud Service Plans</Typography>
            <Typography variant="body2" className={classes.bodyText}>
              Choose a plan that gives you the features you need to manage your
              data.
            </Typography>
          </>
        ) : (
          <>
            <div className={classes.logotypeWrapper}>
              <Logotype />
            </div>
            <Typography variant="h1">enDAQ data management tool</Typography>
          </>
        )}
      </div>
      <PlanList />
    </>
  );
};

export default PlansList;
