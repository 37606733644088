import {
  Authenticator,
  Greetings,
  Loading,
  SignIn,
  SignUp
} from 'aws-amplify-react';
import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import App from '../../App';
import awsconfig from '../../aws-exports';
import { isRouteSelected } from '../../helpers/NavHelper';
import { PublicScreenRoutes } from '../../helpers/RouteHelper';
import CustomSignIn from '../Amplify/CustomSignIn';
import CustomSignUp from '../Amplify/CustomSignUp';

interface Props extends RouteComponentProps {}

const AppWithAuth = (props: Props) => {
  const { location } = props;
  const isPublicRoute = useMemo(
    () =>
      PublicScreenRoutes.reduce(
        (previousValue, currentValue) =>
          previousValue ||
          isRouteSelected(currentValue.path as string, location.pathname),
        false
      ),
    [location.pathname]
  );

  if (isPublicRoute) {
    return <App isPublicRoute={isPublicRoute} />;
  }

  return (
    <Authenticator
      hide={[Loading, Greetings, SignIn, SignUp]}
      amplifyConfig={awsconfig}
    >
      <CustomSignIn override="SignIn" />
      <CustomSignUp override="SignUp" />
      <App isPublicRoute={isPublicRoute} />
    </Authenticator>
  );
};

export default withRouter(AppWithAuth);
