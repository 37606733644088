import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import React from 'react';
import { startCase } from 'lodash';

interface Props {
  newTierId: string;
  leaveDowngrade: () => void;
  continueDowngrade: () => void;
}

const ConfirmStart = (props: Props) => {
  const { newTierId, leaveDowngrade, continueDowngrade } = props;

  return (
    <>
      <DialogTitle id="form-dialog-title">
        Downgrade to {startCase(newTierId)} Plan
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please confirm you want to downgrade to the {startCase(newTierId)}{' '}
          Plan. You will lose some of the features from your current plan.
        </DialogContentText>
      </DialogContent>
      <DialogActions disableSpacing>
        <Button
          variant="text"
          size="large"
          color="default"
          onClick={leaveDowngrade}
        >
          Cancel
        </Button>
        <Button
          variant="text"
          size="large"
          color="default"
          onClick={continueDowngrade}
        >
          Confirm
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfirmStart;
