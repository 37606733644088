import createMuiTheme, {
  ThemeOptions
} from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import {
  AlertClassKey,
  AlertTitleClassKey,
  AutocompleteClassKey
} from '@material-ui/lab';
import { CSSProperties } from '@material-ui/styles';
const defaultTheme = createMuiTheme();
function createMyTheme(options: ThemeOptions) {
  return createMuiTheme({ ...options });
}

export const palette = {
  primary: {
    main: '#e77025',
    dark: '#dc5922',
    50: '#fceee5',
    100: '#f8d4be',
    200: '#f3b892',
    300: '#ee9b66',
    400: '#eb8546',
    500: '#e77025',
    600: '#e46821',
    700: '#e05d1b',
    800: '#dd5316',
    900: '#d7410d',
    A100: '#ffffff',
    A200: '#ffdacf',
    A400: '#ffb29c',
    A700: '#ff9e83',
    contrastDefaultColor: 'dark'
  },
  secondary: {
    main: '#81379e',
    light: '#f0e6f3',
    dark: '#6e388e',
    50: '#f0e8f3',
    100: '#dbc2e2',
    200: '#bf9fcc',
    300: '#a579b7',
    400: '#925ca7',
    500: '#7f3f98',
    600: '#773990',
    700: '#6c3185',
    800: '#62297b',
    900: '#4f1b6a',
    A100: '#dfa6ff',
    A200: '#cd73ff',
    A400: '#ba40ff',
    A700: '#b126ff',
    contrastDefaultColor: 'light'
  },
  grey: {
    100: '#f3f3f3',
    200: '#dad9d8',
    300: 'rgba(0, 0, 0, 0.38)',
    400: '#e4e4e5',
    500: '#ebebeb',
    600: '#d8d8d8',
    700: '#262626',
    800: '#4a4a4a',
    900: '#404041',
    A100: '#979797',
    A200: '#F5F5F5'
  },
  error: {
    main: '#c92727'
  },
  background: {
    paper: '#fff',
    default: '#fcfcfc'
  }
};
export const typography = {
  htmlFontSize: 10,
  fontFamily: '"Open Sans", sans-serif',
  body2: {
    fontSize: 12
  },
  caption: {
    color: palette.grey[900],
    fontSize: 11,
    lineHeight: 1.78,
    letterSpacing: 0.31
  }
};

const MuiAlert = {
  filledError: {
    backgroundColor: palette.error.main
  }
};

const MuiAlertTitle = {
  root: {
    fontSize: 16
  }
};

const MuiAutocomplete = {
  root: {},
  paper: {
    boxShadow:
      '0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14)',
    borderRadius: 4
  },
  listbox: {
    fontSize: 12
  }
};

const MuiButtonBase: Overrides['MuiButtonBase'] = {
  root: {
    letterSpacing: '1.25px',
    outline: '',
    WebkitTapHighlightColor: '',
    '-moz-appearance': '',
    '-webkit-appearance': '',
    '&::-moz-focus-inner': {
      borderStyle: ''
    }
  }
};

const MuiButton: Overrides['MuiButton'] = {
  root: {
    fontSize: '1.2rem',
    lineHeight: 1.6,
    letterSpacing: 0.92,
    '&:disabled': {
      pointerEvents: 'none'
    }
  },
  text: {
    textTransform: 'uppercase',
    fontSize: 12,
    lineHeight: 2.33,
    letterSpacing: '0.39px',
    fontWeight: 'bold'
  },
  contained: {
    border: '1px solid transparent',
    '&:disabled': {
      border: '1px solid',
      borderColor: palette.grey[300],
      backgroundColor: palette.background.default,
      color: palette.grey[300]
    }
  },
  containedPrimary: {
    '&:hover': {
      backgroundColor: palette.primary.dark
    }
  },
  containedSecondary: {
    '&:hover': {
      backgroundColor: palette.secondary.dark
    }
  },
  outlined: {
    color: '#000000',
    borderColor: palette.grey[700],
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: palette.secondary.dark,
      borderColor: palette.secondary.dark,
      '@media (hover: none)': {
        backgroundColor: palette.secondary.dark
      }
    }
  },
  outlinedPrimary: {
    color: palette.primary[700],
    borderColor: palette.primary[700],
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: palette.primary.dark,
      borderColor: palette.primary.dark,
      '@media (hover: none)': {
        backgroundColor: palette.primary.dark
      }
    }
  },
  outlinedSecondary: {
    color: palette.secondary[700],
    borderColor: palette.secondary[700],
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: palette.secondary.dark,
      borderColor: palette.secondary.dark,
      '@media (hover: none)': {
        backgroundColor: palette.secondary.dark
      }
    }
  },
  outlinedSizeLarge: {
    color: '#000000',
    borderColor: palette.grey[700]
  },
  sizeSmall: {
    fontSize: '1rem'
  },
  sizeLarge: {
    padding: '9px 13px 11px',
    fontSize: 12,
    lineHeight: 1.33,
    letterSpacing: 1.11
  },
  textSizeLarge: {
    fontSize: 14,
    lineHeight: 16,
    textTransform: 'uppercase',
    color: '#000000'
  }
};

const MuiDivider: Overrides['MuiDivider'] = {
  root: {
    backgroundColor: '#c2c2c2'
  }
};

const MuiLink: Overrides['MuiLink'] = {
  root: {
    cursor: 'pointer'
  }
};

const MuiTable: Overrides['MuiTable'] = {
  root: {
    fontFamily: '"Roboto", sans-serif'
  }
};

const MuiTableRow: Overrides['MuiTableRow'] = {};

const MuiTableCell: Overrides['MuiTableCell'] = {
  root: {
    wordBreak: 'break-word',
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    padding: defaultTheme.spacing(0, 1.25, 0, 2),
    color: palette.grey[800],
    borderBottom: `1px solid ${palette.grey[500]}`
  },
  head: {
    fontSize: '1.1rem',
    fontWeight: 600,
    lineHeight: '1.4rem',
    color: palette.grey[800],
    textTransform: 'uppercase'
  },
  stickyHeader: {
    backgroundColor: palette.background.paper,
    borderColor: palette.grey[500],
    borderStyle: 'solid',
    borderWidth: '1px 0 0 1px',
    borderBottom: `1px solid ${palette.grey[800]}`,
    cursor: 'pointer',
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      backgroundColor: palette.grey[500]
    },
    '&:first-child': {
      borderLeft: 0
    }
  },
  paddingCheckbox: {
    width: 44
  }
};

const MuiTableSortLabel: Overrides['MuiTableSortLabel'] = {
  root: {
    letterSpacing: 0,
    width: '100%',
    '&:hover': {
      '& $icon': {
        color: palette.secondary.main
      }
    },
    '&$active': {
      '&& $icon': {
        color: palette.secondary.main
      }
    }
  },
  icon: {
    color: palette.grey[200],
    opacity: 1
  }
};

const MuiFormControlLabel: Overrides['MuiFormControlLabel'] = {
  label: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    color: '#000000'
  }
};

const MuiTablePagination: Overrides['MuiTablePagination'] = {
  toolbar: {
    height: 40,
    minHeight: 0
  },
  caption: {
    fontSize: 11
  },
  select: {
    fontSize: 11
  }
};

const MuiTypography: Overrides['MuiTypography'] = {
  body1: {
    fontSize: 12,
    lineHeight: '28px',
    letterSpacing: '0.15px'
  },
  caption: {
    color: palette.grey[900],
    fontSize: 11,
    lineHeight: 1.78,
    letterSpacing: 0.31
  },
  subtitle1: {
    color: palette.grey[900],
    fontSize: 11,
    textTransform: 'uppercase'
  },
  h1: {
    fontSize: 22,
    fontWeight: 400
  },
  h2: {
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: '0.26px'
  },
  h3: {
    fontSize: 11,
    fontWeight: 700,
    lineHeight: '14px',
    textTransform: 'uppercase'
  }
};

const MuiMenuItem: Overrides['MuiMenuItem'] = {
  root: {
    fontSize: 11,
    minHeight: 'auto',
    lineHeight: '2em',
    paddingTop: 5,
    paddingBottom: 5
  }
};

const MuiChip: Overrides['MuiChip'] = {
  root: {
    height: 24,
    borderRadius: 2,
    backgroundColor: palette.secondary.light,
    color: palette.grey[900],
    margin: `${defaultTheme.spacing(0.5)}px 0 ${defaultTheme.spacing(
      0.5
    )}px ${defaultTheme.spacing(1)}px`,
    fontSize: 9
  },
  clickable: {
    '&:active': {
      boxShadow: 'none',
      backgroundColor: palette.secondary.light
    },
    '&:hover': {
      backgroundColor: palette.secondary[100]
    }
  },
  outlined: {
    backgroundColor: palette.background.default,
    color: palette.grey[800]
  },
  deletable: {
    '&:focus': {
      backgroundColor: palette.secondary.light
    },
    '&:hover': {
      backgroundColor: palette.secondary[100]
    }
  },
  deleteIcon: {
    color: 'rgba(0,0,0, 0.54)',
    height: 18,
    maxWidth: 22,
    width: 'auto'
  }
};

const MuiListSubheader: Overrides['MuiListSubheader'] = {
  root: {}
};

const MuiListItemIcon: Overrides['MuiListItemIcon'] = {
  root: {
    // minWidth: 'none'
  }
};

const MuiListItemText: Overrides['MuiListItemText'] = {
  root: {
    letterSpacing: 0,
    fontSize: 12
  },
  inset: {
    paddingLeft: 20
  }
};

const MuiSvgIcon: Overrides['MuiSvgIcon'] = {
  colorSecondary: {
    color: palette.secondary[500]
  }
};

const MuiTextField: Overrides['MuiTextField'] = {
  root: {
    borderColor: '#000',
    '&:focus': {
      borderColor: '#000'
    }
  }
};

const MuiDialog = {
  paper: {
    padding: defaultTheme.spacing(1)
  },
  paperWidthXs: {
    maxWidth: 280,
    padding: 0
  }
};

const MuiDialogContentText = {
  root: {
    fontSize: '15.5px',
    lineHeight: '24px',
    color: 'rgba(0,0,0,0.6)',
    marginBottom: defaultTheme.spacing(5)
  }
};

const MuiDialogTitle = {
  root: {
    padding: defaultTheme.spacing(2, 3, 0)
  }
};

const MuiDialogActions = {
  root: {
    padding: defaultTheme.spacing(1)
  },
  spacing: {
    padding: defaultTheme.spacing(3)
  }
};

interface ThemeOverrides extends Overrides {
  MuiAutocomplete?:
    | Partial<
        Record<AutocompleteClassKey, CSSProperties | (() => CSSProperties)>
      >
    | undefined;
  MuiAlert?:
    | Partial<Record<AlertClassKey, CSSProperties | (() => CSSProperties)>>
    | undefined;
  MuiAlertTitle?:
    | Partial<Record<AlertTitleClassKey, CSSProperties | (() => CSSProperties)>>
    | undefined;
}

const overrides: ThemeOverrides = {
  MuiAlert,
  MuiAlertTitle,
  MuiAutocomplete,
  MuiButton,
  MuiButtonBase, // WCAG 2 compliance, enables visual focus state
  MuiChip,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContentText,
  MuiDialogTitle,
  MuiDivider,
  MuiLink,
  MuiListSubheader,
  MuiListItemIcon,
  MuiListItemText,
  MuiFormControlLabel,
  MuiMenuItem,
  MuiSvgIcon,
  MuiTable,
  MuiTableCell,
  MuiTablePagination,
  MuiTableRow,
  MuiTableSortLabel,
  MuiTextField,
  MuiTypography
};

export default createMyTheme({
  typography,
  palette,
  overrides
});
