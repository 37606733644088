import { ICredentials } from '@aws-amplify/core';
import { Auth, Hub } from 'aws-amplify';
import gql from 'graphql-tag';
import { get, orderBy } from 'lodash';
import * as log from 'loglevel';
import React, {
  createContext,
  ReactChild,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { ApolloContext } from 'react-apollo';
import { matchPath, RouteComponentProps, withRouter } from 'react-router';
import { BillingSubscriptionStatus, DeviceAssociationStatus } from '../../API';
import { getCustomerByOwnerId, listApiKeys } from '../../graphql/queries';
import {
  routeDashboard,
  routePlans,
  routeSingleDashboard
} from '../../helpers/RouteHelper';
import {
  getListOfDashboards,
  listAlertsShort,
  listCustomScriptsShort,
  listDeviceAssociationsShort
} from '../../helpers/ShortQueryHelper';
import { getSorting, stableSort } from '../../helpers/SortingHelper';
import { DashboardShareData } from '../UseDashboardApi/ApiHelper';
import { getBillingFrequency } from '../UseStripeSessionApi/ApiHelper';

export interface AuthContextCustomerReport {
  id: string;
  owner: null | string;
  maxFiles: number;
  status: string;
  tags: string[];
  title: null | string;
  createdAt: string;
  updatedAt: string;
  customScript: null | {
    __typename: 'CustomScript';
    id: string;
    owner: string;
    error?: null | {
      __typename: 'CustomScriptError';
      code?: number | null;
      detail: string;
      message: string;
      status?: string | null;
      type: string;
    };
    lines: string[];
    status?: null | string;
    title?: null | string;
    type: string;
    createdAt?: null | string;
    updatedAt?: null | string;
  };
  customScriptId: null | string;
  defaultScript: null | string;
  error: null | {
    __typename: 'DashboardError';
    code?: number | null;
    message: string;
    status?: string | null;
  };
  file_ids?: string[];
  notes?: string;
  shares?: {
    items?: Array<DashboardShareData | null> | null;
  } | null;
  sequence: null | number;
  modules: {
    nextToken: null | string;
    __typename: 'ModelDashboardModuleConnection';
  };
  __typename: 'Dashboard';
}

export interface AuthContextExports {
  signedIn: boolean;
  userInfo:
    | {
        email: string;
        sub: string;
        identityId: string;
        authTime: number | undefined;
      }
    | null
    | undefined;
  isAuthorized: boolean | undefined;
  isSuspended: boolean | undefined;
  subscriptionStatus: string | undefined;
  customerData: null | {
    id: string;
    owner: string;
    storageConsumed: number;
    storageSpaceRemaining: boolean;
    tierId: string;
    billing: {
      stripe_session_id: string;
      owner: string;
      stripe_customer_id: string;
      stripe_subscription_id: null | string;
      subscription_status: string;
      stripe_plan_id: string;
    };
    companyName: string;
    logoImage: string;
  };
  customerAlerts:
    | null
    | {
        id: string;
        name: null | string;
        tagName: string;
        enabled: boolean | undefined;
      }[];
  customerApiKeys:
    | null
    | {
        id: string;
        name: null | string;
      }[];
  customerReports: null | AuthContextCustomerReport[];
  customerIndexedReports: null | AuthContextCustomerReport[];
  customerScripts:
    | []
    | {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        error?: null | {
          __typename: 'CustomScriptError';
          code?: number | null;
          detail: string;
          message: string;
          status?: string | null;
          type: string;
        };
        lines: string[];
        status?: null | string;
        title?: null | string;
        type: string;
        createdAt?: null | string;
        updatedAt?: null | string;
      }[];
  customerSensors:
    | null
    | {
        __typename: 'DeviceAssociation';
        id: string;
        org_id: string | null;
        s3_bucket_name: string | null;
        serial_number_id: string;
        status: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
      }[];
  customerNeedsTier: boolean;
  refetchCustomerData: () => void;
  refetchCustomerAlerts: () => void;
  refetchCustomerApiKeys: () => void;
  refetchCustomerReports: () => void;
  refetchCustomerSensors: () => void;
}

export const AuthContext = createContext<AuthContextExports | undefined>(
  undefined
);
const { Provider } = AuthContext;

function formatUserInfo(authData: any, credData: ICredentials) {
  log.debug('formatUserInfo', authData, credData);
  const { identityId } = credData;
  let data = {
    identityId,
    authTime: get(authData, 'signInUserSession.accessToken.payload.auth_time')
  };
  if (!get(authData, 'attributes.email') || !get(authData, 'attributes.sub')) {
    throw Error('Unexpected Cognito User');
  }
  return {
    ...data,
    ...get(authData || {}, 'attributes', {})
  };
}

export interface AuthProviderProps extends RouteComponentProps {
  children?: ReactChild;
  defaultSignedIn?: boolean;
  defaultIsAuthorized?: boolean;
  defaultPersonInfo?: any;
}

export const AuthProvider = withRouter((props: AuthProviderProps) => {
  const apolloContext = useContext(ApolloContext);
  if (!apolloContext) {
    throw new Error(
      'AuthContext component must be used within an Apollo Context Provider'
    );
  }
  const { client } = apolloContext;
  const { history, location } = props;
  const [signedIn, setSignedIn] = useState<boolean>(!!props.defaultSignedIn);
  const [userInfo, setUserInfo] = useState();
  const [customerData, setCustomerData] = useState(null);
  const [customerAlerts, setCustomerAlerts] = useState<any[] | null>(null);
  const [customerApiKeys, setCustomerApiKeys] = useState<
    AuthContextExports['customerApiKeys'] | null
  >(null);
  const [customerReports, setCustomerReports] = useState<
    AuthContextExports['customerReports'] | null
  >(null);
  const [customerIndexedReports, setCustomerIndexedReports] = useState<
    AuthContextExports['customerIndexedReports'] | null
  >(null);
  const [customerSensors, setCustomerSensors] = useState(null);
  const [customerScripts, setCustomerScripts] = useState<
    AuthContextExports['customerScripts']
  >([]);
  const [isAuthorized, setIsAuthorized] = useState<boolean | undefined>(
    props.defaultIsAuthorized
  );
  const subscriptionStatus = get(customerData, 'billing.subscription_status');
  const isSuspended = useMemo(() => {
    const tier = get(customerData, 'tierId');
    return (
      subscriptionStatus === BillingSubscriptionStatus.suspended &&
      tier !== 'free'
    );
  }, [customerData, subscriptionStatus]);

  const customerNeedsTier = useMemo(() => {
    if (customerData === null) return false;
    const tier = get(customerData, 'tierId');
    return !tier || tier === 'none';
  }, [customerData]);

  useEffect(() => {
    if (customerNeedsTier) {
      history.replace({
        pathname: routePlans()
      });
    }
  }, [customerNeedsTier, history]);

  useEffect(() => {
    const fetchUser = async (user?: AuthContextExports['userInfo']) => {
      try {
        const userData = user || (await Auth.currentAuthenticatedUser());
        const userCredentials = await Auth.currentCredentials();
        const data = formatUserInfo(userData, userCredentials);
        setUserInfo(data);
      } catch (e) {
        log.warn('fetchUser', 'caught error', e);
      }
    };

    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn': {
          setSignedIn(true);
          fetchUser();
          const visitingReport = matchPath(
            location.pathname,
            routeSingleDashboard()
          );
          if (!customerNeedsTier && !visitingReport) {
            history.replace({
              pathname: routeDashboard()
            });
          }
          break;
        }
        case 'signOut': {
          setSignedIn(false);
          setIsAuthorized(false);
          history.push({ pathname: '/' });
          break;
        }
        case 'signIn_failure': {
          break;
        }
      }
    });

    Auth.currentAuthenticatedUser()
      .then((user: any) => {
        setSignedIn(true);
        fetchUser(user);
      })
      .catch((e: any) => log.warn('User is', e));
  }, [history]);

  useEffect(() => {
    if (userInfo) {
      setIsAuthorized(!!get(userInfo || {}, 'email'));
    }
  }, [userInfo]);

  const refetchCustomerAlerts = useMemo(
    () => () => {
      if (!client || !userInfo) return;
      try {
        client
          .query({
            query: gql(listAlertsShort),
            variables: {
              owner: get(userInfo, 'sub'),
              filter: {
                enabled: {
                  eq: true
                }
              },
              limit: 1000
            },
            fetchPolicy: 'network-only'
          })
          .then(data => {
            const alertData = orderBy(
              get(data, 'data.listAlerts.items'),
              ['createdAt'],
              ['desc']
            );
            setCustomerAlerts(alertData);
          });
      } catch (e) {
        console.log('Error fetching customer alert data', e);
      }
    },
    [client, userInfo]
  );

  const refetchCustomerApiKeys = useMemo(
    () => () => {
      if (!client || !userInfo) return;
      try {
        client
          .query({
            query: gql(listApiKeys),
            variables: {
              limit: 1000
            },
            fetchPolicy: 'network-only'
          })
          .then(data => {
            const apiKeyData = get(data, 'data.listApiKeys.items');
            setCustomerApiKeys(apiKeyData);
          });
      } catch (e) {
        console.log('Error fetching customer api key data', e);
      }
    },
    [client, userInfo]
  );

  const refetchCustomerReports = useMemo(
    () => async () => {
      if (!client || !userInfo) return;

      try {
        let nextToken: string | null = null;
        let dashboards: any[] = [];

        // keep fetching data until next token is null
        while (true) {
          // get data in request
          const response: any = await client.query({
            query: gql(getListOfDashboards),
            variables: {
              limit: 1000,
              nextToken: nextToken
            },
            fetchPolicy: 'network-only'
          });
          const data = response.data.listDashboards;
          // get reports
          const reports = get(data, 'items');

          // update list of dashboards
          dashboards = [...dashboards, ...reports];

          // now get next token
          nextToken = data.nextToken;
          if (!nextToken) {
            break;
          }
          // keep going
        }

        const fixedReportsData = stableSort(
          dashboards.map((report: AuthContextCustomerReport) => ({
            ...report,
            updatedAt: report && (report.updatedAt || report.createdAt)
          })),
          getSorting('desc', 'updatedAt')
        ) as AuthContextExports['customerReports'];

        let topReports: AuthContextCustomerReport[] = [];
        let needSortReports: any[] = [];

        if (fixedReportsData) {
          topReports = fixedReportsData.filter((item: any) => !item.sequence);
          needSortReports = fixedReportsData.filter((item: any) => {
            if (item.sequence && item.sequence > 0) {
              return true;
            }
            return false;
          });
          needSortReports = stableSort(
            needSortReports,
            getSorting('asc', 'sequence')
          );
        }

        const sortedReports = [...topReports, ...needSortReports];
        const indexedReports = sortedReports.map(
          (report: AuthContextCustomerReport, idx) => ({
            ...report,
            sequence: idx + 1
          })
        );

        setCustomerReports(sortedReports);
        setCustomerIndexedReports(indexedReports);
      } catch (e) {
        console.log('Error fetching customer report data', e);
      }
    },
    [client, userInfo]
  );

  const refetchCustomerScripts = useMemo(
    () => () => {
      if (!client || !userInfo) return;
      try {
        client
          .query({
            query: gql(listCustomScriptsShort),
            fetchPolicy: 'network-only'
          })
          .then(data => {
            const scriptsData = get(data, 'data.listCustomScripts.items');
            setCustomerScripts(scriptsData);
          });
      } catch (e) {
        console.log('Error fetching customer report data', e);
      }
    },
    [client, userInfo]
  );

  const refetchCustomerSensors = useMemo(
    () => () => {
      if (!client || !userInfo) return;
      try {
        client
          .query({
            query: gql(listDeviceAssociationsShort),
            variables: {
              owner: get(userInfo, 'sub'),
              limit: 1000,
              filter: {
                status: {
                  eq: DeviceAssociationStatus['PAIRED']
                }
              }
            },
            fetchPolicy: 'network-only'
          })
          .then(data => {
            const sensorData = get(data, 'data.listDeviceAssociations.items');
            setCustomerSensors(sensorData);
          });
      } catch (e) {
        console.log('Error fetching customer sensor data', e);
      }
    },
    [client, userInfo]
  );

  const refetchCustomerData = useMemo(
    () => () => {
      if (!client || !userInfo || !refetchCustomerAlerts) return;
      try {
        client
          .query({
            query: gql(getCustomerByOwnerId),
            variables: {
              owner: get(userInfo, 'sub'),
              limit: 10
            },
            fetchPolicy: 'network-only'
          })
          .then(data => {
            const itemData = get(data, 'data.getCustomerByOwnerId.items[0]');
            if (!itemData) {
              setCustomerData(itemData);
              return;
            }
            const tierId = get(itemData, 'tierId');
            const stripePlanId = get(itemData, 'billing.stripe_plan_id');
            const billingFrequency = getBillingFrequency(stripePlanId);
            let billingCost = 0;
            if (tierId !== 'free' && stripePlanId) {
              const billingField =
                billingFrequency === 'monthly' ? 'costMonthly' : 'costYearly';
              billingCost = get(itemData, `tier.${billingField}`);
            }
            setCustomerData({ ...itemData, billingFrequency, billingCost });

            //Let's refresh alerts as well (optional and can be removed but you'll need to call this manually)
            refetchCustomerAlerts();
            refetchCustomerApiKeys();
            refetchCustomerReports();
            refetchCustomerSensors();
            refetchCustomerScripts();
          });
      } catch (e) {
        console.log('Error fetching customer data', e);
      }
    },
    [
      client,
      userInfo,
      refetchCustomerAlerts,
      refetchCustomerApiKeys,
      refetchCustomerSensors,
      refetchCustomerReports,
      refetchCustomerScripts
    ]
  );

  useEffect(() => {
    if (!refetchCustomerData || !refetchCustomerAlerts) return;
    refetchCustomerData();
  }, [client, userInfo, refetchCustomerData, refetchCustomerAlerts]);

  const defaultContext = {
    signedIn,
    userInfo,
    isAuthorized,
    isSuspended,
    subscriptionStatus,
    customerData,
    customerAlerts,
    customerApiKeys,
    customerNeedsTier,
    customerReports,
    customerIndexedReports,
    customerSensors,
    customerScripts,
    refetchCustomerData,
    refetchCustomerAlerts,
    refetchCustomerApiKeys,
    refetchCustomerReports,
    refetchCustomerSensors
  };
  return (
    <Provider value={defaultContext}>
      {props.children && props.children}
    </Provider>
  );
});
