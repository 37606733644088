import { createStyles, Theme, WithStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { isRouteSelected } from '../../helpers/NavHelper';
import { NavMenuItemIcon } from '../../images/Icons';

const styles = (theme: Theme) =>
  createStyles({
    listItemRoot: {
      '&:hover': {
        backgroundColor: `${theme.palette.grey[700]} !important`
      }
    },
    listItemSelected: {
      backgroundColor: `${theme.palette.grey[700]} !important`
    },
    listIcon: {
      width: 15,
      height: '100%',
      position: 'absolute',
      left: 12,
      top: 0
    },
    selectedIcon: {
      color: '#ffffff',
      width: 15
    }
  });

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  label: string;
  icon?: () => ReactElement;
  pathname: string;
  path: string;
  external?: boolean;
  name: string;
  closeDrawer: null | (() => void);
}

const NavPrimaryItem = ({
  label,
  icon,
  pathname,
  path,
  external,
  name,
  history,
  location,
  closeDrawer,
  classes
}: Props) => {
  const selected = isRouteSelected(
    path,
    `${location.pathname}${location.search}`
  );
  let RenderIcon = null;
  if (icon) {
    RenderIcon = icon();
  }
  if (selected) {
    RenderIcon = <NavMenuItemIcon className={classes.selectedIcon} />;
  }
  return (
    <ListItem
      button
      data-testid={`navprimaryitem-${name}`}
      selected={selected}
      classes={{
        root: classes.listItemRoot,
        selected: classes.listItemSelected
      }}
      {...(external
        ? {
            component: 'a',
            href: pathname,
            target: '_blank',
            onClick: () => {
              closeDrawer && closeDrawer();
            }
          }
        : {
            onClick: () => {
              closeDrawer && closeDrawer();
              history.push(pathname);
            }
          })}
    >
      {RenderIcon && (
        <ListItemIcon className={classes.listIcon}>{RenderIcon}</ListItemIcon>
      )}
      <ListItemText
        primary={label}
        disableTypography
        {...(icon ? {} : { inset: true })}
      />
    </ListItem>
  );
};

export default withStyles(styles, { withTheme: true })(
  withRouter(NavPrimaryItem)
);
