export const getPageTitle = (title: string) =>
  `${title && `${title} | `}enDAQ Portal`;
export const getSerialNumber = (serialId: string) =>
  serialId.length > 0 ? serialId : ' ';

export const formatSecondsToTimestring = (timeInSeconds: number) => {
  const pad: (num: number | string, size: number) => string = (num, size) =>
    ('000' + num).slice(size * -1);
  const timeString: string = Number.parseFloat(`${timeInSeconds}`).toFixed(3);
  const time: number = Number.parseFloat(timeString);
  const hours: number = Math.floor(time / 60 / 60);
  const minutes: number = Math.floor(time / 60) % 60;
  const seconds: number = Math.floor(time - minutes * 60);
  const milliseconds: string = timeString.slice(-3);
  return `${pad(hours, 2)} h ${pad(minutes, 2)} m ${pad(seconds, 2)} s ${pad(
    milliseconds,
    3
  )} ms`;
};

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const pluralizeString = (
  str: string,
  count: number,
  ending: string = ''
) => {
  if (count === 1) return `${str}${ending}`;
  return `${str}s${ending}`;
};

export const pluralizeVerb = (
  str: string,
  count: number,
  ending: string = ''
) => {
  if (count === 1) return `${str}s${ending}`;
  return `${str}${ending}`;
};

export const getPlanSelectionErrorMessage = ({
  currentTier,
  newTierCost
}: {
  currentTier?: string;
  newTierCost: number;
}) => {
  if (newTierCost === 0) {
    if (currentTier) {
      return 'Could not downgrade to the free plan at this time, please try again';
    } else {
      return 'Could not select the free tier at this time, please try again';
    }
  } else {
    if (currentTier) {
      return 'Could not switch plans at this time, please try again';
    } else {
      return 'Could not activate the selected plan at this time, please try again';
    }
  }
};

export const convertBytesToGb = (value: number) =>
  Math.floor(value / 100000000) / 10;

export const asyncTimer = async (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const parseJsonFromString = (strBlock: string) => {
  let parsedJson = JSON.parse(strBlock);

  if (typeof parsedJson === 'string') {
    parsedJson = JSON.parse(parsedJson);
  }
  return parsedJson;
};

export const urlPatternValidation = (URL: string) => {
  const regex = new RegExp('(http)?s?:?(//[^"\']*.(?:png|jpg|jpeg|png|svg))');
  return regex.test(URL);
};

export const getScriptLineErrors = (
  scriptName: string,
  scriptError: string
) => {
  // This is for one error returns only, if in the future there are more than one error that is returned we will need to modify this function
  const scriptErrorArray = scriptError.replace(/(\r\n|\n|\r)/gm, '').split(',');
  let errorAnnotation = [];
  if (scriptErrorArray && scriptErrorArray.length > 1) {
    if (scriptErrorArray[0].includes(`File "${scriptName}"`)) {
      const error = scriptErrorArray[0].replace(`File "${scriptName}"`, '');
      const lineNum = scriptErrorArray[1].replace('line ', '');
      const annotation = {
        row: parseInt(lineNum) - 1,
        column: 0,
        text: error,
        type: 'error'
      };
      errorAnnotation.push(annotation);
    }
  }

  return errorAnnotation;
};
