// Polyfill needs to be at the top - do not enable organizeImports for this reason
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import Amplify, { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { Rehydrated } from 'aws-appsync-react';
import * as log from 'loglevel';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import awsconfig from './aws-exports';
import AppWithAuth from './components/AppWithAuth';
import './fonts/index.css';
import { AuthProvider } from './hooks/AuthContext';
import { LayoutProvider } from './hooks/LayoutContext';
import { ReportProvider } from './hooks/ReportContext';
import {
  apiKeyApiName,
  apiKeyApiPathRoot
} from './hooks/UseApiKeyApi/ApiHelper';
import {
  customerApiName,
  customerApiPathRoot
} from './hooks/UseCustomerApi/ApiHelper';
import {
  dashboardApiName,
  dashboardApiPathRoot,
  shareDashboardApiName
} from './hooks/UseDashboardApi/ApiHelper';
import { fileApiName, fileApiPathRoot } from './hooks/UseFileApi/ApiHelper';
import {
  pairingApiName,
  pairingApiPathRoot
} from './hooks/UsePairingApi/ApiHelper';
import {
  stripeSessionApiName,
  stripeSessionApiPathRoot
} from './hooks/UseStripeSessionApi/ApiHelper';
import './index.css';
import * as serviceWorker from './serviceWorker';
import materialUiTheme from './theme/materialUi';

// if app is running in production, disable debug statements
if (
  process.env.REACT_APP_USER_BRANCH &&
  process.env.REACT_APP_USER_BRANCH === 'master'
) {
  // only show `info`, `warn`, and `error` messages
  log.setLevel('info');

  // disable console.log
  console.log = function() {};
} else {
  log.setLevel('trace');
}

const API_HOST = `${process.env.REACT_APP_API_HOST ||
  'https://qvthkmtukh.execute-api.us-west-2.amazonaws.com/master'}`;
Amplify.configure({
  ...awsconfig,
  API: {
    endpoints: [
      {
        name: pairingApiName,
        endpoint: `${API_HOST}${pairingApiPathRoot}`,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          };
        }
      },
      {
        name: fileApiName,
        endpoint: `${API_HOST}${fileApiPathRoot}`,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          };
        }
      },
      {
        name: stripeSessionApiName,
        endpoint: `${API_HOST}${stripeSessionApiPathRoot}`,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          };
        }
      },
      {
        name: apiKeyApiName,
        endpoint: `${API_HOST}${apiKeyApiPathRoot}`,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          };
        }
      },
      {
        name: dashboardApiName,
        endpoint: `${API_HOST}${dashboardApiPathRoot}`,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          };
        }
      },
      {
        name: shareDashboardApiName,
        endpoint: `${API_HOST}${dashboardApiPathRoot}`
      },
      {
        name: customerApiName,
        endpoint: `${API_HOST}${customerApiPathRoot}`,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          };
        }
      }
    ]
  }
});

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: awsconfig.aws_project_region,
    aws_pubsub_endpoint: `wss://${process.env.REACT_APP_MQTT_ID}.iot.${awsconfig.aws_project_region}.amazonaws.com/mqtt`
  })
);

const client = new AWSAppSyncClient({
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () =>
      (await Auth.currentSession()).getIdToken().getJwtToken()
  },
  disableOffline: true
});

ReactDOM.render(
  <ApolloProvider client={client as any}>
    <Rehydrated>
      <ThemeProvider theme={materialUiTheme}>
        <CssBaseline />
        <BrowserRouter>
          <AuthProvider>
            <LayoutProvider>
              <ReportProvider>
                <AppWithAuth />
              </ReportProvider>
            </LayoutProvider>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Rehydrated>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
