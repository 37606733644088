import {
  Button,
  createStyles,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Popover,
  Theme,
  Typography
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { get } from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  DeviceCreateStep,
  routeAccount,
  routeAccountAlerts,
  routeDeviceAdd,
  routeDeviceListAll,
  routePlans
} from '../../../helpers/RouteHelper';
import { AuthContext } from '../../../hooks/AuthContext';
import { LayoutContext } from '../../../hooks/LayoutContext';
import { convertBytesToGb } from '../../../helpers/DataHelper';
import { AccountCircleIcon, ArrowDropDownIcon } from '../../../images/Icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuPaper: {
      width: 344,
      padding: theme.spacing(4)
    },
    menuButton: {
      marginLeft: theme.spacing(-1)
    },
    menuStorageUsage: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    menuStoragePercentage: {
      borderRadius: 2,
      marginBottom: theme.spacing(0.75),
      background: theme.palette.grey[600]
    },
    menuStoragePercentageBar: {
      borderRadius: 2
    },
    menuTierName: {
      textTransform: 'uppercase'
    },
    menuDivider: {
      paddingLeft: '100%',
      margin: theme.spacing(1.5, 0)
    }
  })
);

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const layoutContext = useContext(LayoutContext);
  const classes = useStyles();
  if (!layoutContext) {
    throw new Error('Layout context is missing');
  }
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error(
      'Header component must be used within a Auth Context Provider'
    );
  }
  const {
    customerData,
    signedIn,
    userInfo,
    customerAlerts,
    customerSensors
  } = authContext;
  const userEmail = get(userInfo, 'email');
  const userTier = get(customerData, 'tierId', 'free');
  const billingCost = get(customerData, 'billingCost');
  const userAlerts = customerAlerts || [];
  const userSensors = customerSensors || [];
  const userStorage = convertBytesToGb(get(customerData, 'storageConsumed', 0));
  const tierMaxStorage = get(customerData, 'tier.storageLimitGb');
  const userStoragePercentageUsed = Math.floor(
    (userStorage / tierMaxStorage) * 100
  );
  const logoutAction = useCallback(() => {
    closeMenu();
    Auth.signOut();
    window.location.reload();
  }, []);
  if (!signedIn) {
    return null;
  }

  // Managing state of Profile Menu
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const menuSensorLink =
    userSensors.length <= 0
      ? routeDeviceAdd(false, DeviceCreateStep.Form)
      : routeDeviceListAll();
  const menuSensorCta =
    userSensors.length <= 0 ? 'Link a Sensor' : `${userSensors.length} Linked`;
  const menuAlertCta =
    userAlerts.length <= 0 ? 'Set Up' : `${userAlerts.length} Active`;
  const planCta = billingCost === 0 ? 'Upgrade' : 'Change Plan';

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls={!!anchorEl ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        color="inherit"
        onClick={openMenu}
      >
        <AccountCircleIcon color="inherit" />
        <ArrowDropDownIcon color="inherit" />
      </IconButton>
      <Popover
        id={'header-popover'}
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        classes={{ paper: classes.menuPaper }}
      >
        <Grid container direction="row" justify="space-around">
          <Grid item xs={12}>
            <Typography variant="h2" component="div">
              Hello,
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="div">
              {userEmail}
            </Typography>
          </Grid>
          <Divider className={classes.menuDivider} />
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="body1" component="div">
                Storage
              </Typography>
            </Grid>
            <Grid item container xs={6}>
              <Grid item container xs={12} justify="flex-end">
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.menuStorageUsage}
                >
                  {userStorage} GB / {tierMaxStorage} GB
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <LinearProgress
                  variant="determinate"
                  color="primary"
                  value={userStoragePercentageUsed}
                  className={classes.menuStoragePercentage}
                  classes={{ bar: classes.menuStoragePercentageBar }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" component="div">
                Sensors
              </Typography>
            </Grid>
            <Grid item container xs={6} justify="flex-end">
              <Button
                color="primary"
                className={classes.menuButton}
                component={Link}
                to={menuSensorLink}
                onClick={closeMenu}
              >
                {menuSensorCta}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Alerts</Typography>
            </Grid>
            <Grid item container xs={6} justify="flex-end">
              <Button
                color="primary"
                className={classes.menuButton}
                component={Link}
                to={routeAccountAlerts()}
                onClick={closeMenu}
              >
                {menuAlertCta}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.menuTierName}>
              You have a {userTier} account
            </Typography>
          </Grid>
          <Divider className={classes.menuDivider} />
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Button
                color="primary"
                className={classes.menuButton}
                onClick={closeMenu}
                component={Link}
                to={routeAccount()}
              >
                My Profile
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                className={classes.menuButton}
                to={routePlans()}
                onClick={closeMenu}
                component={Link}
              >
                {planCta}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                className={classes.menuButton}
                onClick={logoutAction}
              >
                Log Out
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};
export default ProfileMenu;
