import {
  Button,
  Checkbox,
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import gql from 'graphql-tag';
import { findIndex, get, map, remove, startCase } from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import { withApollo, WithApolloClient } from 'react-apollo';
import { updateAlert } from '../../../../graphql/mutations';
import { pluralizeString } from '../../../../helpers/DataHelper';
import { LayoutContext } from '../../../../hooks/LayoutContext';

interface Props extends WithApolloClient<any> {
  newTierId: string;
  customerAlerts: any[] | null;
  maxAlerts: number;
  leaveDowngrade: () => void;
  continueDowngrade: () => void;
  setLoading: React.Dispatch<any>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogText: {
      margin: 0
    },
    checkboxLabelRoot: {
      margin: 0,
      display: 'flex',
      flex: '1 0 0'
    },
    checkboxLabel: {
      textTransform: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      flex: '1 0 auto',
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    customerItem: {
      justifyContent: 'space-between',
      paddingRight: theme.spacing(3),
      flexWrap: 'nowrap',
      borderBottom: `1px solid ${theme.palette.grey[500]}`
    },
    listContainer: {
      maxHeight: 210,
      overflowY: 'scroll',
      padding: theme.spacing(1, 1.5)
    }
  })
);

const AlertsError = (props: Props) => {
  const {
    newTierId,
    customerAlerts,
    maxAlerts,
    leaveDowngrade,
    continueDowngrade,
    client,
    setLoading
  } = props;

  const classes = useStyles();

  const layoutContext = useContext(LayoutContext);
  if (!layoutContext) {
    throw new Error(
      'App component must be used within a Layout Context Provider'
    );
  }
  const { pushGlobalSiteError } = layoutContext;

  const [enabledItems, setEnabledItems] = useState<string[]>([]);

  const generateCheckToggleAction = (itemId: string) => () => {
    let newEnabledItems: string[] = [...enabledItems];
    const isDisabled = remove(
      newEnabledItems,
      enabledItem => enabledItem === itemId
    );
    if (isDisabled.length <= 0) {
      newEnabledItems = [...newEnabledItems, itemId];
    }
    setEnabledItems(newEnabledItems);
  };
  const errorEnabledAtMax = enabledItems.length >= maxAlerts;

  const applyAndContinue = useCallback(async () => {
    if (!customerAlerts) return;
    setLoading(true);
    await Promise.all(
      map(customerAlerts, (customerItem: any) => {
        const itemId = get(customerItem, 'id');
        const enabled =
          findIndex(enabledItems, enabledItem => enabledItem === itemId) >= 0;
        return client.mutate({
          mutation: gql(updateAlert),
          variables: {
            input: {
              id: itemId,
              enabled
            }
          }
        });
      })
    )
      .then(() => {
        continueDowngrade();
      })
      .catch(err => {
        setLoading(false);
        pushGlobalSiteError(
          'Error encountered while updating alerts. Please try again.'
        );
      });
  }, [
    customerAlerts,
    enabledItems,
    client,
    continueDowngrade,
    pushGlobalSiteError,
    setLoading
  ]);

  if (!customerAlerts) return null;

  return (
    <>
      <DialogTitle id="form-dialog-title">Manage Custom Alerts</DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: classes.dialogText }}>
          You can only have {maxAlerts} custom{' '}
          {pluralizeString('alert', maxAlerts)} for the {startCase(newTierId)}{' '}
          Plan. Please select the custom {pluralizeString('alert', maxAlerts)}{' '}
          you want to keep on your account.
        </DialogContentText>
      </DialogContent>
      <Grid container className={classes.listContainer}>
        {customerAlerts.map((customerItem: any) => {
          const itemId = get(customerItem, 'id');
          const itemIsEnabled =
            findIndex(enabledItems, enabledItem => enabledItem === itemId) >= 0;
          const alertName = get(customerItem, 'name') || 'No Alert Name';
          const tagName = get(customerItem, 'tagName');

          return (
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justify="center"
              key={`alert-item-${customerItem.id}`}
              className={classes.customerItem}
            >
              <FormControlLabel
                classes={{
                  root: classes.checkboxLabelRoot,
                  label: classes.checkboxLabel
                }}
                control={
                  <Checkbox
                    value="sensorCheck"
                    color="secondary"
                    checked={itemIsEnabled}
                    disabled={errorEnabledAtMax && !itemIsEnabled}
                    onClick={generateCheckToggleAction(customerItem.id)}
                  />
                }
                label={
                  <>
                    <Typography variant="body2" component="div">
                      {alertName}
                    </Typography>
                    <Typography variant="body2" component="div">
                      {tagName}
                    </Typography>
                  </>
                }
              />
            </Grid>
          );
        })}
      </Grid>
      <DialogActions disableSpacing>
        <Button
          variant="text"
          size="large"
          color="default"
          onClick={leaveDowngrade}
        >
          Cancel
        </Button>
        <Button
          variant="text"
          size="large"
          color="default"
          onClick={applyAndContinue}
        >
          Apply
        </Button>
      </DialogActions>
    </>
  );
};

export default withApollo(AlertsError);
