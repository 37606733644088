import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { startCase } from 'lodash';
import React from 'react';

interface Props {
  newTierId: string;
  leaveDowngrade: () => void;
  continueDowngrade: () => void;
}

const StartManage = (props: Props) => {
  const { newTierId, leaveDowngrade, continueDowngrade } = props;

  return (
    <>
      <DialogTitle id="form-dialog-title">Manage Downgrade</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please confirm you want to downgrade to the {startCase(newTierId)}{' '}
          Plan. You will need to manage your account before you can downgrade
          your plan.
        </DialogContentText>
      </DialogContent>
      <DialogActions disableSpacing>
        <Button
          variant="text"
          size="large"
          color="default"
          onClick={leaveDowngrade}
        >
          Cancel
        </Button>
        <Button
          variant="text"
          size="large"
          color="default"
          onClick={continueDowngrade}
        >
          Manage
        </Button>
      </DialogActions>
    </>
  );
};

export default StartManage;
