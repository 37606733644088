import {
  AppBar,
  Button,
  Collapse,
  createStyles,
  LinearProgress,
  makeStyles,
  Theme,
  Toolbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import classnames from 'classnames';
import { format } from 'date-fns';
import { get, startCase } from 'lodash';
import React, { useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { drawerWidth } from '../../helpers/NavHelper';
import {
  routeAccountBilling,
  routeDashboard,
  routePlans
} from '../../helpers/RouteHelper';
import { AuthContext } from '../../hooks/AuthContext';
import { LayoutContext } from '../../hooks/LayoutContext';
import { StripeSessionProvider } from '../../hooks/UseStripeSessionApi/StripeSessionContext';
import { ReportProblemIcon } from '../../images/Icons';
import { ReactComponent as Logotype } from '../../images/Logos/Logotype-white.svg';
import NavPrimary from '../NavPrimary';
import SuspendedOverlay from '../SuspendedOverlay';
import NotificationsMenu from './components/NotificationsMenu';
import ProfileMenu from './components/ProfileMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContent: {
      display: 'flex'
    },
    snackbarAlert: {
      borderRadius: 0,
      fontSize: '1.4rem',
      fontWeight: 'bold',
      color: theme.palette.grey[700],
      padding: '11px 40px',
      borderBottom: `1px solid ${theme.palette.grey['A100']}`
    },
    action: {
      '& svg': {
        fontSize: '2.4rem'
      }
    },
    appBar: {
      backgroundColor: theme.palette.grey[900]
    },
    appBarUnderline: {
      height: 4,
      background: theme.palette.primary.main
    },
    appBarLoggedOut: {
      backgroundColor: theme.palette.grey[900],
      marginLeft: drawerWidth
    },
    gutters: {
      paddingLeft: 20,
      paddingRight: 20,
      display: 'flex',
      justifyContent: 'space-between'
    },
    hasRightDrawer: {
      [theme.breakpoints.up('md')]: {
        paddingRight: drawerWidth
      }
    },
    logotype: {
      display: 'flex'
    },
    logotypeWrapper: {
      textAlign: 'left',
      height: 19
    },
    menuSection: {
      display: 'flex',
      color: theme.palette.common.white
    }
  })
);

export enum GlobalSiteAlertTypes {
  tierSelected = 'tierSelected',
  frequencySelected = 'frequencySelected',
  passwordChanged = 'passwordChanged',
  updatedCustomReportSettings = 'updatedCustomReportSettings',
  billingUpdated = 'billingUpdated',
  accountReactivated = 'accountReactivated',
  accountPastDue = 'accountPastDue',
  accountSuspended = 'accountSuspended'
}
export const globalSiteAlertText = {
  [GlobalSiteAlertTypes.tierSelected]: ({ tierId }: { tierId: string }) =>
    `You are now on the ${startCase(tierId)} plan.`,
  [GlobalSiteAlertTypes.frequencySelected]: () =>
    `Your billing frequency has been updated`,
  [GlobalSiteAlertTypes.passwordChanged]: () =>
    'You have successfully changed your password',
  [GlobalSiteAlertTypes.updatedCustomReportSettings]: () =>
    'You have successfully updated your Custom Report Settings',
  [GlobalSiteAlertTypes.billingUpdated]: () =>
    'Your billing information is updated.',
  [GlobalSiteAlertTypes.accountReactivated]: () =>
    'Your account has been re-activated.',
  [GlobalSiteAlertTypes.accountPastDue]: ({
    suspendedAt
  }: {
    suspendedAt: number;
  }) =>
    `Your account is past due. Please update your billing information ${
      suspendedAt > 0 ? `before ${format(suspendedAt, 'MM/DD/YYYY')}` : ''
    } to avoid service interruption.`,
  [GlobalSiteAlertTypes.accountSuspended]: () =>
    'Your account has been suspended. Please update your billing information to restart service.'
};

interface Props extends RouteComponentProps {}

const Header = (props: Props) => {
  const { history, location } = props;
  const layoutContext = useContext(LayoutContext);
  const classes = useStyles();
  if (!layoutContext) {
    throw new Error('Layout context is missing');
  }
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error(
      'Header component must be used within a Auth Context Provider'
    );
  }
  const { customerData, isAuthorized, isSuspended, signedIn } = authContext;

  if (!signedIn) {
    return null;
  }
  const {
    rightDrawerOpen,
    loading,
    globalSiteAlerts,
    removeGlobalSiteAlert
  } = layoutContext;

  const siteAlertContent = {
    tierId: get(customerData, 'tierId', ''),
    suspendedAt: get(customerData, 'billing.suspended_at', 0) * 1000
  };
  const userChangingPlan =
    location.pathname === routePlans() ||
    location.pathname === routeAccountBilling();

  return (
    <>
      <AppBar
        position="relative"
        className={classnames(
          classes.headerContent,
          { [classes.appBar]: signedIn },
          { [classes.appBarLoggedOut]: !signedIn },
          { [classes.hasRightDrawer]: rightDrawerOpen }
        )}
        elevation={0}
        color="default"
        component="div"
      >
        <Collapse in={globalSiteAlerts.length > 0}>
          {globalSiteAlerts.map((globalSiteAlert, alertIndex) => (
            <Alert
              key={`global-alert-${alertIndex}`}
              className={classes.snackbarAlert}
              classes={{ action: classes.action }}
              severity="warning"
              icon={<ReportProblemIcon color="primary" />}
              onClose={() => removeGlobalSiteAlert(alertIndex)}
            >
              {globalSiteAlertText[globalSiteAlert](siteAlertContent)}
            </Alert>
          ))}
        </Collapse>
        <Toolbar classes={{ gutters: classes.gutters }} role="banner">
          <Button
            className={classes.logotype}
            onClick={() => {
              history.push({
                pathname: routeDashboard()
              });
            }}
          >
            <div className={classes.logotypeWrapper}>
              <Logotype />
            </div>
          </Button>
          {isAuthorized && signedIn && (
            <div className={classes.menuSection}>
              <NotificationsMenu />
              <ProfileMenu />
            </div>
          )}
        </Toolbar>
        <div className={classes.appBarUnderline}>
          {loading ? <LinearProgress /> : null}
        </div>
      </AppBar>
      {isAuthorized && <NavPrimary />}
      <StripeSessionProvider>
        <>{isSuspended && !userChangingPlan && <SuspendedOverlay />}</>
      </StripeSessionProvider>
    </>
  );
};
export default withRouter(Header);
