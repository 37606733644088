import { Backdrop, CircularProgress, Dialog } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { get } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { convertBytesToGb } from '../../../helpers/DataHelper';
import { AuthContext } from '../../../hooks/AuthContext';
import AlertsError from './Components/AlertsError';
import ApiKeyError from './Components/ApiKeyError';
import ConfirmStart from './Components/ConfirmStart';
import ScriptsWarning from './Components/ScriptsWarning';
import SensorsError from './Components/SensorsError';
import StartManage from './Components/StartManage';
import StorageError from './Components/StorageError';

interface Props {
  newTierId: string;
  maxAlerts: number;
  maxSensors: number;
  maxStorage: number;
  hasApiAccess: boolean;
  downgradeOpen: boolean;
  setDowngradeOpen: React.Dispatch<boolean>;
  successCallback: () => void;
}

const flowToMaxWidth: { [key: string]: any } = {
  confirmStart: 'xs',
  startManage: 'xs',
  storageError: 'sm',
  apiKeyError: 'sm',
  sensorsError: 'sm',
  alertsError: 'sm'
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: '#fff'
    }
  })
);

const PlansDowngrade = (props: Props) => {
  const {
    newTierId,
    maxAlerts,
    maxSensors,
    maxStorage,
    hasApiAccess,
    downgradeOpen,
    setDowngradeOpen,
    successCallback
  } = props;
  const classes = useStyles();

  const [currentStepId, setCurrentStepId] = useState<null | number>(0);
  const [loading, setLoading] = useState(false);

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error(
      'App component must be used within a Auth Context Provider'
    );
  }
  const {
    customerData,
    customerAlerts,
    customerApiKeys,
    customerSensors,
    customerScripts,
    refetchCustomerData
  } = authContext;

  const downgradeFlow = useMemo(() => {
    const userAlertCount = customerAlerts ? customerAlerts.length : 0;
    const userApiKeyCount = customerApiKeys ? customerApiKeys.length : 0;
    const userSensorCount = customerSensors ? customerSensors.length : 0;
    const customScriptsCount = customerScripts ? customerScripts.length : 0;
    const userStorage = convertBytesToGb(
      get(customerData, 'storageConsumed', 0)
    );

    const alertsError = userAlertCount > maxAlerts && maxAlerts >= 0;
    const apiKeyError = userApiKeyCount > 0 && !hasApiAccess;
    const sensorsError = userSensorCount > maxSensors && maxSensors >= 0;
    const storageError = userStorage > maxStorage;
    const scriptsError = newTierId === 'free' && customScriptsCount > 0;

    let flow: string[] = [];
    if (alertsError || sensorsError || storageError) {
      flow = [...flow, 'startManage'];
    } else {
      flow = [...flow, 'confirmStart'];
    }
    if (apiKeyError) flow = [...flow, 'apiKeyError'];
    if (storageError) flow = [...flow, 'storageError'];
    if (sensorsError) flow = [...flow, 'sensorsError'];
    if (alertsError) flow = [...flow, 'alertsError'];
    if (scriptsError) flow = [...flow, 'scriptsError'];
    return flow;
  }, [
    customerAlerts,
    customerApiKeys,
    customerSensors,
    customerData,
    maxAlerts,
    maxSensors,
    maxStorage,
    hasApiAccess
  ]);

  useEffect(() => {
    setCurrentStepId(0);
  }, [downgradeOpen, downgradeFlow]);

  const leaveDowngrade = useCallback(() => {
    setCurrentStepId(null);
    setDowngradeOpen(false);
    refetchCustomerData();
  }, [setCurrentStepId, setDowngradeOpen, refetchCustomerData]);

  const continueDowngrade = useCallback(() => {
    if (currentStepId === null) return;
    setLoading(false);
    const newStepId = currentStepId + 1;
    if (newStepId >= downgradeFlow.length) {
      successCallback();
      leaveDowngrade();
    } else {
      setCurrentStepId(newStepId);
    }
  }, [
    currentStepId,
    downgradeFlow,
    leaveDowngrade,
    successCallback,
    setLoading
  ]);

  if (currentStepId === null || !downgradeOpen) return null;

  const planDowngradeMode = downgradeFlow[currentStepId];

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={!!planDowngradeMode && !loading}
        onClose={leaveDowngrade}
        aria-labelledby="form-dialog-title"
        maxWidth={flowToMaxWidth[planDowngradeMode] || 'xs'}
      >
        {planDowngradeMode === 'confirmStart' && (
          <ConfirmStart
            newTierId={newTierId}
            leaveDowngrade={leaveDowngrade}
            continueDowngrade={continueDowngrade}
          />
        )}
        {planDowngradeMode === 'scriptsError' && (
          <ScriptsWarning
            leaveDowngrade={leaveDowngrade}
            continueDowngrade={continueDowngrade}
          />
        )}
        {planDowngradeMode === 'startManage' && (
          <StartManage
            newTierId={newTierId}
            leaveDowngrade={leaveDowngrade}
            continueDowngrade={continueDowngrade}
          />
        )}
        {planDowngradeMode === 'storageError' && (
          <StorageError
            newTierId={newTierId}
            leaveDowngrade={leaveDowngrade}
            continueDowngrade={continueDowngrade}
          />
        )}
        {planDowngradeMode === 'sensorsError' && (
          <SensorsError
            newTierId={newTierId}
            customerSensors={customerSensors}
            maxSensors={maxSensors}
            leaveDowngrade={leaveDowngrade}
            continueDowngrade={continueDowngrade}
            setLoading={setLoading}
          />
        )}
        {planDowngradeMode === 'alertsError' && (
          <AlertsError
            newTierId={newTierId}
            customerAlerts={customerAlerts}
            maxAlerts={maxAlerts}
            leaveDowngrade={leaveDowngrade}
            continueDowngrade={continueDowngrade}
            setLoading={setLoading}
          />
        )}
        {planDowngradeMode === 'apiKeyError' && (
          <ApiKeyError
            newTierId={newTierId}
            customerApiKeys={customerApiKeys}
            leaveDowngrade={leaveDowngrade}
            continueDowngrade={continueDowngrade}
            setLoading={setLoading}
          />
        )}
      </Dialog>
    </>
  );
};

export default PlansDowngrade;
