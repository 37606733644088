/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAlert = /* GraphQL */ `
  mutation CreateAlert(
    $input: CreateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    createAlert(input: $input, condition: $condition) {
      id
      owner
      customerId
      name
      tagName
      enabled
      createdAt
      updatedAt
      customer {
        id
        owner
        storageConsumed
        storageSpaceRemaining
        tierId
        createdAt
        updatedAt
        companyName
        logoImage
        apiKeys {
          nextToken
        }
        billing {
          id
          stripe_session_id
          owner
          stripe_customer_id
          stripe_subscription_id
          subscription_status
          stripe_plan_id
          suspended_at
          next_billing_at
          createdAt
          updatedAt
        }
        deviceAssociations {
          nextToken
        }
        tier {
          id
          costMonthly
          costYearly
          deviceMaxCount
          storageLimitGb
          alertMaxCount
          hasApiAccess
          billingCycles
          reportMaxCount
          reportTemplate
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateAlert = /* GraphQL */ `
  mutation UpdateAlert(
    $input: UpdateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    updateAlert(input: $input, condition: $condition) {
      id
      owner
      customerId
      name
      tagName
      enabled
      createdAt
      updatedAt
      customer {
        id
        owner
        storageConsumed
        storageSpaceRemaining
        tierId
        createdAt
        updatedAt
        companyName
        logoImage
        apiKeys {
          nextToken
        }
        billing {
          id
          stripe_session_id
          owner
          stripe_customer_id
          stripe_subscription_id
          subscription_status
          stripe_plan_id
          suspended_at
          next_billing_at
          createdAt
          updatedAt
        }
        deviceAssociations {
          nextToken
        }
        tier {
          id
          costMonthly
          costYearly
          deviceMaxCount
          storageLimitGb
          alertMaxCount
          hasApiAccess
          billingCycles
          reportMaxCount
          reportTemplate
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteAlert = /* GraphQL */ `
  mutation DeleteAlert(
    $input: DeleteAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    deleteAlert(input: $input, condition: $condition) {
      id
      owner
      customerId
      name
      tagName
      enabled
      createdAt
      updatedAt
      customer {
        id
        owner
        storageConsumed
        storageSpaceRemaining
        tierId
        createdAt
        updatedAt
        companyName
        logoImage
        apiKeys {
          nextToken
        }
        billing {
          id
          stripe_session_id
          owner
          stripe_customer_id
          stripe_subscription_id
          subscription_status
          stripe_plan_id
          suspended_at
          next_billing_at
          createdAt
          updatedAt
        }
        deviceAssociations {
          nextToken
        }
        tier {
          id
          costMonthly
          costYearly
          deviceMaxCount
          storageLimitGb
          alertMaxCount
          hasApiAccess
          billingCycles
          reportMaxCount
          reportTemplate
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createApiKey = /* GraphQL */ `
  mutation CreateApiKey(
    $input: CreateApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    createApiKey(input: $input, condition: $condition) {
      id
      name
      owner
      revoked
      createdAt
      updatedAt
    }
  }
`;
export const updateApiKey = /* GraphQL */ `
  mutation UpdateApiKey(
    $input: UpdateApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    updateApiKey(input: $input, condition: $condition) {
      id
      name
      owner
      revoked
      createdAt
      updatedAt
    }
  }
`;
export const deleteApiKey = /* GraphQL */ `
  mutation DeleteApiKey(
    $input: DeleteApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    deleteApiKey(input: $input, condition: $condition) {
      id
      name
      owner
      revoked
      createdAt
      updatedAt
    }
  }
`;
export const createAttribute = /* GraphQL */ `
  mutation CreateAttribute(
    $input: CreateAttributeInput!
    $condition: ModelAttributeConditionInput
  ) {
    createAttribute(input: $input, condition: $condition) {
      id
      user_id
      attributeFileId
      name
      type
      value
      thing
      mutable
      createdAt
      updatedAt
    }
  }
`;
export const updateAttribute = /* GraphQL */ `
  mutation UpdateAttribute(
    $input: UpdateAttributeInput!
    $condition: ModelAttributeConditionInput
  ) {
    updateAttribute(input: $input, condition: $condition) {
      id
      user_id
      attributeFileId
      name
      type
      value
      thing
      mutable
      createdAt
      updatedAt
    }
  }
`;
export const deleteAttribute = /* GraphQL */ `
  mutation DeleteAttribute(
    $input: DeleteAttributeInput!
    $condition: ModelAttributeConditionInput
  ) {
    deleteAttribute(input: $input, condition: $condition) {
      id
      user_id
      attributeFileId
      name
      type
      value
      thing
      mutable
      createdAt
      updatedAt
    }
  }
`;
export const createBilling = /* GraphQL */ `
  mutation CreateBilling(
    $input: CreateBillingInput!
    $condition: ModelBillingConditionInput
  ) {
    createBilling(input: $input, condition: $condition) {
      id
      stripe_session_id
      owner
      stripe_customer_id
      stripe_subscription_id
      subscription_status
      stripe_plan_id
      suspended_at
      next_billing_at
      createdAt
      updatedAt
      customer {
        id
        owner
        storageConsumed
        storageSpaceRemaining
        tierId
        createdAt
        updatedAt
        companyName
        logoImage
        apiKeys {
          nextToken
        }
        billing {
          id
          stripe_session_id
          owner
          stripe_customer_id
          stripe_subscription_id
          subscription_status
          stripe_plan_id
          suspended_at
          next_billing_at
          createdAt
          updatedAt
        }
        deviceAssociations {
          nextToken
        }
        tier {
          id
          costMonthly
          costYearly
          deviceMaxCount
          storageLimitGb
          alertMaxCount
          hasApiAccess
          billingCycles
          reportMaxCount
          reportTemplate
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateBilling = /* GraphQL */ `
  mutation UpdateBilling(
    $input: UpdateBillingInput!
    $condition: ModelBillingConditionInput
  ) {
    updateBilling(input: $input, condition: $condition) {
      id
      stripe_session_id
      owner
      stripe_customer_id
      stripe_subscription_id
      subscription_status
      stripe_plan_id
      suspended_at
      next_billing_at
      createdAt
      updatedAt
      customer {
        id
        owner
        storageConsumed
        storageSpaceRemaining
        tierId
        createdAt
        updatedAt
        companyName
        logoImage
        apiKeys {
          nextToken
        }
        billing {
          id
          stripe_session_id
          owner
          stripe_customer_id
          stripe_subscription_id
          subscription_status
          stripe_plan_id
          suspended_at
          next_billing_at
          createdAt
          updatedAt
        }
        deviceAssociations {
          nextToken
        }
        tier {
          id
          costMonthly
          costYearly
          deviceMaxCount
          storageLimitGb
          alertMaxCount
          hasApiAccess
          billingCycles
          reportMaxCount
          reportTemplate
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteBilling = /* GraphQL */ `
  mutation DeleteBilling(
    $input: DeleteBillingInput!
    $condition: ModelBillingConditionInput
  ) {
    deleteBilling(input: $input, condition: $condition) {
      id
      stripe_session_id
      owner
      stripe_customer_id
      stripe_subscription_id
      subscription_status
      stripe_plan_id
      suspended_at
      next_billing_at
      createdAt
      updatedAt
      customer {
        id
        owner
        storageConsumed
        storageSpaceRemaining
        tierId
        createdAt
        updatedAt
        companyName
        logoImage
        apiKeys {
          nextToken
        }
        billing {
          id
          stripe_session_id
          owner
          stripe_customer_id
          stripe_subscription_id
          subscription_status
          stripe_plan_id
          suspended_at
          next_billing_at
          createdAt
          updatedAt
        }
        deviceAssociations {
          nextToken
        }
        tier {
          id
          costMonthly
          costYearly
          deviceMaxCount
          storageLimitGb
          alertMaxCount
          hasApiAccess
          billingCycles
          reportMaxCount
          reportTemplate
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createCustomScript = /* GraphQL */ `
  mutation CreateCustomScript(
    $input: CreateCustomScriptInput!
    $condition: ModelCustomScriptConditionInput
  ) {
    createCustomScript(input: $input, condition: $condition) {
      id
      owner
      error {
        code
        detail
        message
        status
        traces {
          filename
          lineno
          name
          offset
          text
        }
        transactionId
        type
      }
      lines
      status
      title
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomScript = /* GraphQL */ `
  mutation UpdateCustomScript(
    $input: UpdateCustomScriptInput!
    $condition: ModelCustomScriptConditionInput
  ) {
    updateCustomScript(input: $input, condition: $condition) {
      id
      owner
      error {
        code
        detail
        message
        status
        traces {
          filename
          lineno
          name
          offset
          text
        }
        transactionId
        type
      }
      lines
      status
      title
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomScript = /* GraphQL */ `
  mutation DeleteCustomScript(
    $input: DeleteCustomScriptInput!
    $condition: ModelCustomScriptConditionInput
  ) {
    deleteCustomScript(input: $input, condition: $condition) {
      id
      owner
      error {
        code
        detail
        message
        status
        traces {
          filename
          lineno
          name
          offset
          text
        }
        transactionId
        type
      }
      lines
      status
      title
      type
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      owner
      storageConsumed
      storageSpaceRemaining
      tierId
      createdAt
      updatedAt
      companyName
      logoImage
      apiKeys {
        items {
          id
          name
          owner
          revoked
          createdAt
          updatedAt
        }
        nextToken
      }
      billing {
        id
        stripe_session_id
        owner
        stripe_customer_id
        stripe_subscription_id
        subscription_status
        stripe_plan_id
        suspended_at
        next_billing_at
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
      }
      deviceAssociations {
        items {
          id
          org_id
          s3_bucket_name
          serial_number_id
          status
          user_id
          customerId
          createdAt
          updatedAt
        }
        nextToken
      }
      tier {
        id
        costMonthly
        costYearly
        deviceMaxCount
        storageLimitGb
        alertMaxCount
        hasApiAccess
        billingCycles
        reportMaxCount
        reportTemplate
        createdAt
        updatedAt
        stripePlans {
          nextToken
        }
      }
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      owner
      storageConsumed
      storageSpaceRemaining
      tierId
      createdAt
      updatedAt
      companyName
      logoImage
      apiKeys {
        items {
          id
          name
          owner
          revoked
          createdAt
          updatedAt
        }
        nextToken
      }
      billing {
        id
        stripe_session_id
        owner
        stripe_customer_id
        stripe_subscription_id
        subscription_status
        stripe_plan_id
        suspended_at
        next_billing_at
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
      }
      deviceAssociations {
        items {
          id
          org_id
          s3_bucket_name
          serial_number_id
          status
          user_id
          customerId
          createdAt
          updatedAt
        }
        nextToken
      }
      tier {
        id
        costMonthly
        costYearly
        deviceMaxCount
        storageLimitGb
        alertMaxCount
        hasApiAccess
        billingCycles
        reportMaxCount
        reportTemplate
        createdAt
        updatedAt
        stripePlans {
          nextToken
        }
      }
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      owner
      storageConsumed
      storageSpaceRemaining
      tierId
      createdAt
      updatedAt
      companyName
      logoImage
      apiKeys {
        items {
          id
          name
          owner
          revoked
          createdAt
          updatedAt
        }
        nextToken
      }
      billing {
        id
        stripe_session_id
        owner
        stripe_customer_id
        stripe_subscription_id
        subscription_status
        stripe_plan_id
        suspended_at
        next_billing_at
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
      }
      deviceAssociations {
        items {
          id
          org_id
          s3_bucket_name
          serial_number_id
          status
          user_id
          customerId
          createdAt
          updatedAt
        }
        nextToken
      }
      tier {
        id
        costMonthly
        costYearly
        deviceMaxCount
        storageLimitGb
        alertMaxCount
        hasApiAccess
        billingCycles
        reportMaxCount
        reportTemplate
        createdAt
        updatedAt
        stripePlans {
          nextToken
        }
      }
    }
  }
`;
export const createDashboard = /* GraphQL */ `
  mutation CreateDashboard(
    $input: CreateDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    createDashboard(input: $input, condition: $condition) {
      id
      owner
      customScriptId
      defaultScript
      error {
        code
        message
        status
        transactionId
        scriptError
      }
      output_logs
      lastTransactionId
      maxFiles
      status
      tags
      file_ids
      title
      createdAt
      updatedAt
      customScript {
        id
        owner
        error {
          code
          detail
          message
          status
          transactionId
          type
        }
        lines
        status
        title
        type
        createdAt
        updatedAt
      }
      notes
      sequence
      modules {
        items {
          id
          owner
          dashboardId
          sequence
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      shares {
        items {
          id
          dashboardId
          token
          expires
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateDashboard = /* GraphQL */ `
  mutation UpdateDashboard(
    $input: UpdateDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    updateDashboard(input: $input, condition: $condition) {
      id
      owner
      customScriptId
      defaultScript
      error {
        code
        message
        status
        transactionId
        scriptError
      }
      output_logs
      lastTransactionId
      maxFiles
      status
      tags
      file_ids
      title
      createdAt
      updatedAt
      customScript {
        id
        owner
        error {
          code
          detail
          message
          status
          transactionId
          type
        }
        lines
        status
        title
        type
        createdAt
        updatedAt
      }
      notes
      sequence
      modules {
        items {
          id
          owner
          dashboardId
          sequence
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      shares {
        items {
          id
          dashboardId
          token
          expires
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteDashboard = /* GraphQL */ `
  mutation DeleteDashboard(
    $input: DeleteDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    deleteDashboard(input: $input, condition: $condition) {
      id
      owner
      customScriptId
      defaultScript
      error {
        code
        message
        status
        transactionId
        scriptError
      }
      output_logs
      lastTransactionId
      maxFiles
      status
      tags
      file_ids
      title
      createdAt
      updatedAt
      customScript {
        id
        owner
        error {
          code
          detail
          message
          status
          transactionId
          type
        }
        lines
        status
        title
        type
        createdAt
        updatedAt
      }
      notes
      sequence
      modules {
        items {
          id
          owner
          dashboardId
          sequence
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      shares {
        items {
          id
          dashboardId
          token
          expires
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const createDashboardModule = /* GraphQL */ `
  mutation CreateDashboardModule(
    $input: CreateDashboardModuleInput!
    $condition: ModelDashboardModuleConditionInput
  ) {
    createDashboardModule(input: $input, condition: $condition) {
      id
      owner
      dashboardId
      error {
        code
        message
        status
        transactionId
      }
      sequence
      title
      createdAt
      updatedAt
      dashboard {
        id
        owner
        customScriptId
        defaultScript
        error {
          code
          message
          status
          transactionId
          scriptError
        }
        output_logs
        lastTransactionId
        maxFiles
        status
        tags
        file_ids
        title
        createdAt
        updatedAt
        customScript {
          id
          owner
          lines
          status
          title
          type
          createdAt
          updatedAt
        }
        notes
        sequence
        modules {
          nextToken
        }
        shares {
          nextToken
        }
      }
      data {
        items {
          id
          owner
          moduleId
          json
          sequence
          createdAt
          updatedAt
        }
        nextToken
      }
      frames {
        items {
          id
          owner
          moduleId
          json
          sequence
          createdAt
          updatedAt
        }
        nextToken
      }
      layout {
        id
        owner
        json
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateDashboardModule = /* GraphQL */ `
  mutation UpdateDashboardModule(
    $input: UpdateDashboardModuleInput!
    $condition: ModelDashboardModuleConditionInput
  ) {
    updateDashboardModule(input: $input, condition: $condition) {
      id
      owner
      dashboardId
      error {
        code
        message
        status
        transactionId
      }
      sequence
      title
      createdAt
      updatedAt
      dashboard {
        id
        owner
        customScriptId
        defaultScript
        error {
          code
          message
          status
          transactionId
          scriptError
        }
        output_logs
        lastTransactionId
        maxFiles
        status
        tags
        file_ids
        title
        createdAt
        updatedAt
        customScript {
          id
          owner
          lines
          status
          title
          type
          createdAt
          updatedAt
        }
        notes
        sequence
        modules {
          nextToken
        }
        shares {
          nextToken
        }
      }
      data {
        items {
          id
          owner
          moduleId
          json
          sequence
          createdAt
          updatedAt
        }
        nextToken
      }
      frames {
        items {
          id
          owner
          moduleId
          json
          sequence
          createdAt
          updatedAt
        }
        nextToken
      }
      layout {
        id
        owner
        json
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteDashboardModule = /* GraphQL */ `
  mutation DeleteDashboardModule(
    $input: DeleteDashboardModuleInput!
    $condition: ModelDashboardModuleConditionInput
  ) {
    deleteDashboardModule(input: $input, condition: $condition) {
      id
      owner
      dashboardId
      error {
        code
        message
        status
        transactionId
      }
      sequence
      title
      createdAt
      updatedAt
      dashboard {
        id
        owner
        customScriptId
        defaultScript
        error {
          code
          message
          status
          transactionId
          scriptError
        }
        output_logs
        lastTransactionId
        maxFiles
        status
        tags
        file_ids
        title
        createdAt
        updatedAt
        customScript {
          id
          owner
          lines
          status
          title
          type
          createdAt
          updatedAt
        }
        notes
        sequence
        modules {
          nextToken
        }
        shares {
          nextToken
        }
      }
      data {
        items {
          id
          owner
          moduleId
          json
          sequence
          createdAt
          updatedAt
        }
        nextToken
      }
      frames {
        items {
          id
          owner
          moduleId
          json
          sequence
          createdAt
          updatedAt
        }
        nextToken
      }
      layout {
        id
        owner
        json
        createdAt
        updatedAt
      }
    }
  }
`;
export const createDashboardModuleData = /* GraphQL */ `
  mutation CreateDashboardModuleData(
    $input: CreateDashboardModuleDataInput!
    $condition: ModelDashboardModuleDataConditionInput
  ) {
    createDashboardModuleData(input: $input, condition: $condition) {
      id
      owner
      moduleId
      json
      sequence
      createdAt
      updatedAt
      module {
        id
        owner
        dashboardId
        error {
          code
          message
          status
          transactionId
        }
        sequence
        title
        createdAt
        updatedAt
        dashboard {
          id
          owner
          customScriptId
          defaultScript
          output_logs
          lastTransactionId
          maxFiles
          status
          tags
          file_ids
          title
          createdAt
          updatedAt
          notes
          sequence
        }
        data {
          nextToken
        }
        frames {
          nextToken
        }
        layout {
          id
          owner
          json
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateDashboardModuleData = /* GraphQL */ `
  mutation UpdateDashboardModuleData(
    $input: UpdateDashboardModuleDataInput!
    $condition: ModelDashboardModuleDataConditionInput
  ) {
    updateDashboardModuleData(input: $input, condition: $condition) {
      id
      owner
      moduleId
      json
      sequence
      createdAt
      updatedAt
      module {
        id
        owner
        dashboardId
        error {
          code
          message
          status
          transactionId
        }
        sequence
        title
        createdAt
        updatedAt
        dashboard {
          id
          owner
          customScriptId
          defaultScript
          output_logs
          lastTransactionId
          maxFiles
          status
          tags
          file_ids
          title
          createdAt
          updatedAt
          notes
          sequence
        }
        data {
          nextToken
        }
        frames {
          nextToken
        }
        layout {
          id
          owner
          json
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteDashboardModuleData = /* GraphQL */ `
  mutation DeleteDashboardModuleData(
    $input: DeleteDashboardModuleDataInput!
    $condition: ModelDashboardModuleDataConditionInput
  ) {
    deleteDashboardModuleData(input: $input, condition: $condition) {
      id
      owner
      moduleId
      json
      sequence
      createdAt
      updatedAt
      module {
        id
        owner
        dashboardId
        error {
          code
          message
          status
          transactionId
        }
        sequence
        title
        createdAt
        updatedAt
        dashboard {
          id
          owner
          customScriptId
          defaultScript
          output_logs
          lastTransactionId
          maxFiles
          status
          tags
          file_ids
          title
          createdAt
          updatedAt
          notes
          sequence
        }
        data {
          nextToken
        }
        frames {
          nextToken
        }
        layout {
          id
          owner
          json
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createDashboardModuleFrame = /* GraphQL */ `
  mutation CreateDashboardModuleFrame(
    $input: CreateDashboardModuleFrameInput!
    $condition: ModelDashboardModuleFrameConditionInput
  ) {
    createDashboardModuleFrame(input: $input, condition: $condition) {
      id
      owner
      moduleId
      json
      sequence
      createdAt
      updatedAt
      module {
        id
        owner
        dashboardId
        error {
          code
          message
          status
          transactionId
        }
        sequence
        title
        createdAt
        updatedAt
        dashboard {
          id
          owner
          customScriptId
          defaultScript
          output_logs
          lastTransactionId
          maxFiles
          status
          tags
          file_ids
          title
          createdAt
          updatedAt
          notes
          sequence
        }
        data {
          nextToken
        }
        frames {
          nextToken
        }
        layout {
          id
          owner
          json
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateDashboardModuleFrame = /* GraphQL */ `
  mutation UpdateDashboardModuleFrame(
    $input: UpdateDashboardModuleFrameInput!
    $condition: ModelDashboardModuleFrameConditionInput
  ) {
    updateDashboardModuleFrame(input: $input, condition: $condition) {
      id
      owner
      moduleId
      json
      sequence
      createdAt
      updatedAt
      module {
        id
        owner
        dashboardId
        error {
          code
          message
          status
          transactionId
        }
        sequence
        title
        createdAt
        updatedAt
        dashboard {
          id
          owner
          customScriptId
          defaultScript
          output_logs
          lastTransactionId
          maxFiles
          status
          tags
          file_ids
          title
          createdAt
          updatedAt
          notes
          sequence
        }
        data {
          nextToken
        }
        frames {
          nextToken
        }
        layout {
          id
          owner
          json
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteDashboardModuleFrame = /* GraphQL */ `
  mutation DeleteDashboardModuleFrame(
    $input: DeleteDashboardModuleFrameInput!
    $condition: ModelDashboardModuleFrameConditionInput
  ) {
    deleteDashboardModuleFrame(input: $input, condition: $condition) {
      id
      owner
      moduleId
      json
      sequence
      createdAt
      updatedAt
      module {
        id
        owner
        dashboardId
        error {
          code
          message
          status
          transactionId
        }
        sequence
        title
        createdAt
        updatedAt
        dashboard {
          id
          owner
          customScriptId
          defaultScript
          output_logs
          lastTransactionId
          maxFiles
          status
          tags
          file_ids
          title
          createdAt
          updatedAt
          notes
          sequence
        }
        data {
          nextToken
        }
        frames {
          nextToken
        }
        layout {
          id
          owner
          json
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createDashboardModuleLayout = /* GraphQL */ `
  mutation CreateDashboardModuleLayout(
    $input: CreateDashboardModuleLayoutInput!
    $condition: ModelDashboardModuleLayoutConditionInput
  ) {
    createDashboardModuleLayout(input: $input, condition: $condition) {
      id
      owner
      json
      createdAt
      updatedAt
    }
  }
`;
export const updateDashboardModuleLayout = /* GraphQL */ `
  mutation UpdateDashboardModuleLayout(
    $input: UpdateDashboardModuleLayoutInput!
    $condition: ModelDashboardModuleLayoutConditionInput
  ) {
    updateDashboardModuleLayout(input: $input, condition: $condition) {
      id
      owner
      json
      createdAt
      updatedAt
    }
  }
`;
export const deleteDashboardModuleLayout = /* GraphQL */ `
  mutation DeleteDashboardModuleLayout(
    $input: DeleteDashboardModuleLayoutInput!
    $condition: ModelDashboardModuleLayoutConditionInput
  ) {
    deleteDashboardModuleLayout(input: $input, condition: $condition) {
      id
      owner
      json
      createdAt
      updatedAt
    }
  }
`;
export const createDashboardShare = /* GraphQL */ `
  mutation CreateDashboardShare(
    $input: CreateDashboardShareInput!
    $condition: ModelDashboardShareConditionInput
  ) {
    createDashboardShare(input: $input, condition: $condition) {
      id
      dashboardId
      token
      expires
      createdAt
      updatedAt
      owner
      dashboard {
        id
        owner
        customScriptId
        defaultScript
        error {
          code
          message
          status
          transactionId
          scriptError
        }
        output_logs
        lastTransactionId
        maxFiles
        status
        tags
        file_ids
        title
        createdAt
        updatedAt
        customScript {
          id
          owner
          lines
          status
          title
          type
          createdAt
          updatedAt
        }
        notes
        sequence
        modules {
          nextToken
        }
        shares {
          nextToken
        }
      }
    }
  }
`;
export const updateDashboardShare = /* GraphQL */ `
  mutation UpdateDashboardShare(
    $input: UpdateDashboardShareInput!
    $condition: ModelDashboardShareConditionInput
  ) {
    updateDashboardShare(input: $input, condition: $condition) {
      id
      dashboardId
      token
      expires
      createdAt
      updatedAt
      owner
      dashboard {
        id
        owner
        customScriptId
        defaultScript
        error {
          code
          message
          status
          transactionId
          scriptError
        }
        output_logs
        lastTransactionId
        maxFiles
        status
        tags
        file_ids
        title
        createdAt
        updatedAt
        customScript {
          id
          owner
          lines
          status
          title
          type
          createdAt
          updatedAt
        }
        notes
        sequence
        modules {
          nextToken
        }
        shares {
          nextToken
        }
      }
    }
  }
`;
export const deleteDashboardShare = /* GraphQL */ `
  mutation DeleteDashboardShare(
    $input: DeleteDashboardShareInput!
    $condition: ModelDashboardShareConditionInput
  ) {
    deleteDashboardShare(input: $input, condition: $condition) {
      id
      dashboardId
      token
      expires
      createdAt
      updatedAt
      owner
      dashboard {
        id
        owner
        customScriptId
        defaultScript
        error {
          code
          message
          status
          transactionId
          scriptError
        }
        output_logs
        lastTransactionId
        maxFiles
        status
        tags
        file_ids
        title
        createdAt
        updatedAt
        customScript {
          id
          owner
          lines
          status
          title
          type
          createdAt
          updatedAt
        }
        notes
        sequence
        modules {
          nextToken
        }
        shares {
          nextToken
        }
      }
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      serial_number_id
      name
      description
      part_number
      hardware_revision
      firmware_revision
      last_calibration_ts
      thing_name
      thing_arn
      cert_arn
      manufacture_date
      last_uploaded_ts
      createdAt
      updatedAt
      associations {
        items {
          id
          org_id
          s3_bucket_name
          serial_number_id
          status
          user_id
          customerId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      serial_number_id
      name
      description
      part_number
      hardware_revision
      firmware_revision
      last_calibration_ts
      thing_name
      thing_arn
      cert_arn
      manufacture_date
      last_uploaded_ts
      createdAt
      updatedAt
      associations {
        items {
          id
          org_id
          s3_bucket_name
          serial_number_id
          status
          user_id
          customerId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      serial_number_id
      name
      description
      part_number
      hardware_revision
      firmware_revision
      last_calibration_ts
      thing_name
      thing_arn
      cert_arn
      manufacture_date
      last_uploaded_ts
      createdAt
      updatedAt
      associations {
        items {
          id
          org_id
          s3_bucket_name
          serial_number_id
          status
          user_id
          customerId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createDeviceAssociation = /* GraphQL */ `
  mutation CreateDeviceAssociation(
    $input: CreateDeviceAssociationInput!
    $condition: ModelDeviceAssociationConditionInput
  ) {
    createDeviceAssociation(input: $input, condition: $condition) {
      id
      org_id
      s3_bucket_name
      serial_number_id
      status
      user_id
      customerId
      createdAt
      updatedAt
      customer {
        id
        owner
        storageConsumed
        storageSpaceRemaining
        tierId
        createdAt
        updatedAt
        companyName
        logoImage
        apiKeys {
          nextToken
        }
        billing {
          id
          stripe_session_id
          owner
          stripe_customer_id
          stripe_subscription_id
          subscription_status
          stripe_plan_id
          suspended_at
          next_billing_at
          createdAt
          updatedAt
        }
        deviceAssociations {
          nextToken
        }
        tier {
          id
          costMonthly
          costYearly
          deviceMaxCount
          storageLimitGb
          alertMaxCount
          hasApiAccess
          billingCycles
          reportMaxCount
          reportTemplate
          createdAt
          updatedAt
        }
      }
      device {
        serial_number_id
        name
        description
        part_number
        hardware_revision
        firmware_revision
        last_calibration_ts
        thing_name
        thing_arn
        cert_arn
        manufacture_date
        last_uploaded_ts
        createdAt
        updatedAt
        associations {
          nextToken
        }
      }
      files {
        items {
          id
          user_id
          serial_number_id
          sensor_name
          sensor_type
          file_name
          file_size
          s3_bucket_name
          s3_object_key
          device_meta
          recording_length
          recording_ts
          created_ts
          modified_ts
          archived_ts
          file_uploaded
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateDeviceAssociation = /* GraphQL */ `
  mutation UpdateDeviceAssociation(
    $input: UpdateDeviceAssociationInput!
    $condition: ModelDeviceAssociationConditionInput
  ) {
    updateDeviceAssociation(input: $input, condition: $condition) {
      id
      org_id
      s3_bucket_name
      serial_number_id
      status
      user_id
      customerId
      createdAt
      updatedAt
      customer {
        id
        owner
        storageConsumed
        storageSpaceRemaining
        tierId
        createdAt
        updatedAt
        companyName
        logoImage
        apiKeys {
          nextToken
        }
        billing {
          id
          stripe_session_id
          owner
          stripe_customer_id
          stripe_subscription_id
          subscription_status
          stripe_plan_id
          suspended_at
          next_billing_at
          createdAt
          updatedAt
        }
        deviceAssociations {
          nextToken
        }
        tier {
          id
          costMonthly
          costYearly
          deviceMaxCount
          storageLimitGb
          alertMaxCount
          hasApiAccess
          billingCycles
          reportMaxCount
          reportTemplate
          createdAt
          updatedAt
        }
      }
      device {
        serial_number_id
        name
        description
        part_number
        hardware_revision
        firmware_revision
        last_calibration_ts
        thing_name
        thing_arn
        cert_arn
        manufacture_date
        last_uploaded_ts
        createdAt
        updatedAt
        associations {
          nextToken
        }
      }
      files {
        items {
          id
          user_id
          serial_number_id
          sensor_name
          sensor_type
          file_name
          file_size
          s3_bucket_name
          s3_object_key
          device_meta
          recording_length
          recording_ts
          created_ts
          modified_ts
          archived_ts
          file_uploaded
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteDeviceAssociation = /* GraphQL */ `
  mutation DeleteDeviceAssociation(
    $input: DeleteDeviceAssociationInput!
    $condition: ModelDeviceAssociationConditionInput
  ) {
    deleteDeviceAssociation(input: $input, condition: $condition) {
      id
      org_id
      s3_bucket_name
      serial_number_id
      status
      user_id
      customerId
      createdAt
      updatedAt
      customer {
        id
        owner
        storageConsumed
        storageSpaceRemaining
        tierId
        createdAt
        updatedAt
        companyName
        logoImage
        apiKeys {
          nextToken
        }
        billing {
          id
          stripe_session_id
          owner
          stripe_customer_id
          stripe_subscription_id
          subscription_status
          stripe_plan_id
          suspended_at
          next_billing_at
          createdAt
          updatedAt
        }
        deviceAssociations {
          nextToken
        }
        tier {
          id
          costMonthly
          costYearly
          deviceMaxCount
          storageLimitGb
          alertMaxCount
          hasApiAccess
          billingCycles
          reportMaxCount
          reportTemplate
          createdAt
          updatedAt
        }
      }
      device {
        serial_number_id
        name
        description
        part_number
        hardware_revision
        firmware_revision
        last_calibration_ts
        thing_name
        thing_arn
        cert_arn
        manufacture_date
        last_uploaded_ts
        createdAt
        updatedAt
        associations {
          nextToken
        }
      }
      files {
        items {
          id
          user_id
          serial_number_id
          sensor_name
          sensor_type
          file_name
          file_size
          s3_bucket_name
          s3_object_key
          device_meta
          recording_length
          recording_ts
          created_ts
          modified_ts
          archived_ts
          file_uploaded
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      user_id
      serial_number_id
      sensor_name
      sensor_type
      file_name
      file_size
      s3_bucket_name
      s3_object_key
      device_meta
      recording_length
      recording_ts
      created_ts
      modified_ts
      archived_ts
      file_uploaded
      createdAt
      updatedAt
      attributes {
        items {
          id
          user_id
          attributeFileId
          name
          type
          value
          thing
          mutable
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        id
        org_id
        s3_bucket_name
        serial_number_id
        status
        user_id
        customerId
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
        device {
          serial_number_id
          name
          description
          part_number
          hardware_revision
          firmware_revision
          last_calibration_ts
          thing_name
          thing_arn
          cert_arn
          manufacture_date
          last_uploaded_ts
          createdAt
          updatedAt
        }
        files {
          nextToken
        }
      }
      tags {
        items {
          id
          user_id
          tagFileId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      user_id
      serial_number_id
      sensor_name
      sensor_type
      file_name
      file_size
      s3_bucket_name
      s3_object_key
      device_meta
      recording_length
      recording_ts
      created_ts
      modified_ts
      archived_ts
      file_uploaded
      createdAt
      updatedAt
      attributes {
        items {
          id
          user_id
          attributeFileId
          name
          type
          value
          thing
          mutable
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        id
        org_id
        s3_bucket_name
        serial_number_id
        status
        user_id
        customerId
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
        device {
          serial_number_id
          name
          description
          part_number
          hardware_revision
          firmware_revision
          last_calibration_ts
          thing_name
          thing_arn
          cert_arn
          manufacture_date
          last_uploaded_ts
          createdAt
          updatedAt
        }
        files {
          nextToken
        }
      }
      tags {
        items {
          id
          user_id
          tagFileId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      user_id
      serial_number_id
      sensor_name
      sensor_type
      file_name
      file_size
      s3_bucket_name
      s3_object_key
      device_meta
      recording_length
      recording_ts
      created_ts
      modified_ts
      archived_ts
      file_uploaded
      createdAt
      updatedAt
      attributes {
        items {
          id
          user_id
          attributeFileId
          name
          type
          value
          thing
          mutable
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        id
        org_id
        s3_bucket_name
        serial_number_id
        status
        user_id
        customerId
        createdAt
        updatedAt
        customer {
          id
          owner
          storageConsumed
          storageSpaceRemaining
          tierId
          createdAt
          updatedAt
          companyName
          logoImage
        }
        device {
          serial_number_id
          name
          description
          part_number
          hardware_revision
          firmware_revision
          last_calibration_ts
          thing_name
          thing_arn
          cert_arn
          manufacture_date
          last_uploaded_ts
          createdAt
          updatedAt
        }
        files {
          nextToken
        }
      }
      tags {
        items {
          id
          user_id
          tagFileId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createStripePlan = /* GraphQL */ `
  mutation CreateStripePlan(
    $input: CreateStripePlanInput!
    $condition: ModelStripePlanConditionInput
  ) {
    createStripePlan(input: $input, condition: $condition) {
      id
      tierId
      billingCycle
      cost
      createdAt
      updatedAt
      tier {
        id
        costMonthly
        costYearly
        deviceMaxCount
        storageLimitGb
        alertMaxCount
        hasApiAccess
        billingCycles
        reportMaxCount
        reportTemplate
        createdAt
        updatedAt
        stripePlans {
          nextToken
        }
      }
    }
  }
`;
export const updateStripePlan = /* GraphQL */ `
  mutation UpdateStripePlan(
    $input: UpdateStripePlanInput!
    $condition: ModelStripePlanConditionInput
  ) {
    updateStripePlan(input: $input, condition: $condition) {
      id
      tierId
      billingCycle
      cost
      createdAt
      updatedAt
      tier {
        id
        costMonthly
        costYearly
        deviceMaxCount
        storageLimitGb
        alertMaxCount
        hasApiAccess
        billingCycles
        reportMaxCount
        reportTemplate
        createdAt
        updatedAt
        stripePlans {
          nextToken
        }
      }
    }
  }
`;
export const deleteStripePlan = /* GraphQL */ `
  mutation DeleteStripePlan(
    $input: DeleteStripePlanInput!
    $condition: ModelStripePlanConditionInput
  ) {
    deleteStripePlan(input: $input, condition: $condition) {
      id
      tierId
      billingCycle
      cost
      createdAt
      updatedAt
      tier {
        id
        costMonthly
        costYearly
        deviceMaxCount
        storageLimitGb
        alertMaxCount
        hasApiAccess
        billingCycles
        reportMaxCount
        reportTemplate
        createdAt
        updatedAt
        stripePlans {
          nextToken
        }
      }
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      user_id
      tagFileId
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      user_id
      tagFileId
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      user_id
      tagFileId
      value
      createdAt
      updatedAt
    }
  }
`;
export const createTier = /* GraphQL */ `
  mutation CreateTier(
    $input: CreateTierInput!
    $condition: ModelTierConditionInput
  ) {
    createTier(input: $input, condition: $condition) {
      id
      costMonthly
      costYearly
      deviceMaxCount
      storageLimitGb
      alertMaxCount
      hasApiAccess
      billingCycles
      reportMaxCount
      reportTemplate
      createdAt
      updatedAt
      stripePlans {
        items {
          id
          tierId
          billingCycle
          cost
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateTier = /* GraphQL */ `
  mutation UpdateTier(
    $input: UpdateTierInput!
    $condition: ModelTierConditionInput
  ) {
    updateTier(input: $input, condition: $condition) {
      id
      costMonthly
      costYearly
      deviceMaxCount
      storageLimitGb
      alertMaxCount
      hasApiAccess
      billingCycles
      reportMaxCount
      reportTemplate
      createdAt
      updatedAt
      stripePlans {
        items {
          id
          tierId
          billingCycle
          cost
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteTier = /* GraphQL */ `
  mutation DeleteTier(
    $input: DeleteTierInput!
    $condition: ModelTierConditionInput
  ) {
    deleteTier(input: $input, condition: $condition) {
      id
      costMonthly
      costYearly
      deviceMaxCount
      storageLimitGb
      alertMaxCount
      hasApiAccess
      billingCycles
      reportMaxCount
      reportTemplate
      createdAt
      updatedAt
      stripePlans {
        items {
          id
          tierId
          billingCycle
          cost
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
