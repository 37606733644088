import { API } from 'aws-amplify';
import { useMemo } from 'react';
import { ApiCallback, ApiTypes, apiUrls, pairingApiName } from './ApiHelper';

const UsePairingApi = (apiType: ApiTypes) => {
  const requestPost = useMemo(
    () => async (body?: Object) => {
      const url: ApiCallback = apiUrls[apiType];
      try {
        let apiName = pairingApiName;
        let path = url();
        let init = {
          body: body || {}
        };
        const response = await API.post(apiName, path, init);
        return response;
      } catch (e) {
        throw e;
      }
    },
    [apiType]
  );

  return {
    requestPost
  };
};

export default UsePairingApi;
