import { StripePlanId } from '../../API';

export const stripeSessionApiName = 'stripe';
export const stripeSessionApiPathRoot = '/billing/stripe';

export enum ApiSessionErrorStatus {
  STRIPE_SESSION_UNAUTHORIZED = 'unauthorized',
  STRIPE_SESSION_MISSING_RESOURCE = 'MISSING_RESOURCE',
  STRIPE_SESSION_INVALID_URL = 'INVALID_URL',
  STRIPE_SESSION_RESOURCE_MISSING = 'RESOURCE_MISSING'
}

export interface PostParams {
  cancel: {};
  create: {
    success_url: string;
    cancel_url: string;
    promo_code: string | null;
    plan_id: string;
    customer?: string;
  };
  updateBilling: {
    success_url: string;
    cancel_url: string;
  };
  complete: {
    id: string;
  };
  update: {
    plan_id: string;
  };
}

export enum ApiTypes {
  CANCEL = 'cancel',
  CREATE = 'create',
  UPDATE_BILLING = 'updateBilling',
  COMPLETE = 'complete',
  UPDATE_SUB = 'updateSubscription'
}

export type ApiCallback = () => string;

export type ApiUrlsSignature = { [key in ApiTypes]: ApiCallback };

export const apiUrls: ApiUrlsSignature = {
  cancel: () => `/subscription-cancel`,
  create: () => `/create-session`,
  updateBilling: () => `/update-billing`,
  complete: () => `/session-complete`,
  updateSubscription: () => `/subscription-update`
};

export const generateTierId = (tierId: string, paymentPeriod: string) => {
  if (tierId === 'starter') {
    if (paymentPeriod === 'month') return StripePlanId.starter_monthly;
    if (paymentPeriod === 'year') return StripePlanId.starter_yearly_2021;
  }
  if (tierId === 'professional') {
    if (paymentPeriod === 'month') return StripePlanId.professional_monthly;
    if (paymentPeriod === 'year') return StripePlanId.professional_yearly;
  }
  if (tierId === 'enterprise') {
    if (paymentPeriod === 'month') return StripePlanId.enterprise_monthly;
    if (paymentPeriod === 'year') return StripePlanId.enterprise_yearly;
  }
  return '';
};

export const getBillingFrequency = (stripePlanId: string) => {
  if (!stripePlanId || stripePlanId === 'free') {
    return 'none';
  }
  if (
    stripePlanId === StripePlanId.starter_monthly ||
    stripePlanId === StripePlanId.professional_monthly ||
    stripePlanId === StripePlanId.enterprise_monthly
  ) {
    return 'monthly';
  }
  return 'yearly';
};
