import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme
} from '@material-ui/core';
import { startCase } from 'lodash';
import React, { useCallback, useContext } from 'react';
import { LayoutContext } from '../../../../hooks/LayoutContext';
import { ApiTypes } from '../../../../hooks/UseApiKeyApi/ApiHelper';
import UseApiKeyApi from '../../../../hooks/UseApiKeyApi';

interface Props {
  newTierId: string;
  customerApiKeys: any[] | null;
  leaveDowngrade: () => void;
  continueDowngrade: () => void;
  setLoading: React.Dispatch<any>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogText: {
      margin: 0
    },
    checkboxLabelRoot: {
      margin: 0,
      display: 'flex',
      flex: '1 0 0'
    },
    checkboxLabel: {
      textTransform: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      flex: '1 0 auto',
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    customerItem: {
      justifyContent: 'space-between',
      paddingRight: theme.spacing(3),
      flexWrap: 'nowrap',
      borderBottom: `1px solid ${theme.palette.grey[500]}`
    },
    listContainer: {
      maxHeight: 210,
      overflowY: 'scroll',
      padding: theme.spacing(1, 1.5)
    }
  })
);

const ApiKeyError = (props: Props) => {
  const {
    newTierId,
    customerApiKeys,
    leaveDowngrade,
    continueDowngrade,
    setLoading
  } = props;

  const classes = useStyles();

  const layoutContext = useContext(LayoutContext);
  if (!layoutContext) {
    throw new Error(
      'App component must be used within a Layout Context Provider'
    );
  }
  const { pushGlobalSiteError } = layoutContext;

  const { requestDelete: removeApiKeys } = UseApiKeyApi(ApiTypes['REMOVE']);

  const applyAndContinue = useCallback(async () => {
    setLoading(true);
    await Promise.resolve(removeApiKeys())
      .then(() => {
        continueDowngrade();
      })
      .catch(err => {
        setLoading(false);
        pushGlobalSiteError(
          'Error encountered while removing API keys. Please try again.'
        );
      });
  }, [removeApiKeys, continueDowngrade, pushGlobalSiteError, setLoading]);

  if (!customerApiKeys) return null;

  return (
    <>
      <DialogTitle id="form-dialog-title">Manage API Keys</DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: classes.dialogText }}>
          You will need to remove your API keys for the {startCase(newTierId)}{' '}
          Plan. Please click "Remove and Continue" to clear your API keys from
          your account
        </DialogContentText>
      </DialogContent>
      <DialogActions disableSpacing>
        <Button
          variant="text"
          size="large"
          color="default"
          onClick={leaveDowngrade}
        >
          Cancel
        </Button>
        <Button
          variant="text"
          size="large"
          color="default"
          onClick={applyAndContinue}
        >
          Remove and Continue
        </Button>
      </DialogActions>
    </>
  );
};

export default ApiKeyError;
