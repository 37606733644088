import { createStyles, makeStyles } from '@material-ui/core';
import theme from '../theme/materialUi';
export const stylesLayout = {
  root: {
    'overflow-y': 'auto'
  },
  container: {
    display: 'flex',
    'flex-direction': 'column',
    flex: '1 1 0px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  grid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    flex: '1 1 0px',
    'flex-wrap': 'nowrap',
    'flex-direction': 'column'
  },
  gridTop: {
    flex: '1 0 auto',
    'flex-direction': 'row',
    alignContent: 'center'
  },
  gridBottom: {
    flex: '0'
  },
  superIcon: {
    fontSize: '192px'
  }
};
export const useStylesLayout = makeStyles(() => createStyles(stylesLayout));
