import { format } from 'date-fns';
import { get } from 'lodash';
import React, {
  createContext,
  ReactChild,
  useContext,
  useEffect,
  useState
} from 'react';
import { LayoutContext } from '../../hooks/LayoutContext';
import UseDashboardApi from '../../hooks/UseDashboardApi';
import { ApiTypes } from '../../hooks/UseDashboardApi/ApiHelper';
import { AuthContext } from '../AuthContext';

export interface ReportContextExports {
  dataUpdateCount: number;
  setDataUpdateCount: React.Dispatch<number>;
  createModalOpen: boolean;
  setCreateModalOpen: React.Dispatch<boolean>;
  savingInProgress: boolean;
  setSavingInProgress: React.Dispatch<boolean>;
  inFlightDashboards: Set<string>;
  createDashboardClick: (dashboardData: {
    dashboardId?: string | null;
    reportName?: string | null;
    selectedTags?: string[] | null;
    fileLimit?: number | null;
    customScriptId?: string | null;
    confirmButtonRef?: any;
    defaultScript?: string | null;
    file_ids?: string[] | null;
  }) => () => void;
  reportListLastViewed: string | null;
  refreshReportListLastViewed: () => void;
  reorderedReport: string | null;
  setReorderedReport: (ordered: string) => void;
  reportNotifications: {
    id: string;
    name: string;
    isNew: boolean;
    date: Date;
  }[];
  removeReportNotification: (dashboardId: string) => void;
}

export const ReportContext = createContext<ReportContextExports | undefined>(
  undefined
);
const { Provider } = ReportContext;

export interface ReportProviderProps {
  children?: ReactChild;
}

export const ReportProvider = (props: ReportProviderProps) => {
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);

  if (!authContext) throw new Error('Auth context is missing');
  if (!layoutContext) {
    throw new Error('Layout context is missing');
  }
  const { customerReports, refetchCustomerReports } = authContext;
  const { pushGlobalSiteError } = layoutContext;

  const [dataUpdateCount, setDataUpdateCount] = useState(0);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [reportNotifications, setReportNotifications] = useState<
    { id: string; name: string; isNew: boolean; date: Date }[]
  >([]);
  const [reportListLastViewed] = useState<string | null>(
    localStorage.getItem('reportListLastViewed') || null
  );

  const [reorderedReport] = useState<string | null>(
    localStorage.getItem('reorderedReport') || null
  );

  const setReorderedReport = (order: string) => {
    localStorage.setItem('reorderedReport', order);
  };

  const refreshReportListLastViewed = () => {
    const newDate = format(new Date());
    localStorage.setItem('reportListLastViewed', newDate);
  };

  const [inFlightDashboards, setInFlightDashboards] = useState<Set<string>>(
    new Set()
  );

  const [inFlightError, setInFlightError] = useState<boolean>(false);

  // Effect for resetting inFlight
  // count in case of an error.
  useEffect(() => {
    if (inFlightError) {
      setInFlightDashboards(new Set());
    }
  }, [inFlightError]);

  useEffect(() => {
    let refreshReportTimeout: ReturnType<typeof setTimeout>;
    let newInFlightDashboards = new Set(inFlightDashboards);
    if (customerReports) {
      let inFlightChange = false;
      customerReports.forEach(customerReport => {
        if (inFlightDashboards.has(customerReport.id)) {
          if (!['creating', 'updating'].includes(customerReport.status)) {
            newInFlightDashboards.delete(customerReport.id);
            inFlightChange = true;
            setReportNotifications(oldReportNotifications => [
              ...oldReportNotifications,
              {
                id: customerReport.id,
                name: customerReport.title || '',
                isNew: customerReport.status === 'created',
                date: new Date()
              }
            ]);
          }
        }
      });
      if (inFlightChange) {
        setInFlightDashboards(newInFlightDashboards);
      }
    }
    if (newInFlightDashboards.size > 0) {
      refreshReportTimeout = setTimeout(() => {
        refetchCustomerReports();
      }, 5000);
    }
    return () => {
      if (refreshReportTimeout) {
        clearTimeout(refreshReportTimeout);
      }
    };
  }, [customerReports, refetchCustomerReports]);

  const { requestPost: createDashboard } = UseDashboardApi(
    ApiTypes['CREATE'],
    false
  );

  const removeReportNotification = (dashboardId: string) => {
    setReportNotifications(oldNotifications =>
      oldNotifications.filter(
        oldNotification => oldNotification.id !== dashboardId
      )
    );
  };

  const createDashboardClick: ReportContextExports['createDashboardClick'] = ({
    dashboardId,
    reportName,
    selectedTags,
    fileLimit,
    customScriptId,
    confirmButtonRef,
    defaultScript,
    file_ids
  }) => async () => {
    if (confirmButtonRef) {
      confirmButtonRef.current.setAttribute('disabled', true);
    }
    setReorderedReport('false');
    setCreateModalOpen(false);
    setSavingInProgress(true);

    let requestBody: any = {};
    if (dashboardId) {
      requestBody = {
        ...requestBody,
        dashboard_id: dashboardId
      };
    }
    if (reportName) {
      requestBody = {
        ...requestBody,
        title: reportName
      };
    }
    if (selectedTags) {
      requestBody = {
        ...requestBody,
        tags: selectedTags
      };
    }
    if (fileLimit) {
      requestBody = {
        ...requestBody,
        max_files: fileLimit
      };
    }
    if (customScriptId) {
      requestBody = {
        ...requestBody,
        custom_script_id: customScriptId
      };
    }
    if (defaultScript) {
      requestBody = {
        ...requestBody,
        default_script: defaultScript
      };
    }

    if (file_ids) {
      requestBody = {
        ...requestBody,
        file_ids: file_ids
      };
    }

    setDataUpdateCount(prevCount => prevCount + 1);
    createDashboard(requestBody)
      .then(response => {
        const [returnedData] = response;
        setInFlightError(false);
        setSavingInProgress(false);
        if (!returnedData || !returnedData.id) {
          throw new Error('No dashboard ID returned');
        }
        setDataUpdateCount(prevCount => prevCount + 1);
        setInFlightDashboards(currentDashboards => {
          const copy = new Set(currentDashboards);
          copy.add(returnedData.id);
          return copy;
        });
      })
      .then(() => {
        refetchCustomerReports();
      })
      .catch(err => {
        const errorMessage = get(err, 'response.data.message');
        pushGlobalSiteError(
          'There was an error processing your report. Try again later.'
        );
        console.error(`Error while generating report: ${errorMessage}`);
        setSavingInProgress(false);
        setInFlightError(true);
        setDataUpdateCount(prevCount => prevCount - 1);
      });
  };

  const defaultContext = {
    dataUpdateCount,
    setDataUpdateCount,
    createModalOpen,
    setCreateModalOpen,
    savingInProgress,
    setSavingInProgress,
    inFlightDashboards,
    createDashboardClick,
    reportListLastViewed,
    refreshReportListLastViewed,
    reorderedReport,
    setReorderedReport,
    reportNotifications,
    removeReportNotification
  };

  return (
    <Provider value={defaultContext}>
      {props.children && props.children}
    </Provider>
  );
};
