export const apiKeyApiName = 'apikey';
export const apiKeyApiPathRoot = '/apikey';

export interface PostParams {
  create: {};
}

export interface DeleteParams {
  remove: {};
}

export enum ApiTypes {
  CREATE = 'create',
  REMOVE = 'remove'
}

export type ApiCallback = () => string;

export type ApiUrlsSignature = { [key in ApiTypes]: ApiCallback };

export const apiUrls: ApiUrlsSignature = {
  create: () => `/create`,
  remove: () => `/remove`
};
