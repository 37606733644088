import {
  Backdrop,
  CircularProgress,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { find, get, startCase } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ChildDataProps, compose, DataValue, graphql } from 'react-apollo';
import * as API from '../../API';
import LabelledSwitch from '../../components/LabelledSwitch';
import PlanCard from '../../components/PlanCard';
import { listTiers } from '../../graphql/queries';
import { getPlanSelectionErrorMessage } from '../../helpers/DataHelper';
import { AuthContext } from '../../hooks/AuthContext';
import { LayoutContext } from '../../hooks/LayoutContext';
import { StripeSessionContext } from '../../hooks/UseStripeSessionApi/StripeSessionContext';
import PlansDowngrade from '../../screens/Plans/Downgrade';
import TextOnly from '../../templates/TextOnly';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    planContainer: {
      marginBottom: theme.spacing(2.5),
      flexShrink: 0
    },
    yearlySwitch: {
      marginBottom: theme.spacing(2.5)
    },
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: '#fff'
    },
    couponForm: {
      display: 'flex',
      flex: '1 0 auto',
      justifyContent: 'center',
      margin: theme.spacing(-2.5, 0, 2.5)
    }
  })
);

type TierValue = DataValue<API.ListTiersQuery, {}>;
type TierChildProps = ChildDataProps<{}, API.ListTiersQuery, {}>;

interface ChildProps {
  dataListTiers: TierValue;
}

const PlansList = ({
  dataListTiers: { loading: dataLoading, listTiers, error }
}: ChildProps) => {
  const classes = useStyles();

  const authContext = useContext(AuthContext);
  if (!authContext) throw new Error('Auth context is missing');
  const { customerData } = authContext;

  const layoutContext = useContext(LayoutContext);
  if (!layoutContext) throw new Error('Layout context is missing');
  const { setLoading } = layoutContext;

  const stripeSessionContext = useContext(StripeSessionContext);
  if (!stripeSessionContext) throw new Error('Stripe context is missing');
  const { initializing, chooseTier, pushStripeError } = stripeSessionContext;

  const currentTier = get(customerData, 'tierId');
  const currentFrequency = get(customerData, 'billingFrequency');

  const [downgradeInfo, setDowngradeInfo] = useState<null | {
    newTierId: string;
    maxAlerts: number;
    maxSensors: number;
    maxStorage: number;
    hasApiAccess: boolean;
    successCallback: () => void;
  }>(null);
  const [loaderOpen, setLoaderOpen] = useState(false);
  const [downgradeOpen, setDowngradeOpen] = useState(false);
  const [couponCode, setCouponCode] = useState('');

  const tiers: { [key: string]: any }[] = get(
    listTiers || {},
    'items',
    []
  ).sort(
    (a: API.CreateTierInput, b: API.CreateTierInput) =>
      typeof a.costMonthly === 'number' &&
      typeof b.costMonthly === 'number' &&
      a.costMonthly - b.costMonthly
  );

  useEffect(() => {
    setLoading(initializing || dataLoading);
  }, [initializing, dataLoading, setLoading]);

  const generateHandleError = useCallback(
    (errorMessage: string) => (error?: Error) => {
      pushStripeError(errorMessage, error);
      setLoaderOpen(false);
    },
    [pushStripeError]
  );

  const generateOnPlanClick = useCallback(
    (tierId: string, paymentPeriod: string, newTierCost: number) => () => {
      const selectedTierCost = get(
        customerData,
        `tier.cost${startCase(paymentPeriod)}ly`
      );
      const switchPlan = () => {
        setLoaderOpen(true);
        chooseTier(
          newTierCost,
          tierId,
          paymentPeriod,
          couponCode,
          generateHandleError(
            getPlanSelectionErrorMessage({ currentTier, newTierCost })
          )
        );
      };
      if (currentTier && selectedTierCost > newTierCost) {
        const newTierData = find(tiers, tier => tier.id === tierId);
        if (!newTierData) {
          generateHandleError(
            'Unexpected error encountered - Plan data not found.'
          );
          return;
        }
        const {
          alertMaxCount,
          storageLimitGb,
          deviceMaxCount,
          hasApiAccess
        } = newTierData;
        setDowngradeInfo({
          newTierId: tierId,
          maxAlerts: alertMaxCount,
          maxSensors: deviceMaxCount,
          maxStorage: storageLimitGb,
          hasApiAccess,
          successCallback: switchPlan
        });
        setDowngradeOpen(true);
      } else {
        switchPlan();
      }
    },
    [
      couponCode,
      chooseTier,
      currentTier,
      customerData,
      generateHandleError,
      tiers
    ]
  );

  const [yearlyToggled, setYearlyToggled] = useState(false);
  useEffect(() => {
    if (!currentFrequency) return;
    setYearlyToggled(currentFrequency === 'yearly');
  }, [currentFrequency]);
  const YearToggleComponent = (
    <div className={classes.yearlySwitch}>
      <LabelledSwitch
        offLabel="Monthly"
        onLabel="Annually (Save 17%)"
        setToggle={setYearlyToggled}
        switchColor="primary"
        toggle={yearlyToggled}
      />
    </div>
  );
  const CouponComponent = (
    <form className={classes.couponForm} noValidate>
      <TextField
        variant="outlined"
        margin="normal"
        id="stripe-coupon"
        label="Coupon Code"
        name="stripe-coupon"
        inputProps={{
          autoCapitalize: 'none'
        }}
        autoComplete="off"
        value={couponCode}
        onChange={event => {
          setCouponCode(event.currentTarget.value);
        }}
      />
    </form>
  );
  if (initializing) return null;

  if (error) {
    return (
      <TextOnly>
        <Typography variant="h5" component="h1" color={'error'}>
          Error Retrieving the Plans List
        </Typography>
      </TextOnly>
    );
  }

  let recommendedTier = 'starter';
  if (customerData && customerData.tierId !== 'free') {
    recommendedTier = customerData.tierId;
  }

  return (
    <>
      {YearToggleComponent}
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-end"
        className={classes.planContainer}
      >
        {tiers.map(tier => {
          if (!tier) return null;
          return (
            <Grid item key={`tier-${tier.id}`}>
              <PlanCard
                tierId={tier.id}
                costMonthly={tier.costMonthly}
                costYearly={tier.costYearly}
                yearlyToggled={yearlyToggled}
                capLinkedSensors={tier.deviceMaxCount}
                capStorageGb={tier.storageLimitGb}
                capAlerts={tier.alertMaxCount}
                hasApiAccess={tier.hasApiAccess}
                reportTemplate={tier.reportTemplate}
                generateOnCtaClick={generateOnPlanClick}
                disableCta={initializing}
                recommended={tier.id === recommendedTier}
              />
            </Grid>
          );
        })}
      </Grid>
      <Backdrop className={classes.backdrop} open={loaderOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {downgradeInfo && (
        <PlansDowngrade
          {...downgradeInfo}
          downgradeOpen={downgradeOpen}
          setDowngradeOpen={setDowngradeOpen}
        />
      )}
      {CouponComponent}
    </>
  );
};

export default compose(
  graphql<{}, API.ListFilesQuery, {}, TierChildProps>(gql(listTiers), {
    name: 'dataListTiers',
    options: (props: any) => {
      return {
        variables: {
          filter: {
            id: {
              ne: API.TierName['none']
            }
          },
          limit: 100
        },
        fetchPolicy: 'network-only'
      };
    }
  })
)((props: ChildProps) => <PlansList {...props} />);
