import {
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { LayoutContext } from '../../hooks/LayoutContext';
import Copyright from '../Copyright';
import { drawerWidth } from './../../helpers/NavHelper';

const styles = (theme: Theme) =>
  createStyles({
    footer: {
      height: 56,
      [theme.breakpoints.up('sm')]: {
        padding: `0 ${theme.spacing(3)}px`
      },
      [theme.breakpoints.up('md')]: {
        height: 64,
        paddingLeft: theme.spacing(3.5),
        paddingRight: theme.spacing(3.5)
      }
    },
    footerContent: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',

      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-between'
      }
    },
    footerHasLeftDrawer: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: `calc(${theme.spacing(3.5)}px + ${drawerWidth}px)`
      }
    },
    [theme.breakpoints.up('sm')]: {
      footerContentLt: {
        justifyContent: 'flex-start'
      },
      footerContentRt: {
        justifyContent: 'flex-end'
      }
    }
  });

interface Props extends WithStyles<typeof styles> {
  version: string | undefined;
  year: number;
}

const Footer = ({ classes, version, year }: Props) => {
  const layoutContext = useContext(LayoutContext);
  if (!layoutContext) {
    throw new Error('Layout context is missing');
  }
  const { leftDrawerOpen } = layoutContext;
  return (
    <>
      <Grid
        container={true}
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classNames(classes.footer, {
          [classes.footerHasLeftDrawer]: leftDrawerOpen
        })}
      >
        <Grid
          item={true}
          xs={12}
          sm={10}
          className={`${classes.footerContent} ${classes.footerContentLt}`}
        >
          <Copyright />
        </Grid>
        <Grid
          item={true}
          xs={12}
          sm={2}
          className={`${classes.footerContent} ${classes.footerContentRt}`}
        >
          <Typography variant="body1" component="span">
            {`Version ${version}`}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

Footer.defaultProps = {
  version: process.env.REACT_APP_VERSION,
  year: new Date().getFullYear()
};

export default withStyles(styles)(Footer);
