import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MaterialLink } from '@material-ui/core';
import { LayoutContext } from '../../hooks/LayoutContext';

interface MaterialClassesInterface {
  [key: string]: string;
}

interface Props {
  classes: MaterialClassesInterface;
}

const BreadCrumbNavigation = ({ classes }: Props) => {
  const layoutContext = useContext(LayoutContext);

  if (!layoutContext) {
    throw new Error(
      'App component must be used within a Layout Context Provider'
    );
  }

  const { prevPageTitle } = layoutContext;

  if (prevPageTitle.length < 2) {
    return null;
  }

  const [urlTitle, url] = prevPageTitle;

  return (
    <>
      <MaterialLink component={RouterLink} to={url}>
        {urlTitle}
      </MaterialLink>{' '}
      /{' '}
    </>
  );
};

export default BreadCrumbNavigation;
