/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateAlertInput = {
  id?: string | null;
  owner: string;
  customerId: string;
  name?: string | null;
  tagName: string;
  enabled?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelAlertConditionInput = {
  customerId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  tagName?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAlertConditionInput | null> | null;
  or?: Array<ModelAlertConditionInput | null> | null;
  not?: ModelAlertConditionInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = 'binary',
  binarySet = 'binarySet',
  bool = 'bool',
  list = 'list',
  map = 'map',
  number = 'number',
  numberSet = 'numberSet',
  string = 'string',
  stringSet = 'stringSet',
  _null = '_null'
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type Alert = {
  __typename: 'Alert';
  id?: string;
  owner?: string;
  customerId?: string;
  name?: string | null;
  tagName?: string;
  enabled?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  customer?: Customer;
};

export type Customer = {
  __typename: 'Customer';
  id?: string;
  owner?: string;
  storageConsumed?: number | null;
  storageSpaceRemaining?: boolean | null;
  tierId?: TierName;
  createdAt?: string | null;
  updatedAt?: string | null;
  companyName?: string | null;
  logoImage?: string | null;
  apiKeys?: ModelApiKeyConnection;
  billing?: Billing;
  deviceAssociations?: ModelDeviceAssociationConnection;
  tier?: Tier;
};

export enum TierName {
  none = 'none',
  free = 'free',
  starter = 'starter',
  professional = 'professional',
  enterprise = 'enterprise'
}

export type ModelApiKeyConnection = {
  __typename: 'ModelApiKeyConnection';
  items?: Array<ApiKey | null> | null;
  nextToken?: string | null;
};

export type ApiKey = {
  __typename: 'ApiKey';
  id?: string;
  name?: string | null;
  owner?: string;
  revoked?: boolean;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type Billing = {
  __typename: 'Billing';
  id?: string;
  stripe_session_id?: string;
  owner?: string;
  stripe_customer_id?: string;
  stripe_subscription_id?: string | null;
  subscription_status?: BillingSubscriptionStatus;
  stripe_plan_id?: StripePlanId;
  suspended_at?: number | null;
  next_billing_at?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  customer?: Customer;
};

export enum BillingSubscriptionStatus {
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  active = 'active',
  past_due = 'past_due',
  canceled = 'canceled',
  unpaid = 'unpaid',
  suspended = 'suspended'
}

export enum StripePlanId {
  starter_monthly = 'starter_monthly',
  starter_yearly = 'starter_yearly',
  starter_yearly_2021 = 'starter_yearly_2021',
  professional_monthly = 'professional_monthly',
  professional_yearly = 'professional_yearly',
  enterprise_monthly = 'enterprise_monthly',
  enterprise_yearly = 'enterprise_yearly'
}

export type ModelDeviceAssociationConnection = {
  __typename: 'ModelDeviceAssociationConnection';
  items?: Array<DeviceAssociation | null> | null;
  nextToken?: string | null;
};

export type DeviceAssociation = {
  __typename: 'DeviceAssociation';
  id?: string;
  org_id?: string | null;
  s3_bucket_name?: string | null;
  serial_number_id?: string;
  status?: DeviceAssociationStatus | null;
  user_id?: string;
  customerId?: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  customer?: Customer;
  device?: Device;
  files?: ModelFileConnection;
};

export enum DeviceAssociationStatus {
  PAIRED = 'PAIRED',
  PENDING = 'PENDING',
  UNPAIRED = 'UNPAIRED'
}

export type Device = {
  __typename: 'Device';
  serial_number_id?: string;
  name?: string | null;
  description?: string | null;
  part_number?: string | null;
  hardware_revision?: string | null;
  firmware_revision?: string | null;
  last_calibration_ts?: number | null;
  thing_name?: string | null;
  thing_arn?: string | null;
  cert_arn?: string | null;
  manufacture_date?: string | null;
  last_uploaded_ts?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  associations?: ModelDeviceAssociationConnection;
};

export type ModelFileConnection = {
  __typename: 'ModelFileConnection';
  items?: Array<File | null> | null;
  nextToken?: string | null;
};

export type File = {
  __typename: 'File';
  id?: string;
  user_id?: string;
  serial_number_id?: string | null;
  sensor_name?: string | null;
  sensor_type?: string | null;
  file_name?: string | null;
  file_size?: number | null;
  s3_bucket_name?: string | null;
  s3_object_key?: string;
  device_meta?: string | null;
  recording_length?: number | null;
  recording_ts?: number | null;
  created_ts?: number | null;
  modified_ts?: number | null;
  archived_ts?: number | null;
  file_uploaded?: boolean;
  createdAt?: string | null;
  updatedAt?: string | null;
  attributes?: ModelAttributeConnection;
  device?: DeviceAssociation;
  tags?: ModelTagConnection;
};

export type ModelAttributeConnection = {
  __typename: 'ModelAttributeConnection';
  items?: Array<Attribute | null> | null;
  nextToken?: string | null;
};

export type Attribute = {
  __typename: 'Attribute';
  id?: string;
  user_id?: string;
  attributeFileId?: string;
  name?: string;
  type?: string;
  value?: string;
  thing?: string | null;
  mutable?: boolean;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelTagConnection = {
  __typename: 'ModelTagConnection';
  items?: Array<Tag | null> | null;
  nextToken?: string | null;
};

export type Tag = {
  __typename: 'Tag';
  id?: string;
  user_id?: string;
  tagFileId?: string;
  value?: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type Tier = {
  __typename: 'Tier';
  id?: TierName;
  costMonthly?: number | null;
  costYearly?: number | null;
  deviceMaxCount?: number | null;
  storageLimitGb?: number | null;
  alertMaxCount?: number | null;
  hasApiAccess?: boolean | null;
  billingCycles?: Array<BillingCycle | null> | null;
  reportMaxCount?: number | null;
  reportTemplate?: ReportTemplate | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  stripePlans?: ModelStripePlanConnection;
};

export enum BillingCycle {
  monthly = 'monthly',
  yearly = 'yearly'
}

export enum ReportTemplate {
  standard = 'standard',
  custom = 'custom'
}

export type ModelStripePlanConnection = {
  __typename: 'ModelStripePlanConnection';
  items?: Array<StripePlan | null> | null;
  nextToken?: string | null;
};

export type StripePlan = {
  __typename: 'StripePlan';
  id?: StripePlanId;
  tierId?: TierName;
  billingCycle?: BillingCycle | null;
  cost?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  tier?: Tier;
};

export type UpdateAlertInput = {
  id: string;
  owner?: string | null;
  customerId?: string | null;
  name?: string | null;
  tagName?: string | null;
  enabled?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteAlertInput = {
  id?: string | null;
};

export type CreateApiKeyInput = {
  id?: string | null;
  name?: string | null;
  owner: string;
  revoked: boolean;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelApiKeyConditionInput = {
  name?: ModelStringInput | null;
  revoked?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelApiKeyConditionInput | null> | null;
  or?: Array<ModelApiKeyConditionInput | null> | null;
  not?: ModelApiKeyConditionInput | null;
};

export type UpdateApiKeyInput = {
  id: string;
  name?: string | null;
  owner?: string | null;
  revoked?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteApiKeyInput = {
  id?: string | null;
};

export type CreateAttributeInput = {
  id?: string | null;
  user_id: string;
  attributeFileId: string;
  name: string;
  type: string;
  value: string;
  thing?: string | null;
  mutable: boolean;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelAttributeConditionInput = {
  attributeFileId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  type?: ModelStringInput | null;
  value?: ModelStringInput | null;
  thing?: ModelStringInput | null;
  mutable?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAttributeConditionInput | null> | null;
  or?: Array<ModelAttributeConditionInput | null> | null;
  not?: ModelAttributeConditionInput | null;
};

export type UpdateAttributeInput = {
  id: string;
  user_id?: string | null;
  attributeFileId?: string | null;
  name?: string | null;
  type?: string | null;
  value?: string | null;
  thing?: string | null;
  mutable?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteAttributeInput = {
  id?: string | null;
};

export type CreateBillingInput = {
  id?: string | null;
  stripe_session_id: string;
  owner: string;
  stripe_customer_id: string;
  stripe_subscription_id?: string | null;
  subscription_status: BillingSubscriptionStatus;
  stripe_plan_id: StripePlanId;
  suspended_at?: number | null;
  next_billing_at?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  billingCustomerId: string;
};

export type ModelBillingConditionInput = {
  stripe_session_id?: ModelStringInput | null;
  stripe_customer_id?: ModelStringInput | null;
  stripe_subscription_id?: ModelStringInput | null;
  subscription_status?: ModelBillingSubscriptionStatusInput | null;
  stripe_plan_id?: ModelStripePlanIdInput | null;
  suspended_at?: ModelIntInput | null;
  next_billing_at?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelBillingConditionInput | null> | null;
  or?: Array<ModelBillingConditionInput | null> | null;
  not?: ModelBillingConditionInput | null;
};

export type ModelBillingSubscriptionStatusInput = {
  eq?: BillingSubscriptionStatus | null;
  ne?: BillingSubscriptionStatus | null;
};

export type ModelStripePlanIdInput = {
  eq?: StripePlanId | null;
  ne?: StripePlanId | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateBillingInput = {
  id: string;
  stripe_session_id?: string | null;
  owner?: string | null;
  stripe_customer_id?: string | null;
  stripe_subscription_id?: string | null;
  subscription_status?: BillingSubscriptionStatus | null;
  stripe_plan_id?: StripePlanId | null;
  suspended_at?: number | null;
  next_billing_at?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  billingCustomerId?: string | null;
};

export type DeleteBillingInput = {
  id?: string | null;
};

export type CreateCustomScriptInput = {
  id?: string | null;
  owner: string;
  error?: CustomScriptErrorInput | null;
  lines: Array<string>;
  status?: CustomScriptStatus | null;
  title?: string | null;
  type: CustomScriptType;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CustomScriptErrorInput = {
  code?: number | null;
  detail: string;
  message: string;
  status?: string | null;
  traces: Array<StackTraceInput>;
  transactionId?: string | null;
  type: string;
};

export type StackTraceInput = {
  filename: string;
  lineno: string;
  name?: string | null;
  offset?: number | null;
  text?: string | null;
};

export enum CustomScriptStatus {
  error = 'error',
  running = 'running',
  success = 'success'
}

export enum CustomScriptType {
  dashboard = 'dashboard',
  IDE_Summary = 'IDE_Summary',
  Large_Time_Series = 'Large_Time_Series',
  Shock_Focus = 'Shock_Focus',
  Vibration_Focus = 'Vibration_Focus'
}

export type ModelCustomScriptConditionInput = {
  lines?: ModelStringInput | null;
  status?: ModelCustomScriptStatusInput | null;
  title?: ModelStringInput | null;
  type?: ModelCustomScriptTypeInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCustomScriptConditionInput | null> | null;
  or?: Array<ModelCustomScriptConditionInput | null> | null;
  not?: ModelCustomScriptConditionInput | null;
};

export type ModelCustomScriptStatusInput = {
  eq?: CustomScriptStatus | null;
  ne?: CustomScriptStatus | null;
};

export type ModelCustomScriptTypeInput = {
  eq?: CustomScriptType | null;
  ne?: CustomScriptType | null;
};

export type CustomScript = {
  __typename: 'CustomScript';
  id?: string;
  owner?: string;
  error?: CustomScriptError;
  lines?: Array<string>;
  status?: CustomScriptStatus | null;
  title?: string | null;
  type?: CustomScriptType;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CustomScriptError = {
  __typename: 'CustomScriptError';
  code?: number | null;
  detail?: string;
  message?: string;
  status?: string | null;
  traces?: Array<StackTrace>;
  transactionId?: string | null;
  type?: string;
};

export type Error = {
  __typename: 'Error';
  code?: number | null;
  message?: string;
  status?: string | null;
};

export type DashboardError = {
  __typename: 'DashboardError';
  code?: number | null;
  message?: string;
  status?: string | null;
  transactionId?: string | null;
  scriptError?: boolean | null;
};

export type DashboardModuleError = {
  __typename: 'DashboardModuleError';
  code?: number | null;
  message?: string;
  status?: string | null;
  transactionId?: string | null;
};

export type GenericError = {
  __typename: 'GenericError';
  code?: number | null;
  message?: string;
  status?: string | null;
};

export type ReportError = {
  __typename: 'ReportError';
  code?: number | null;
  message?: string;
  status?: string | null;
  transactionId?: string | null;
};

export type StackTrace = {
  __typename: 'StackTrace';
  filename?: string;
  lineno?: string;
  name?: string | null;
  offset?: number | null;
  text?: string | null;
};

export type UpdateCustomScriptInput = {
  id: string;
  owner?: string | null;
  error?: CustomScriptErrorInput | null;
  lines?: Array<string> | null;
  status?: CustomScriptStatus | null;
  title?: string | null;
  type?: CustomScriptType | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteCustomScriptInput = {
  id?: string | null;
};

export type CreateCustomerInput = {
  id?: string | null;
  owner: string;
  storageConsumed?: number | null;
  storageSpaceRemaining?: boolean | null;
  tierId: TierName;
  createdAt?: string | null;
  updatedAt?: string | null;
  companyName?: string | null;
  logoImage?: string | null;
  customerBillingId?: string | null;
};

export type ModelCustomerConditionInput = {
  storageConsumed?: ModelFloatInput | null;
  storageSpaceRemaining?: ModelBooleanInput | null;
  tierId?: ModelTierNameInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  companyName?: ModelStringInput | null;
  logoImage?: ModelStringInput | null;
  and?: Array<ModelCustomerConditionInput | null> | null;
  or?: Array<ModelCustomerConditionInput | null> | null;
  not?: ModelCustomerConditionInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelTierNameInput = {
  eq?: TierName | null;
  ne?: TierName | null;
};

export type UpdateCustomerInput = {
  id: string;
  owner?: string | null;
  storageConsumed?: number | null;
  storageSpaceRemaining?: boolean | null;
  tierId?: TierName | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  companyName?: string | null;
  logoImage?: string | null;
  customerBillingId?: string | null;
};

export type DeleteCustomerInput = {
  id?: string | null;
};

export type CreateDashboardInput = {
  id?: string | null;
  owner: string;
  customScriptId?: string | null;
  defaultScript?: string | null;
  error?: DashboardErrorInput | null;
  output_logs?: string | null;
  lastTransactionId?: string | null;
  maxFiles?: number | null;
  status: DashboardStatus;
  tags: Array<string>;
  file_ids?: Array<string> | null;
  title?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  notes?: string | null;
  sequence?: number | null;
};

export type DashboardErrorInput = {
  code?: number | null;
  message: string;
  status?: string | null;
  transactionId?: string | null;
  scriptError?: boolean | null;
};

export enum DashboardStatus {
  creating = 'creating',
  created = 'created',
  error = 'error',
  updating = 'updating',
  updated = 'updated'
}

export type ModelDashboardConditionInput = {
  customScriptId?: ModelIDInput | null;
  defaultScript?: ModelStringInput | null;
  output_logs?: ModelStringInput | null;
  lastTransactionId?: ModelIDInput | null;
  maxFiles?: ModelIntInput | null;
  status?: ModelDashboardStatusInput | null;
  tags?: ModelStringInput | null;
  file_ids?: ModelStringInput | null;
  title?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  sequence?: ModelIntInput | null;
  and?: Array<ModelDashboardConditionInput | null> | null;
  or?: Array<ModelDashboardConditionInput | null> | null;
  not?: ModelDashboardConditionInput | null;
};

export type ModelDashboardStatusInput = {
  eq?: DashboardStatus | null;
  ne?: DashboardStatus | null;
};

export type Dashboard = {
  __typename: 'Dashboard';
  id?: string;
  owner?: string;
  customScriptId?: string | null;
  defaultScript?: string | null;
  error?: DashboardError;
  output_logs?: string | null;
  lastTransactionId?: string | null;
  maxFiles?: number | null;
  status?: DashboardStatus;
  tags?: Array<string>;
  file_ids?: Array<string> | null;
  title?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  customScript?: CustomScript;
  notes?: string | null;
  sequence?: number | null;
  modules?: ModelDashboardModuleConnection;
  shares?: ModelDashboardShareConnection;
};

export type ModelDashboardModuleConnection = {
  __typename: 'ModelDashboardModuleConnection';
  items?: Array<DashboardModule | null> | null;
  nextToken?: string | null;
};

export type DashboardModule = {
  __typename: 'DashboardModule';
  id?: string;
  owner?: string;
  dashboardId?: string;
  error?: DashboardModuleError;
  sequence?: number | null;
  title?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  dashboard?: Dashboard;
  data?: ModelDashboardModuleDataConnection;
  frames?: ModelDashboardModuleFrameConnection;
  layout?: DashboardModuleLayout;
};

export type ModelDashboardModuleDataConnection = {
  __typename: 'ModelDashboardModuleDataConnection';
  items?: Array<DashboardModuleData | null> | null;
  nextToken?: string | null;
};

export type DashboardModuleData = {
  __typename: 'DashboardModuleData';
  id?: string;
  owner?: string;
  moduleId?: string;
  json?: string;
  sequence?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  module?: DashboardModule;
};

export type ModelDashboardModuleFrameConnection = {
  __typename: 'ModelDashboardModuleFrameConnection';
  items?: Array<DashboardModuleFrame | null> | null;
  nextToken?: string | null;
};

export type DashboardModuleFrame = {
  __typename: 'DashboardModuleFrame';
  id?: string;
  owner?: string;
  moduleId?: string;
  json?: string;
  sequence?: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  module?: DashboardModule;
};

export type DashboardModuleLayout = {
  __typename: 'DashboardModuleLayout';
  id?: string;
  owner?: string;
  json?: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelDashboardShareConnection = {
  __typename: 'ModelDashboardShareConnection';
  items?: Array<DashboardShare | null> | null;
  nextToken?: string | null;
};

export type DashboardShare = {
  __typename: 'DashboardShare';
  id?: string;
  dashboardId?: string;
  token?: string;
  expires?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  owner?: string;
  dashboard?: Dashboard;
};

export type UpdateDashboardInput = {
  id: string;
  owner?: string | null;
  customScriptId?: string | null;
  defaultScript?: string | null;
  error?: DashboardErrorInput | null;
  output_logs?: string | null;
  lastTransactionId?: string | null;
  maxFiles?: number | null;
  status?: DashboardStatus | null;
  tags?: Array<string> | null;
  file_ids?: Array<string> | null;
  title?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  notes?: string | null;
  sequence?: number | null;
};

export type DeleteDashboardInput = {
  id?: string | null;
};

export type CreateDashboardModuleInput = {
  id?: string | null;
  owner: string;
  dashboardId: string;
  error?: DashboardModuleErrorInput | null;
  sequence?: number | null;
  title?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  dashboardModuleLayoutId?: string | null;
};

export type DashboardModuleErrorInput = {
  code?: number | null;
  message: string;
  status?: string | null;
  transactionId?: string | null;
};

export type ModelDashboardModuleConditionInput = {
  dashboardId?: ModelIDInput | null;
  sequence?: ModelIntInput | null;
  title?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDashboardModuleConditionInput | null> | null;
  or?: Array<ModelDashboardModuleConditionInput | null> | null;
  not?: ModelDashboardModuleConditionInput | null;
};

export type UpdateDashboardModuleInput = {
  id: string;
  owner?: string | null;
  dashboardId?: string | null;
  error?: DashboardModuleErrorInput | null;
  sequence?: number | null;
  title?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  dashboardModuleLayoutId?: string | null;
};

export type DeleteDashboardModuleInput = {
  id?: string | null;
};

export type CreateDashboardModuleDataInput = {
  id?: string | null;
  owner: string;
  moduleId: string;
  json: string;
  sequence?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelDashboardModuleDataConditionInput = {
  moduleId?: ModelIDInput | null;
  json?: ModelStringInput | null;
  sequence?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDashboardModuleDataConditionInput | null> | null;
  or?: Array<ModelDashboardModuleDataConditionInput | null> | null;
  not?: ModelDashboardModuleDataConditionInput | null;
};

export type UpdateDashboardModuleDataInput = {
  id: string;
  owner?: string | null;
  moduleId?: string | null;
  json?: string | null;
  sequence?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteDashboardModuleDataInput = {
  id?: string | null;
};

export type CreateDashboardModuleFrameInput = {
  id?: string | null;
  owner: string;
  moduleId: string;
  json: string;
  sequence: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelDashboardModuleFrameConditionInput = {
  moduleId?: ModelIDInput | null;
  json?: ModelStringInput | null;
  sequence?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDashboardModuleFrameConditionInput | null> | null;
  or?: Array<ModelDashboardModuleFrameConditionInput | null> | null;
  not?: ModelDashboardModuleFrameConditionInput | null;
};

export type UpdateDashboardModuleFrameInput = {
  id: string;
  owner?: string | null;
  moduleId?: string | null;
  json?: string | null;
  sequence?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteDashboardModuleFrameInput = {
  id?: string | null;
};

export type CreateDashboardModuleLayoutInput = {
  id?: string | null;
  owner: string;
  json: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelDashboardModuleLayoutConditionInput = {
  json?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDashboardModuleLayoutConditionInput | null> | null;
  or?: Array<ModelDashboardModuleLayoutConditionInput | null> | null;
  not?: ModelDashboardModuleLayoutConditionInput | null;
};

export type UpdateDashboardModuleLayoutInput = {
  id: string;
  owner?: string | null;
  json?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteDashboardModuleLayoutInput = {
  id?: string | null;
};

export type CreateDashboardShareInput = {
  id?: string | null;
  dashboardId: string;
  token: string;
  expires?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  owner: string;
};

export type ModelDashboardShareConditionInput = {
  dashboardId?: ModelIDInput | null;
  token?: ModelStringInput | null;
  expires?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDashboardShareConditionInput | null> | null;
  or?: Array<ModelDashboardShareConditionInput | null> | null;
  not?: ModelDashboardShareConditionInput | null;
};

export type UpdateDashboardShareInput = {
  id: string;
  dashboardId?: string | null;
  token?: string | null;
  expires?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  owner?: string | null;
};

export type DeleteDashboardShareInput = {
  id?: string | null;
};

export type CreateDeviceInput = {
  serial_number_id: string;
  name?: string | null;
  description?: string | null;
  part_number?: string | null;
  hardware_revision?: string | null;
  firmware_revision?: string | null;
  last_calibration_ts?: number | null;
  thing_name?: string | null;
  thing_arn?: string | null;
  cert_arn?: string | null;
  manufacture_date?: string | null;
  last_uploaded_ts?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelDeviceConditionInput = {
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  part_number?: ModelStringInput | null;
  hardware_revision?: ModelStringInput | null;
  firmware_revision?: ModelStringInput | null;
  last_calibration_ts?: ModelFloatInput | null;
  thing_name?: ModelStringInput | null;
  thing_arn?: ModelStringInput | null;
  cert_arn?: ModelStringInput | null;
  manufacture_date?: ModelStringInput | null;
  last_uploaded_ts?: ModelFloatInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDeviceConditionInput | null> | null;
  or?: Array<ModelDeviceConditionInput | null> | null;
  not?: ModelDeviceConditionInput | null;
};

export type UpdateDeviceInput = {
  serial_number_id: string;
  name?: string | null;
  description?: string | null;
  part_number?: string | null;
  hardware_revision?: string | null;
  firmware_revision?: string | null;
  last_calibration_ts?: number | null;
  thing_name?: string | null;
  thing_arn?: string | null;
  cert_arn?: string | null;
  manufacture_date?: string | null;
  last_uploaded_ts?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteDeviceInput = {
  serial_number_id: string;
};

export type CreateDeviceAssociationInput = {
  id?: string | null;
  org_id?: string | null;
  s3_bucket_name?: string | null;
  serial_number_id: string;
  status?: DeviceAssociationStatus | null;
  user_id: string;
  customerId: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  deviceAssociationDeviceId: string;
};

export type ModelDeviceAssociationConditionInput = {
  org_id?: ModelStringInput | null;
  s3_bucket_name?: ModelStringInput | null;
  serial_number_id?: ModelStringInput | null;
  status?: ModelDeviceAssociationStatusInput | null;
  customerId?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDeviceAssociationConditionInput | null> | null;
  or?: Array<ModelDeviceAssociationConditionInput | null> | null;
  not?: ModelDeviceAssociationConditionInput | null;
};

export type ModelDeviceAssociationStatusInput = {
  eq?: DeviceAssociationStatus | null;
  ne?: DeviceAssociationStatus | null;
};

export type UpdateDeviceAssociationInput = {
  id: string;
  org_id?: string | null;
  s3_bucket_name?: string | null;
  serial_number_id?: string | null;
  status?: DeviceAssociationStatus | null;
  user_id?: string | null;
  customerId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deviceAssociationDeviceId?: string | null;
};

export type DeleteDeviceAssociationInput = {
  id?: string | null;
};

export type CreateFileInput = {
  id?: string | null;
  user_id: string;
  serial_number_id?: string | null;
  sensor_name?: string | null;
  sensor_type?: string | null;
  file_name?: string | null;
  file_size?: number | null;
  s3_bucket_name?: string | null;
  s3_object_key: string;
  device_meta?: string | null;
  recording_length?: number | null;
  recording_ts?: number | null;
  created_ts?: number | null;
  modified_ts?: number | null;
  archived_ts?: number | null;
  file_uploaded: boolean;
  createdAt?: string | null;
  updatedAt?: string | null;
  fileDeviceId?: string | null;
};

export type ModelFileConditionInput = {
  serial_number_id?: ModelStringInput | null;
  sensor_name?: ModelStringInput | null;
  sensor_type?: ModelStringInput | null;
  file_name?: ModelStringInput | null;
  file_size?: ModelFloatInput | null;
  s3_bucket_name?: ModelStringInput | null;
  s3_object_key?: ModelStringInput | null;
  device_meta?: ModelStringInput | null;
  recording_length?: ModelFloatInput | null;
  recording_ts?: ModelIntInput | null;
  created_ts?: ModelIntInput | null;
  modified_ts?: ModelIntInput | null;
  archived_ts?: ModelIntInput | null;
  file_uploaded?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelFileConditionInput | null> | null;
  or?: Array<ModelFileConditionInput | null> | null;
  not?: ModelFileConditionInput | null;
};

export type UpdateFileInput = {
  id: string;
  user_id?: string | null;
  serial_number_id?: string | null;
  sensor_name?: string | null;
  sensor_type?: string | null;
  file_name?: string | null;
  file_size?: number | null;
  s3_bucket_name?: string | null;
  s3_object_key?: string | null;
  device_meta?: string | null;
  recording_length?: number | null;
  recording_ts?: number | null;
  created_ts?: number | null;
  modified_ts?: number | null;
  archived_ts?: number | null;
  file_uploaded?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  fileDeviceId?: string | null;
};

export type DeleteFileInput = {
  id?: string | null;
};

export type CreateStripePlanInput = {
  id: StripePlanId;
  tierId: TierName;
  billingCycle?: BillingCycle | null;
  cost?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelStripePlanConditionInput = {
  tierId?: ModelTierNameInput | null;
  billingCycle?: ModelBillingCycleInput | null;
  cost?: ModelFloatInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelStripePlanConditionInput | null> | null;
  or?: Array<ModelStripePlanConditionInput | null> | null;
  not?: ModelStripePlanConditionInput | null;
};

export type ModelBillingCycleInput = {
  eq?: BillingCycle | null;
  ne?: BillingCycle | null;
};

export type UpdateStripePlanInput = {
  id: StripePlanId;
  tierId?: TierName | null;
  billingCycle?: BillingCycle | null;
  cost?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteStripePlanInput = {
  id?: string | null;
};

export type CreateTagInput = {
  id?: string | null;
  user_id: string;
  tagFileId: string;
  value: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelTagConditionInput = {
  tagFileId?: ModelIDInput | null;
  value?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelTagConditionInput | null> | null;
  or?: Array<ModelTagConditionInput | null> | null;
  not?: ModelTagConditionInput | null;
};

export type UpdateTagInput = {
  id: string;
  user_id?: string | null;
  tagFileId?: string | null;
  value?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteTagInput = {
  id?: string | null;
};

export type CreateTierInput = {
  id: TierName;
  costMonthly?: number | null;
  costYearly?: number | null;
  deviceMaxCount?: number | null;
  storageLimitGb?: number | null;
  alertMaxCount?: number | null;
  hasApiAccess?: boolean | null;
  billingCycles?: Array<BillingCycle | null> | null;
  reportMaxCount?: number | null;
  reportTemplate?: ReportTemplate | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelTierConditionInput = {
  costMonthly?: ModelFloatInput | null;
  costYearly?: ModelFloatInput | null;
  deviceMaxCount?: ModelIntInput | null;
  storageLimitGb?: ModelIntInput | null;
  alertMaxCount?: ModelIntInput | null;
  hasApiAccess?: ModelBooleanInput | null;
  billingCycles?: ModelBillingCycleListInput | null;
  reportMaxCount?: ModelIntInput | null;
  reportTemplate?: ModelReportTemplateInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelTierConditionInput | null> | null;
  or?: Array<ModelTierConditionInput | null> | null;
  not?: ModelTierConditionInput | null;
};

export type ModelBillingCycleListInput = {
  eq?: Array<BillingCycle | null> | null;
  ne?: Array<BillingCycle | null> | null;
  contains?: BillingCycle | null;
  notContains?: BillingCycle | null;
};

export type ModelReportTemplateInput = {
  eq?: ReportTemplate | null;
  ne?: ReportTemplate | null;
};

export type UpdateTierInput = {
  id: TierName;
  costMonthly?: number | null;
  costYearly?: number | null;
  deviceMaxCount?: number | null;
  storageLimitGb?: number | null;
  alertMaxCount?: number | null;
  hasApiAccess?: boolean | null;
  billingCycles?: Array<BillingCycle | null> | null;
  reportMaxCount?: number | null;
  reportTemplate?: ReportTemplate | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteTierInput = {
  id?: string | null;
};

export type TagType = {
  __typename: 'TagType';
  value?: string;
};

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type FileType = {
  __typename: 'FileType';
  nextCursor?: string | null;
  totalCount?: number | null;
  files?: Array<File | null>;
};

export type ModelAlertFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  customerId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  tagName?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAlertFilterInput | null> | null;
  or?: Array<ModelAlertFilterInput | null> | null;
  not?: ModelAlertFilterInput | null;
};

export type ModelAlertConnection = {
  __typename: 'ModelAlertConnection';
  items?: Array<Alert | null> | null;
  nextToken?: string | null;
};

export type ModelApiKeyFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  revoked?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelApiKeyFilterInput | null> | null;
  or?: Array<ModelApiKeyFilterInput | null> | null;
  not?: ModelApiKeyFilterInput | null;
};

export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type ModelAttributeFilterInput = {
  id?: ModelIDInput | null;
  user_id?: ModelStringInput | null;
  attributeFileId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  type?: ModelStringInput | null;
  value?: ModelStringInput | null;
  thing?: ModelStringInput | null;
  mutable?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAttributeFilterInput | null> | null;
  or?: Array<ModelAttributeFilterInput | null> | null;
  not?: ModelAttributeFilterInput | null;
};

export type ModelBillingFilterInput = {
  id?: ModelIDInput | null;
  stripe_session_id?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  stripe_customer_id?: ModelStringInput | null;
  stripe_subscription_id?: ModelStringInput | null;
  subscription_status?: ModelBillingSubscriptionStatusInput | null;
  stripe_plan_id?: ModelStripePlanIdInput | null;
  suspended_at?: ModelIntInput | null;
  next_billing_at?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelBillingFilterInput | null> | null;
  or?: Array<ModelBillingFilterInput | null> | null;
  not?: ModelBillingFilterInput | null;
};

export type ModelBillingConnection = {
  __typename: 'ModelBillingConnection';
  items?: Array<Billing | null> | null;
  nextToken?: string | null;
};

export type ModelCustomScriptFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  lines?: ModelStringInput | null;
  status?: ModelCustomScriptStatusInput | null;
  title?: ModelStringInput | null;
  type?: ModelCustomScriptTypeInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCustomScriptFilterInput | null> | null;
  or?: Array<ModelCustomScriptFilterInput | null> | null;
  not?: ModelCustomScriptFilterInput | null;
};

export type ModelCustomScriptConnection = {
  __typename: 'ModelCustomScriptConnection';
  items?: Array<CustomScript | null> | null;
  nextToken?: string | null;
};

export type ModelCustomerFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  storageConsumed?: ModelFloatInput | null;
  storageSpaceRemaining?: ModelBooleanInput | null;
  tierId?: ModelTierNameInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  companyName?: ModelStringInput | null;
  logoImage?: ModelStringInput | null;
  and?: Array<ModelCustomerFilterInput | null> | null;
  or?: Array<ModelCustomerFilterInput | null> | null;
  not?: ModelCustomerFilterInput | null;
};

export type ModelCustomerConnection = {
  __typename: 'ModelCustomerConnection';
  items?: Array<Customer | null> | null;
  nextToken?: string | null;
};

export type ModelDashboardFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  customScriptId?: ModelIDInput | null;
  defaultScript?: ModelStringInput | null;
  output_logs?: ModelStringInput | null;
  lastTransactionId?: ModelIDInput | null;
  maxFiles?: ModelIntInput | null;
  status?: ModelDashboardStatusInput | null;
  tags?: ModelStringInput | null;
  file_ids?: ModelStringInput | null;
  title?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  sequence?: ModelIntInput | null;
  and?: Array<ModelDashboardFilterInput | null> | null;
  or?: Array<ModelDashboardFilterInput | null> | null;
  not?: ModelDashboardFilterInput | null;
};

export type ModelDashboardConnection = {
  __typename: 'ModelDashboardConnection';
  items?: Array<Dashboard | null> | null;
  nextToken?: string | null;
};

export type ModelDashboardModuleFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  dashboardId?: ModelIDInput | null;
  sequence?: ModelIntInput | null;
  title?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDashboardModuleFilterInput | null> | null;
  or?: Array<ModelDashboardModuleFilterInput | null> | null;
  not?: ModelDashboardModuleFilterInput | null;
};

export type ModelIntKeyConditionInput = {
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelDashboardModuleDataFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  moduleId?: ModelIDInput | null;
  json?: ModelStringInput | null;
  sequence?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDashboardModuleDataFilterInput | null> | null;
  or?: Array<ModelDashboardModuleDataFilterInput | null> | null;
  not?: ModelDashboardModuleDataFilterInput | null;
};

export type ModelDashboardModuleFrameFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  moduleId?: ModelIDInput | null;
  json?: ModelStringInput | null;
  sequence?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDashboardModuleFrameFilterInput | null> | null;
  or?: Array<ModelDashboardModuleFrameFilterInput | null> | null;
  not?: ModelDashboardModuleFrameFilterInput | null;
};

export type ModelDashboardModuleLayoutFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  json?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDashboardModuleLayoutFilterInput | null> | null;
  or?: Array<ModelDashboardModuleLayoutFilterInput | null> | null;
  not?: ModelDashboardModuleLayoutFilterInput | null;
};

export type ModelDashboardModuleLayoutConnection = {
  __typename: 'ModelDashboardModuleLayoutConnection';
  items?: Array<DashboardModuleLayout | null> | null;
  nextToken?: string | null;
};

export type ModelDashboardShareFilterInput = {
  id?: ModelIDInput | null;
  dashboardId?: ModelIDInput | null;
  token?: ModelStringInput | null;
  expires?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelDashboardShareFilterInput | null> | null;
  or?: Array<ModelDashboardShareFilterInput | null> | null;
  not?: ModelDashboardShareFilterInput | null;
};

export type ModelDeviceFilterInput = {
  serial_number_id?: ModelStringInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  part_number?: ModelStringInput | null;
  hardware_revision?: ModelStringInput | null;
  firmware_revision?: ModelStringInput | null;
  last_calibration_ts?: ModelFloatInput | null;
  thing_name?: ModelStringInput | null;
  thing_arn?: ModelStringInput | null;
  cert_arn?: ModelStringInput | null;
  manufacture_date?: ModelStringInput | null;
  last_uploaded_ts?: ModelFloatInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDeviceFilterInput | null> | null;
  or?: Array<ModelDeviceFilterInput | null> | null;
  not?: ModelDeviceFilterInput | null;
};

export type ModelDeviceConnection = {
  __typename: 'ModelDeviceConnection';
  items?: Array<Device | null> | null;
  nextToken?: string | null;
};

export type ModelDeviceAssociationFilterInput = {
  id?: ModelIDInput | null;
  org_id?: ModelStringInput | null;
  s3_bucket_name?: ModelStringInput | null;
  serial_number_id?: ModelStringInput | null;
  status?: ModelDeviceAssociationStatusInput | null;
  user_id?: ModelStringInput | null;
  customerId?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDeviceAssociationFilterInput | null> | null;
  or?: Array<ModelDeviceAssociationFilterInput | null> | null;
  not?: ModelDeviceAssociationFilterInput | null;
};

export type ModelFileFilterInput = {
  id?: ModelIDInput | null;
  user_id?: ModelStringInput | null;
  serial_number_id?: ModelStringInput | null;
  sensor_name?: ModelStringInput | null;
  sensor_type?: ModelStringInput | null;
  file_name?: ModelStringInput | null;
  file_size?: ModelFloatInput | null;
  s3_bucket_name?: ModelStringInput | null;
  s3_object_key?: ModelStringInput | null;
  device_meta?: ModelStringInput | null;
  recording_length?: ModelFloatInput | null;
  recording_ts?: ModelIntInput | null;
  created_ts?: ModelIntInput | null;
  modified_ts?: ModelIntInput | null;
  archived_ts?: ModelIntInput | null;
  file_uploaded?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelFileFilterInput | null> | null;
  or?: Array<ModelFileFilterInput | null> | null;
  not?: ModelFileFilterInput | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type SearchableFileFilterInput = {
  id?: SearchableIDFilterInput | null;
  user_id?: SearchableStringFilterInput | null;
  serial_number_id?: SearchableStringFilterInput | null;
  sensor_name?: SearchableStringFilterInput | null;
  sensor_type?: SearchableStringFilterInput | null;
  file_name?: SearchableStringFilterInput | null;
  file_size?: SearchableFloatFilterInput | null;
  s3_bucket_name?: SearchableStringFilterInput | null;
  s3_object_key?: SearchableStringFilterInput | null;
  device_meta?: SearchableStringFilterInput | null;
  recording_length?: SearchableFloatFilterInput | null;
  recording_ts?: SearchableIntFilterInput | null;
  created_ts?: SearchableIntFilterInput | null;
  modified_ts?: SearchableIntFilterInput | null;
  archived_ts?: SearchableIntFilterInput | null;
  file_uploaded?: SearchableBooleanFilterInput | null;
  createdAt?: SearchableStringFilterInput | null;
  updatedAt?: SearchableStringFilterInput | null;
  and?: Array<SearchableFileFilterInput | null> | null;
  or?: Array<SearchableFileFilterInput | null> | null;
  not?: SearchableFileFilterInput | null;
};

export type SearchableIDFilterInput = {
  ne?: string | null;
  gt?: string | null;
  lt?: string | null;
  gte?: string | null;
  lte?: string | null;
  eq?: string | null;
  match?: string | null;
  matchPhrase?: string | null;
  matchPhrasePrefix?: string | null;
  multiMatch?: string | null;
  exists?: boolean | null;
  wildcard?: string | null;
  regexp?: string | null;
  range?: Array<string | null> | null;
};

export type SearchableStringFilterInput = {
  ne?: string | null;
  gt?: string | null;
  lt?: string | null;
  gte?: string | null;
  lte?: string | null;
  eq?: string | null;
  match?: string | null;
  matchPhrase?: string | null;
  matchPhrasePrefix?: string | null;
  multiMatch?: string | null;
  exists?: boolean | null;
  wildcard?: string | null;
  regexp?: string | null;
  range?: Array<string | null> | null;
};

export type SearchableFloatFilterInput = {
  ne?: number | null;
  gt?: number | null;
  lt?: number | null;
  gte?: number | null;
  lte?: number | null;
  eq?: number | null;
  range?: Array<number | null> | null;
};

export type SearchableIntFilterInput = {
  ne?: number | null;
  gt?: number | null;
  lt?: number | null;
  gte?: number | null;
  lte?: number | null;
  eq?: number | null;
  range?: Array<number | null> | null;
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null;
  ne?: boolean | null;
};

export type SearchableFileSortInput = {
  field?: SearchableFileSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableFileSortableFields {
  id = 'id',
  user_id = 'user_id',
  serial_number_id = 'serial_number_id',
  sensor_name = 'sensor_name',
  sensor_type = 'sensor_type',
  file_name = 'file_name',
  file_size = 'file_size',
  s3_bucket_name = 's3_bucket_name',
  s3_object_key = 's3_object_key',
  device_meta = 'device_meta',
  recording_length = 'recording_length',
  recording_ts = 'recording_ts',
  created_ts = 'created_ts',
  modified_ts = 'modified_ts',
  archived_ts = 'archived_ts',
  file_uploaded = 'file_uploaded',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt'
}

export enum SearchableSortDirection {
  asc = 'asc',
  desc = 'desc'
}

export type SearchableFileConnection = {
  __typename: 'SearchableFileConnection';
  items?: Array<File | null> | null;
  nextToken?: string | null;
  total?: number | null;
};

export type ModelStripePlanFilterInput = {
  id?: ModelStripePlanIdInput | null;
  tierId?: ModelTierNameInput | null;
  billingCycle?: ModelBillingCycleInput | null;
  cost?: ModelFloatInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelStripePlanFilterInput | null> | null;
  or?: Array<ModelStripePlanFilterInput | null> | null;
  not?: ModelStripePlanFilterInput | null;
};

export type ModelTagFilterInput = {
  id?: ModelIDInput | null;
  user_id?: ModelStringInput | null;
  tagFileId?: ModelIDInput | null;
  value?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelTagFilterInput | null> | null;
  or?: Array<ModelTagFilterInput | null> | null;
  not?: ModelTagFilterInput | null;
};

export type ModelTierFilterInput = {
  id?: ModelTierNameInput | null;
  costMonthly?: ModelFloatInput | null;
  costYearly?: ModelFloatInput | null;
  deviceMaxCount?: ModelIntInput | null;
  storageLimitGb?: ModelIntInput | null;
  alertMaxCount?: ModelIntInput | null;
  hasApiAccess?: ModelBooleanInput | null;
  billingCycles?: ModelBillingCycleListInput | null;
  reportMaxCount?: ModelIntInput | null;
  reportTemplate?: ModelReportTemplateInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelTierFilterInput | null> | null;
  or?: Array<ModelTierFilterInput | null> | null;
  not?: ModelTierFilterInput | null;
};

export type ModelTierConnection = {
  __typename: 'ModelTierConnection';
  items?: Array<Tier | null> | null;
  nextToken?: string | null;
};

export type CreateAlertMutationVariables = {
  input?: CreateAlertInput;
  condition?: ModelAlertConditionInput | null;
};

export type CreateAlertMutation = {
  createAlert?: {
    __typename: 'Alert';
    id: string;
    owner: string;
    customerId: string;
    name?: string | null;
    tagName: string;
    enabled?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer?: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateAlertMutationVariables = {
  input?: UpdateAlertInput;
  condition?: ModelAlertConditionInput | null;
};

export type UpdateAlertMutation = {
  updateAlert?: {
    __typename: 'Alert';
    id: string;
    owner: string;
    customerId: string;
    name?: string | null;
    tagName: string;
    enabled?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer?: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteAlertMutationVariables = {
  input?: DeleteAlertInput;
  condition?: ModelAlertConditionInput | null;
};

export type DeleteAlertMutation = {
  deleteAlert?: {
    __typename: 'Alert';
    id: string;
    owner: string;
    customerId: string;
    name?: string | null;
    tagName: string;
    enabled?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer?: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateApiKeyMutationVariables = {
  input?: CreateApiKeyInput;
  condition?: ModelApiKeyConditionInput | null;
};

export type CreateApiKeyMutation = {
  createApiKey?: {
    __typename: 'ApiKey';
    id: string;
    name?: string | null;
    owner: string;
    revoked: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateApiKeyMutationVariables = {
  input?: UpdateApiKeyInput;
  condition?: ModelApiKeyConditionInput | null;
};

export type UpdateApiKeyMutation = {
  updateApiKey?: {
    __typename: 'ApiKey';
    id: string;
    name?: string | null;
    owner: string;
    revoked: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteApiKeyMutationVariables = {
  input?: DeleteApiKeyInput;
  condition?: ModelApiKeyConditionInput | null;
};

export type DeleteApiKeyMutation = {
  deleteApiKey?: {
    __typename: 'ApiKey';
    id: string;
    name?: string | null;
    owner: string;
    revoked: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateAttributeMutationVariables = {
  input?: CreateAttributeInput;
  condition?: ModelAttributeConditionInput | null;
};

export type CreateAttributeMutation = {
  createAttribute?: {
    __typename: 'Attribute';
    id: string;
    user_id: string;
    attributeFileId: string;
    name: string;
    type: string;
    value: string;
    thing?: string | null;
    mutable: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateAttributeMutationVariables = {
  input?: UpdateAttributeInput;
  condition?: ModelAttributeConditionInput | null;
};

export type UpdateAttributeMutation = {
  updateAttribute?: {
    __typename: 'Attribute';
    id: string;
    user_id: string;
    attributeFileId: string;
    name: string;
    type: string;
    value: string;
    thing?: string | null;
    mutable: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteAttributeMutationVariables = {
  input?: DeleteAttributeInput;
  condition?: ModelAttributeConditionInput | null;
};

export type DeleteAttributeMutation = {
  deleteAttribute?: {
    __typename: 'Attribute';
    id: string;
    user_id: string;
    attributeFileId: string;
    name: string;
    type: string;
    value: string;
    thing?: string | null;
    mutable: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateBillingMutationVariables = {
  input?: CreateBillingInput;
  condition?: ModelBillingConditionInput | null;
};

export type CreateBillingMutation = {
  createBilling?: {
    __typename: 'Billing';
    id: string;
    stripe_session_id: string;
    owner: string;
    stripe_customer_id: string;
    stripe_subscription_id?: string | null;
    subscription_status: BillingSubscriptionStatus;
    stripe_plan_id: StripePlanId;
    suspended_at?: number | null;
    next_billing_at?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type UpdateBillingMutationVariables = {
  input?: UpdateBillingInput;
  condition?: ModelBillingConditionInput | null;
};

export type UpdateBillingMutation = {
  updateBilling?: {
    __typename: 'Billing';
    id: string;
    stripe_session_id: string;
    owner: string;
    stripe_customer_id: string;
    stripe_subscription_id?: string | null;
    subscription_status: BillingSubscriptionStatus;
    stripe_plan_id: StripePlanId;
    suspended_at?: number | null;
    next_billing_at?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type DeleteBillingMutationVariables = {
  input?: DeleteBillingInput;
  condition?: ModelBillingConditionInput | null;
};

export type DeleteBillingMutation = {
  deleteBilling?: {
    __typename: 'Billing';
    id: string;
    stripe_session_id: string;
    owner: string;
    stripe_customer_id: string;
    stripe_subscription_id?: string | null;
    subscription_status: BillingSubscriptionStatus;
    stripe_plan_id: StripePlanId;
    suspended_at?: number | null;
    next_billing_at?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type CreateCustomScriptMutationVariables = {
  input?: CreateCustomScriptInput;
  condition?: ModelCustomScriptConditionInput | null;
};

export type CreateCustomScriptMutation = {
  createCustomScript?: {
    __typename: 'CustomScript';
    id: string;
    owner: string;
    error?: {
      __typename: 'CustomScriptError';
      code?: number | null;
      detail: string;
      message: string;
      status?: string | null;
      traces: Array<{
        __typename: 'StackTrace';
        filename: string;
        lineno: string;
        name?: string | null;
        offset?: number | null;
        text?: string | null;
      }>;
      transactionId?: string | null;
      type: string;
    } | null;
    lines: Array<string>;
    status?: CustomScriptStatus | null;
    title?: string | null;
    type: CustomScriptType;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateCustomScriptMutationVariables = {
  input?: UpdateCustomScriptInput;
  condition?: ModelCustomScriptConditionInput | null;
};

export type UpdateCustomScriptMutation = {
  updateCustomScript?: {
    __typename: 'CustomScript';
    id: string;
    owner: string;
    error?: {
      __typename: 'CustomScriptError';
      code?: number | null;
      detail: string;
      message: string;
      status?: string | null;
      traces: Array<{
        __typename: 'StackTrace';
        filename: string;
        lineno: string;
        name?: string | null;
        offset?: number | null;
        text?: string | null;
      }>;
      transactionId?: string | null;
      type: string;
    } | null;
    lines: Array<string>;
    status?: CustomScriptStatus | null;
    title?: string | null;
    type: CustomScriptType;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteCustomScriptMutationVariables = {
  input?: DeleteCustomScriptInput;
  condition?: ModelCustomScriptConditionInput | null;
};

export type DeleteCustomScriptMutation = {
  deleteCustomScript?: {
    __typename: 'CustomScript';
    id: string;
    owner: string;
    error?: {
      __typename: 'CustomScriptError';
      code?: number | null;
      detail: string;
      message: string;
      status?: string | null;
      traces: Array<{
        __typename: 'StackTrace';
        filename: string;
        lineno: string;
        name?: string | null;
        offset?: number | null;
        text?: string | null;
      }>;
      transactionId?: string | null;
      type: string;
    } | null;
    lines: Array<string>;
    status?: CustomScriptStatus | null;
    title?: string | null;
    type: CustomScriptType;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateCustomerMutationVariables = {
  input?: CreateCustomerInput;
  condition?: ModelCustomerConditionInput | null;
};

export type CreateCustomerMutation = {
  createCustomer?: {
    __typename: 'Customer';
    id: string;
    owner: string;
    storageConsumed?: number | null;
    storageSpaceRemaining?: boolean | null;
    tierId: TierName;
    createdAt?: string | null;
    updatedAt?: string | null;
    companyName?: string | null;
    logoImage?: string | null;
    apiKeys?: {
      __typename: 'ModelApiKeyConnection';
      items?: Array<{
        __typename: 'ApiKey';
        id: string;
        name?: string | null;
        owner: string;
        revoked: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    billing?: {
      __typename: 'Billing';
      id: string;
      stripe_session_id: string;
      owner: string;
      stripe_customer_id: string;
      stripe_subscription_id?: string | null;
      subscription_status: BillingSubscriptionStatus;
      stripe_plan_id: StripePlanId;
      suspended_at?: number | null;
      next_billing_at?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      };
    } | null;
    deviceAssociations?: {
      __typename: 'ModelDeviceAssociationConnection';
      items?: Array<{
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    tier?: {
      __typename: 'Tier';
      id: TierName;
      costMonthly?: number | null;
      costYearly?: number | null;
      deviceMaxCount?: number | null;
      storageLimitGb?: number | null;
      alertMaxCount?: number | null;
      hasApiAccess?: boolean | null;
      billingCycles?: Array<BillingCycle | null> | null;
      reportMaxCount?: number | null;
      reportTemplate?: ReportTemplate | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stripePlans?: {
        __typename: 'ModelStripePlanConnection';
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateCustomerMutationVariables = {
  input?: UpdateCustomerInput;
  condition?: ModelCustomerConditionInput | null;
};

export type UpdateCustomerMutation = {
  updateCustomer?: {
    __typename: 'Customer';
    id: string;
    owner: string;
    storageConsumed?: number | null;
    storageSpaceRemaining?: boolean | null;
    tierId: TierName;
    createdAt?: string | null;
    updatedAt?: string | null;
    companyName?: string | null;
    logoImage?: string | null;
    apiKeys?: {
      __typename: 'ModelApiKeyConnection';
      items?: Array<{
        __typename: 'ApiKey';
        id: string;
        name?: string | null;
        owner: string;
        revoked: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    billing?: {
      __typename: 'Billing';
      id: string;
      stripe_session_id: string;
      owner: string;
      stripe_customer_id: string;
      stripe_subscription_id?: string | null;
      subscription_status: BillingSubscriptionStatus;
      stripe_plan_id: StripePlanId;
      suspended_at?: number | null;
      next_billing_at?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      };
    } | null;
    deviceAssociations?: {
      __typename: 'ModelDeviceAssociationConnection';
      items?: Array<{
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    tier?: {
      __typename: 'Tier';
      id: TierName;
      costMonthly?: number | null;
      costYearly?: number | null;
      deviceMaxCount?: number | null;
      storageLimitGb?: number | null;
      alertMaxCount?: number | null;
      hasApiAccess?: boolean | null;
      billingCycles?: Array<BillingCycle | null> | null;
      reportMaxCount?: number | null;
      reportTemplate?: ReportTemplate | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stripePlans?: {
        __typename: 'ModelStripePlanConnection';
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteCustomerMutationVariables = {
  input?: DeleteCustomerInput;
  condition?: ModelCustomerConditionInput | null;
};

export type DeleteCustomerMutation = {
  deleteCustomer?: {
    __typename: 'Customer';
    id: string;
    owner: string;
    storageConsumed?: number | null;
    storageSpaceRemaining?: boolean | null;
    tierId: TierName;
    createdAt?: string | null;
    updatedAt?: string | null;
    companyName?: string | null;
    logoImage?: string | null;
    apiKeys?: {
      __typename: 'ModelApiKeyConnection';
      items?: Array<{
        __typename: 'ApiKey';
        id: string;
        name?: string | null;
        owner: string;
        revoked: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    billing?: {
      __typename: 'Billing';
      id: string;
      stripe_session_id: string;
      owner: string;
      stripe_customer_id: string;
      stripe_subscription_id?: string | null;
      subscription_status: BillingSubscriptionStatus;
      stripe_plan_id: StripePlanId;
      suspended_at?: number | null;
      next_billing_at?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      };
    } | null;
    deviceAssociations?: {
      __typename: 'ModelDeviceAssociationConnection';
      items?: Array<{
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    tier?: {
      __typename: 'Tier';
      id: TierName;
      costMonthly?: number | null;
      costYearly?: number | null;
      deviceMaxCount?: number | null;
      storageLimitGb?: number | null;
      alertMaxCount?: number | null;
      hasApiAccess?: boolean | null;
      billingCycles?: Array<BillingCycle | null> | null;
      reportMaxCount?: number | null;
      reportTemplate?: ReportTemplate | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stripePlans?: {
        __typename: 'ModelStripePlanConnection';
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateDashboardMutationVariables = {
  input?: CreateDashboardInput;
  condition?: ModelDashboardConditionInput | null;
};

export type CreateDashboardMutation = {
  createDashboard?: {
    __typename: 'Dashboard';
    id: string;
    owner: string;
    customScriptId?: string | null;
    defaultScript?: string | null;
    error?: {
      __typename: 'DashboardError';
      code?: number | null;
      message: string;
      status?: string | null;
      transactionId?: string | null;
      scriptError?: boolean | null;
    } | null;
    output_logs?: string | null;
    lastTransactionId?: string | null;
    maxFiles?: number | null;
    status: DashboardStatus;
    tags: Array<string>;
    file_ids?: Array<string> | null;
    title?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customScript?: {
      __typename: 'CustomScript';
      id: string;
      owner: string;
      error?: {
        __typename: 'CustomScriptError';
        code?: number | null;
        detail: string;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        type: string;
      } | null;
      lines: Array<string>;
      status?: CustomScriptStatus | null;
      title?: string | null;
      type: CustomScriptType;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
    notes?: string | null;
    sequence?: number | null;
    modules?: {
      __typename: 'ModelDashboardModuleConnection';
      items?: Array<{
        __typename: 'DashboardModule';
        id: string;
        owner: string;
        dashboardId: string;
        sequence?: number | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    shares?: {
      __typename: 'ModelDashboardShareConnection';
      items?: Array<{
        __typename: 'DashboardShare';
        id: string;
        dashboardId: string;
        token: string;
        expires?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner: string;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateDashboardMutationVariables = {
  input?: UpdateDashboardInput;
  condition?: ModelDashboardConditionInput | null;
};

export type UpdateDashboardMutation = {
  updateDashboard?: {
    __typename: 'Dashboard';
    id: string;
    owner: string;
    customScriptId?: string | null;
    defaultScript?: string | null;
    error?: {
      __typename: 'DashboardError';
      code?: number | null;
      message: string;
      status?: string | null;
      transactionId?: string | null;
      scriptError?: boolean | null;
    } | null;
    output_logs?: string | null;
    lastTransactionId?: string | null;
    maxFiles?: number | null;
    status: DashboardStatus;
    tags: Array<string>;
    file_ids?: Array<string> | null;
    title?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customScript?: {
      __typename: 'CustomScript';
      id: string;
      owner: string;
      error?: {
        __typename: 'CustomScriptError';
        code?: number | null;
        detail: string;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        type: string;
      } | null;
      lines: Array<string>;
      status?: CustomScriptStatus | null;
      title?: string | null;
      type: CustomScriptType;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
    notes?: string | null;
    sequence?: number | null;
    modules?: {
      __typename: 'ModelDashboardModuleConnection';
      items?: Array<{
        __typename: 'DashboardModule';
        id: string;
        owner: string;
        dashboardId: string;
        sequence?: number | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    shares?: {
      __typename: 'ModelDashboardShareConnection';
      items?: Array<{
        __typename: 'DashboardShare';
        id: string;
        dashboardId: string;
        token: string;
        expires?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner: string;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteDashboardMutationVariables = {
  input?: DeleteDashboardInput;
  condition?: ModelDashboardConditionInput | null;
};

export type DeleteDashboardMutation = {
  deleteDashboard?: {
    __typename: 'Dashboard';
    id: string;
    owner: string;
    customScriptId?: string | null;
    defaultScript?: string | null;
    error?: {
      __typename: 'DashboardError';
      code?: number | null;
      message: string;
      status?: string | null;
      transactionId?: string | null;
      scriptError?: boolean | null;
    } | null;
    output_logs?: string | null;
    lastTransactionId?: string | null;
    maxFiles?: number | null;
    status: DashboardStatus;
    tags: Array<string>;
    file_ids?: Array<string> | null;
    title?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customScript?: {
      __typename: 'CustomScript';
      id: string;
      owner: string;
      error?: {
        __typename: 'CustomScriptError';
        code?: number | null;
        detail: string;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        type: string;
      } | null;
      lines: Array<string>;
      status?: CustomScriptStatus | null;
      title?: string | null;
      type: CustomScriptType;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
    notes?: string | null;
    sequence?: number | null;
    modules?: {
      __typename: 'ModelDashboardModuleConnection';
      items?: Array<{
        __typename: 'DashboardModule';
        id: string;
        owner: string;
        dashboardId: string;
        sequence?: number | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    shares?: {
      __typename: 'ModelDashboardShareConnection';
      items?: Array<{
        __typename: 'DashboardShare';
        id: string;
        dashboardId: string;
        token: string;
        expires?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner: string;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateDashboardModuleMutationVariables = {
  input?: CreateDashboardModuleInput;
  condition?: ModelDashboardModuleConditionInput | null;
};

export type CreateDashboardModuleMutation = {
  createDashboardModule?: {
    __typename: 'DashboardModule';
    id: string;
    owner: string;
    dashboardId: string;
    error?: {
      __typename: 'DashboardModuleError';
      code?: number | null;
      message: string;
      status?: string | null;
      transactionId?: string | null;
    } | null;
    sequence?: number | null;
    title?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    dashboard: {
      __typename: 'Dashboard';
      id: string;
      owner: string;
      customScriptId?: string | null;
      defaultScript?: string | null;
      error?: {
        __typename: 'DashboardError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        scriptError?: boolean | null;
      } | null;
      output_logs?: string | null;
      lastTransactionId?: string | null;
      maxFiles?: number | null;
      status: DashboardStatus;
      tags: Array<string>;
      file_ids?: Array<string> | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customScript?: {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        lines: Array<string>;
        status?: CustomScriptStatus | null;
        title?: string | null;
        type: CustomScriptType;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      notes?: string | null;
      sequence?: number | null;
      modules?: {
        __typename: 'ModelDashboardModuleConnection';
        nextToken?: string | null;
      } | null;
      shares?: {
        __typename: 'ModelDashboardShareConnection';
        nextToken?: string | null;
      } | null;
    };
    data?: {
      __typename: 'ModelDashboardModuleDataConnection';
      items?: Array<{
        __typename: 'DashboardModuleData';
        id: string;
        owner: string;
        moduleId: string;
        json: string;
        sequence?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    frames?: {
      __typename: 'ModelDashboardModuleFrameConnection';
      items?: Array<{
        __typename: 'DashboardModuleFrame';
        id: string;
        owner: string;
        moduleId: string;
        json: string;
        sequence: number;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    layout?: {
      __typename: 'DashboardModuleLayout';
      id: string;
      owner: string;
      json: string;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
  } | null;
};

export type UpdateDashboardModuleMutationVariables = {
  input?: UpdateDashboardModuleInput;
  condition?: ModelDashboardModuleConditionInput | null;
};

export type UpdateDashboardModuleMutation = {
  updateDashboardModule?: {
    __typename: 'DashboardModule';
    id: string;
    owner: string;
    dashboardId: string;
    error?: {
      __typename: 'DashboardModuleError';
      code?: number | null;
      message: string;
      status?: string | null;
      transactionId?: string | null;
    } | null;
    sequence?: number | null;
    title?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    dashboard: {
      __typename: 'Dashboard';
      id: string;
      owner: string;
      customScriptId?: string | null;
      defaultScript?: string | null;
      error?: {
        __typename: 'DashboardError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        scriptError?: boolean | null;
      } | null;
      output_logs?: string | null;
      lastTransactionId?: string | null;
      maxFiles?: number | null;
      status: DashboardStatus;
      tags: Array<string>;
      file_ids?: Array<string> | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customScript?: {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        lines: Array<string>;
        status?: CustomScriptStatus | null;
        title?: string | null;
        type: CustomScriptType;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      notes?: string | null;
      sequence?: number | null;
      modules?: {
        __typename: 'ModelDashboardModuleConnection';
        nextToken?: string | null;
      } | null;
      shares?: {
        __typename: 'ModelDashboardShareConnection';
        nextToken?: string | null;
      } | null;
    };
    data?: {
      __typename: 'ModelDashboardModuleDataConnection';
      items?: Array<{
        __typename: 'DashboardModuleData';
        id: string;
        owner: string;
        moduleId: string;
        json: string;
        sequence?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    frames?: {
      __typename: 'ModelDashboardModuleFrameConnection';
      items?: Array<{
        __typename: 'DashboardModuleFrame';
        id: string;
        owner: string;
        moduleId: string;
        json: string;
        sequence: number;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    layout?: {
      __typename: 'DashboardModuleLayout';
      id: string;
      owner: string;
      json: string;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
  } | null;
};

export type DeleteDashboardModuleMutationVariables = {
  input?: DeleteDashboardModuleInput;
  condition?: ModelDashboardModuleConditionInput | null;
};

export type DeleteDashboardModuleMutation = {
  deleteDashboardModule?: {
    __typename: 'DashboardModule';
    id: string;
    owner: string;
    dashboardId: string;
    error?: {
      __typename: 'DashboardModuleError';
      code?: number | null;
      message: string;
      status?: string | null;
      transactionId?: string | null;
    } | null;
    sequence?: number | null;
    title?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    dashboard: {
      __typename: 'Dashboard';
      id: string;
      owner: string;
      customScriptId?: string | null;
      defaultScript?: string | null;
      error?: {
        __typename: 'DashboardError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        scriptError?: boolean | null;
      } | null;
      output_logs?: string | null;
      lastTransactionId?: string | null;
      maxFiles?: number | null;
      status: DashboardStatus;
      tags: Array<string>;
      file_ids?: Array<string> | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customScript?: {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        lines: Array<string>;
        status?: CustomScriptStatus | null;
        title?: string | null;
        type: CustomScriptType;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      notes?: string | null;
      sequence?: number | null;
      modules?: {
        __typename: 'ModelDashboardModuleConnection';
        nextToken?: string | null;
      } | null;
      shares?: {
        __typename: 'ModelDashboardShareConnection';
        nextToken?: string | null;
      } | null;
    };
    data?: {
      __typename: 'ModelDashboardModuleDataConnection';
      items?: Array<{
        __typename: 'DashboardModuleData';
        id: string;
        owner: string;
        moduleId: string;
        json: string;
        sequence?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    frames?: {
      __typename: 'ModelDashboardModuleFrameConnection';
      items?: Array<{
        __typename: 'DashboardModuleFrame';
        id: string;
        owner: string;
        moduleId: string;
        json: string;
        sequence: number;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    layout?: {
      __typename: 'DashboardModuleLayout';
      id: string;
      owner: string;
      json: string;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
  } | null;
};

export type CreateDashboardModuleDataMutationVariables = {
  input?: CreateDashboardModuleDataInput;
  condition?: ModelDashboardModuleDataConditionInput | null;
};

export type CreateDashboardModuleDataMutation = {
  createDashboardModuleData?: {
    __typename: 'DashboardModuleData';
    id: string;
    owner: string;
    moduleId: string;
    json: string;
    sequence?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    module: {
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type UpdateDashboardModuleDataMutationVariables = {
  input?: UpdateDashboardModuleDataInput;
  condition?: ModelDashboardModuleDataConditionInput | null;
};

export type UpdateDashboardModuleDataMutation = {
  updateDashboardModuleData?: {
    __typename: 'DashboardModuleData';
    id: string;
    owner: string;
    moduleId: string;
    json: string;
    sequence?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    module: {
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type DeleteDashboardModuleDataMutationVariables = {
  input?: DeleteDashboardModuleDataInput;
  condition?: ModelDashboardModuleDataConditionInput | null;
};

export type DeleteDashboardModuleDataMutation = {
  deleteDashboardModuleData?: {
    __typename: 'DashboardModuleData';
    id: string;
    owner: string;
    moduleId: string;
    json: string;
    sequence?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    module: {
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type CreateDashboardModuleFrameMutationVariables = {
  input?: CreateDashboardModuleFrameInput;
  condition?: ModelDashboardModuleFrameConditionInput | null;
};

export type CreateDashboardModuleFrameMutation = {
  createDashboardModuleFrame?: {
    __typename: 'DashboardModuleFrame';
    id: string;
    owner: string;
    moduleId: string;
    json: string;
    sequence: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    module: {
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type UpdateDashboardModuleFrameMutationVariables = {
  input?: UpdateDashboardModuleFrameInput;
  condition?: ModelDashboardModuleFrameConditionInput | null;
};

export type UpdateDashboardModuleFrameMutation = {
  updateDashboardModuleFrame?: {
    __typename: 'DashboardModuleFrame';
    id: string;
    owner: string;
    moduleId: string;
    json: string;
    sequence: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    module: {
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type DeleteDashboardModuleFrameMutationVariables = {
  input?: DeleteDashboardModuleFrameInput;
  condition?: ModelDashboardModuleFrameConditionInput | null;
};

export type DeleteDashboardModuleFrameMutation = {
  deleteDashboardModuleFrame?: {
    __typename: 'DashboardModuleFrame';
    id: string;
    owner: string;
    moduleId: string;
    json: string;
    sequence: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    module: {
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type CreateDashboardModuleLayoutMutationVariables = {
  input?: CreateDashboardModuleLayoutInput;
  condition?: ModelDashboardModuleLayoutConditionInput | null;
};

export type CreateDashboardModuleLayoutMutation = {
  createDashboardModuleLayout?: {
    __typename: 'DashboardModuleLayout';
    id: string;
    owner: string;
    json: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateDashboardModuleLayoutMutationVariables = {
  input?: UpdateDashboardModuleLayoutInput;
  condition?: ModelDashboardModuleLayoutConditionInput | null;
};

export type UpdateDashboardModuleLayoutMutation = {
  updateDashboardModuleLayout?: {
    __typename: 'DashboardModuleLayout';
    id: string;
    owner: string;
    json: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteDashboardModuleLayoutMutationVariables = {
  input?: DeleteDashboardModuleLayoutInput;
  condition?: ModelDashboardModuleLayoutConditionInput | null;
};

export type DeleteDashboardModuleLayoutMutation = {
  deleteDashboardModuleLayout?: {
    __typename: 'DashboardModuleLayout';
    id: string;
    owner: string;
    json: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateDashboardShareMutationVariables = {
  input?: CreateDashboardShareInput;
  condition?: ModelDashboardShareConditionInput | null;
};

export type CreateDashboardShareMutation = {
  createDashboardShare?: {
    __typename: 'DashboardShare';
    id: string;
    dashboardId: string;
    token: string;
    expires?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    owner: string;
    dashboard?: {
      __typename: 'Dashboard';
      id: string;
      owner: string;
      customScriptId?: string | null;
      defaultScript?: string | null;
      error?: {
        __typename: 'DashboardError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        scriptError?: boolean | null;
      } | null;
      output_logs?: string | null;
      lastTransactionId?: string | null;
      maxFiles?: number | null;
      status: DashboardStatus;
      tags: Array<string>;
      file_ids?: Array<string> | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customScript?: {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        lines: Array<string>;
        status?: CustomScriptStatus | null;
        title?: string | null;
        type: CustomScriptType;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      notes?: string | null;
      sequence?: number | null;
      modules?: {
        __typename: 'ModelDashboardModuleConnection';
        nextToken?: string | null;
      } | null;
      shares?: {
        __typename: 'ModelDashboardShareConnection';
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateDashboardShareMutationVariables = {
  input?: UpdateDashboardShareInput;
  condition?: ModelDashboardShareConditionInput | null;
};

export type UpdateDashboardShareMutation = {
  updateDashboardShare?: {
    __typename: 'DashboardShare';
    id: string;
    dashboardId: string;
    token: string;
    expires?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    owner: string;
    dashboard?: {
      __typename: 'Dashboard';
      id: string;
      owner: string;
      customScriptId?: string | null;
      defaultScript?: string | null;
      error?: {
        __typename: 'DashboardError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        scriptError?: boolean | null;
      } | null;
      output_logs?: string | null;
      lastTransactionId?: string | null;
      maxFiles?: number | null;
      status: DashboardStatus;
      tags: Array<string>;
      file_ids?: Array<string> | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customScript?: {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        lines: Array<string>;
        status?: CustomScriptStatus | null;
        title?: string | null;
        type: CustomScriptType;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      notes?: string | null;
      sequence?: number | null;
      modules?: {
        __typename: 'ModelDashboardModuleConnection';
        nextToken?: string | null;
      } | null;
      shares?: {
        __typename: 'ModelDashboardShareConnection';
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteDashboardShareMutationVariables = {
  input?: DeleteDashboardShareInput;
  condition?: ModelDashboardShareConditionInput | null;
};

export type DeleteDashboardShareMutation = {
  deleteDashboardShare?: {
    __typename: 'DashboardShare';
    id: string;
    dashboardId: string;
    token: string;
    expires?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    owner: string;
    dashboard?: {
      __typename: 'Dashboard';
      id: string;
      owner: string;
      customScriptId?: string | null;
      defaultScript?: string | null;
      error?: {
        __typename: 'DashboardError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        scriptError?: boolean | null;
      } | null;
      output_logs?: string | null;
      lastTransactionId?: string | null;
      maxFiles?: number | null;
      status: DashboardStatus;
      tags: Array<string>;
      file_ids?: Array<string> | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customScript?: {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        lines: Array<string>;
        status?: CustomScriptStatus | null;
        title?: string | null;
        type: CustomScriptType;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      notes?: string | null;
      sequence?: number | null;
      modules?: {
        __typename: 'ModelDashboardModuleConnection';
        nextToken?: string | null;
      } | null;
      shares?: {
        __typename: 'ModelDashboardShareConnection';
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateDeviceMutationVariables = {
  input?: CreateDeviceInput;
  condition?: ModelDeviceConditionInput | null;
};

export type CreateDeviceMutation = {
  createDevice?: {
    __typename: 'Device';
    serial_number_id: string;
    name?: string | null;
    description?: string | null;
    part_number?: string | null;
    hardware_revision?: string | null;
    firmware_revision?: string | null;
    last_calibration_ts?: number | null;
    thing_name?: string | null;
    thing_arn?: string | null;
    cert_arn?: string | null;
    manufacture_date?: string | null;
    last_uploaded_ts?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    associations?: {
      __typename: 'ModelDeviceAssociationConnection';
      items?: Array<{
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateDeviceMutationVariables = {
  input?: UpdateDeviceInput;
  condition?: ModelDeviceConditionInput | null;
};

export type UpdateDeviceMutation = {
  updateDevice?: {
    __typename: 'Device';
    serial_number_id: string;
    name?: string | null;
    description?: string | null;
    part_number?: string | null;
    hardware_revision?: string | null;
    firmware_revision?: string | null;
    last_calibration_ts?: number | null;
    thing_name?: string | null;
    thing_arn?: string | null;
    cert_arn?: string | null;
    manufacture_date?: string | null;
    last_uploaded_ts?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    associations?: {
      __typename: 'ModelDeviceAssociationConnection';
      items?: Array<{
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteDeviceMutationVariables = {
  input?: DeleteDeviceInput;
  condition?: ModelDeviceConditionInput | null;
};

export type DeleteDeviceMutation = {
  deleteDevice?: {
    __typename: 'Device';
    serial_number_id: string;
    name?: string | null;
    description?: string | null;
    part_number?: string | null;
    hardware_revision?: string | null;
    firmware_revision?: string | null;
    last_calibration_ts?: number | null;
    thing_name?: string | null;
    thing_arn?: string | null;
    cert_arn?: string | null;
    manufacture_date?: string | null;
    last_uploaded_ts?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    associations?: {
      __typename: 'ModelDeviceAssociationConnection';
      items?: Array<{
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateDeviceAssociationMutationVariables = {
  input?: CreateDeviceAssociationInput;
  condition?: ModelDeviceAssociationConditionInput | null;
};

export type CreateDeviceAssociationMutation = {
  createDeviceAssociation?: {
    __typename: 'DeviceAssociation';
    id: string;
    org_id?: string | null;
    s3_bucket_name?: string | null;
    serial_number_id: string;
    status?: DeviceAssociationStatus | null;
    user_id: string;
    customerId: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer?: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null;
    device: {
      __typename: 'Device';
      serial_number_id: string;
      name?: string | null;
      description?: string | null;
      part_number?: string | null;
      hardware_revision?: string | null;
      firmware_revision?: string | null;
      last_calibration_ts?: number | null;
      thing_name?: string | null;
      thing_arn?: string | null;
      cert_arn?: string | null;
      manufacture_date?: string | null;
      last_uploaded_ts?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      associations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
    };
    files?: {
      __typename: 'ModelFileConnection';
      items?: Array<{
        __typename: 'File';
        id: string;
        user_id: string;
        serial_number_id?: string | null;
        sensor_name?: string | null;
        sensor_type?: string | null;
        file_name?: string | null;
        file_size?: number | null;
        s3_bucket_name?: string | null;
        s3_object_key: string;
        device_meta?: string | null;
        recording_length?: number | null;
        recording_ts?: number | null;
        created_ts?: number | null;
        modified_ts?: number | null;
        archived_ts?: number | null;
        file_uploaded: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateDeviceAssociationMutationVariables = {
  input?: UpdateDeviceAssociationInput;
  condition?: ModelDeviceAssociationConditionInput | null;
};

export type UpdateDeviceAssociationMutation = {
  updateDeviceAssociation?: {
    __typename: 'DeviceAssociation';
    id: string;
    org_id?: string | null;
    s3_bucket_name?: string | null;
    serial_number_id: string;
    status?: DeviceAssociationStatus | null;
    user_id: string;
    customerId: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer?: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null;
    device: {
      __typename: 'Device';
      serial_number_id: string;
      name?: string | null;
      description?: string | null;
      part_number?: string | null;
      hardware_revision?: string | null;
      firmware_revision?: string | null;
      last_calibration_ts?: number | null;
      thing_name?: string | null;
      thing_arn?: string | null;
      cert_arn?: string | null;
      manufacture_date?: string | null;
      last_uploaded_ts?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      associations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
    };
    files?: {
      __typename: 'ModelFileConnection';
      items?: Array<{
        __typename: 'File';
        id: string;
        user_id: string;
        serial_number_id?: string | null;
        sensor_name?: string | null;
        sensor_type?: string | null;
        file_name?: string | null;
        file_size?: number | null;
        s3_bucket_name?: string | null;
        s3_object_key: string;
        device_meta?: string | null;
        recording_length?: number | null;
        recording_ts?: number | null;
        created_ts?: number | null;
        modified_ts?: number | null;
        archived_ts?: number | null;
        file_uploaded: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteDeviceAssociationMutationVariables = {
  input?: DeleteDeviceAssociationInput;
  condition?: ModelDeviceAssociationConditionInput | null;
};

export type DeleteDeviceAssociationMutation = {
  deleteDeviceAssociation?: {
    __typename: 'DeviceAssociation';
    id: string;
    org_id?: string | null;
    s3_bucket_name?: string | null;
    serial_number_id: string;
    status?: DeviceAssociationStatus | null;
    user_id: string;
    customerId: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer?: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null;
    device: {
      __typename: 'Device';
      serial_number_id: string;
      name?: string | null;
      description?: string | null;
      part_number?: string | null;
      hardware_revision?: string | null;
      firmware_revision?: string | null;
      last_calibration_ts?: number | null;
      thing_name?: string | null;
      thing_arn?: string | null;
      cert_arn?: string | null;
      manufacture_date?: string | null;
      last_uploaded_ts?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      associations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
    };
    files?: {
      __typename: 'ModelFileConnection';
      items?: Array<{
        __typename: 'File';
        id: string;
        user_id: string;
        serial_number_id?: string | null;
        sensor_name?: string | null;
        sensor_type?: string | null;
        file_name?: string | null;
        file_size?: number | null;
        s3_bucket_name?: string | null;
        s3_object_key: string;
        device_meta?: string | null;
        recording_length?: number | null;
        recording_ts?: number | null;
        created_ts?: number | null;
        modified_ts?: number | null;
        archived_ts?: number | null;
        file_uploaded: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateFileMutationVariables = {
  input?: CreateFileInput;
  condition?: ModelFileConditionInput | null;
};

export type CreateFileMutation = {
  createFile?: {
    __typename: 'File';
    id: string;
    user_id: string;
    serial_number_id?: string | null;
    sensor_name?: string | null;
    sensor_type?: string | null;
    file_name?: string | null;
    file_size?: number | null;
    s3_bucket_name?: string | null;
    s3_object_key: string;
    device_meta?: string | null;
    recording_length?: number | null;
    recording_ts?: number | null;
    created_ts?: number | null;
    modified_ts?: number | null;
    archived_ts?: number | null;
    file_uploaded: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
    attributes?: {
      __typename: 'ModelAttributeConnection';
      items?: Array<{
        __typename: 'Attribute';
        id: string;
        user_id: string;
        attributeFileId: string;
        name: string;
        type: string;
        value: string;
        thing?: string | null;
        mutable: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    device?: {
      __typename: 'DeviceAssociation';
      id: string;
      org_id?: string | null;
      s3_bucket_name?: string | null;
      serial_number_id: string;
      status?: DeviceAssociationStatus | null;
      user_id: string;
      customerId: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer?: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      } | null;
      device: {
        __typename: 'Device';
        serial_number_id: string;
        name?: string | null;
        description?: string | null;
        part_number?: string | null;
        hardware_revision?: string | null;
        firmware_revision?: string | null;
        last_calibration_ts?: number | null;
        thing_name?: string | null;
        thing_arn?: string | null;
        cert_arn?: string | null;
        manufacture_date?: string | null;
        last_uploaded_ts?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      files?: {
        __typename: 'ModelFileConnection';
        nextToken?: string | null;
      } | null;
    } | null;
    tags?: {
      __typename: 'ModelTagConnection';
      items?: Array<{
        __typename: 'Tag';
        id: string;
        user_id: string;
        tagFileId: string;
        value: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateFileMutationVariables = {
  input?: UpdateFileInput;
  condition?: ModelFileConditionInput | null;
};

export type UpdateFileMutation = {
  updateFile?: {
    __typename: 'File';
    id: string;
    user_id: string;
    serial_number_id?: string | null;
    sensor_name?: string | null;
    sensor_type?: string | null;
    file_name?: string | null;
    file_size?: number | null;
    s3_bucket_name?: string | null;
    s3_object_key: string;
    device_meta?: string | null;
    recording_length?: number | null;
    recording_ts?: number | null;
    created_ts?: number | null;
    modified_ts?: number | null;
    archived_ts?: number | null;
    file_uploaded: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
    attributes?: {
      __typename: 'ModelAttributeConnection';
      items?: Array<{
        __typename: 'Attribute';
        id: string;
        user_id: string;
        attributeFileId: string;
        name: string;
        type: string;
        value: string;
        thing?: string | null;
        mutable: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    device?: {
      __typename: 'DeviceAssociation';
      id: string;
      org_id?: string | null;
      s3_bucket_name?: string | null;
      serial_number_id: string;
      status?: DeviceAssociationStatus | null;
      user_id: string;
      customerId: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer?: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      } | null;
      device: {
        __typename: 'Device';
        serial_number_id: string;
        name?: string | null;
        description?: string | null;
        part_number?: string | null;
        hardware_revision?: string | null;
        firmware_revision?: string | null;
        last_calibration_ts?: number | null;
        thing_name?: string | null;
        thing_arn?: string | null;
        cert_arn?: string | null;
        manufacture_date?: string | null;
        last_uploaded_ts?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      files?: {
        __typename: 'ModelFileConnection';
        nextToken?: string | null;
      } | null;
    } | null;
    tags?: {
      __typename: 'ModelTagConnection';
      items?: Array<{
        __typename: 'Tag';
        id: string;
        user_id: string;
        tagFileId: string;
        value: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteFileMutationVariables = {
  input?: DeleteFileInput;
  condition?: ModelFileConditionInput | null;
};

export type DeleteFileMutation = {
  deleteFile?: {
    __typename: 'File';
    id: string;
    user_id: string;
    serial_number_id?: string | null;
    sensor_name?: string | null;
    sensor_type?: string | null;
    file_name?: string | null;
    file_size?: number | null;
    s3_bucket_name?: string | null;
    s3_object_key: string;
    device_meta?: string | null;
    recording_length?: number | null;
    recording_ts?: number | null;
    created_ts?: number | null;
    modified_ts?: number | null;
    archived_ts?: number | null;
    file_uploaded: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
    attributes?: {
      __typename: 'ModelAttributeConnection';
      items?: Array<{
        __typename: 'Attribute';
        id: string;
        user_id: string;
        attributeFileId: string;
        name: string;
        type: string;
        value: string;
        thing?: string | null;
        mutable: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    device?: {
      __typename: 'DeviceAssociation';
      id: string;
      org_id?: string | null;
      s3_bucket_name?: string | null;
      serial_number_id: string;
      status?: DeviceAssociationStatus | null;
      user_id: string;
      customerId: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer?: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      } | null;
      device: {
        __typename: 'Device';
        serial_number_id: string;
        name?: string | null;
        description?: string | null;
        part_number?: string | null;
        hardware_revision?: string | null;
        firmware_revision?: string | null;
        last_calibration_ts?: number | null;
        thing_name?: string | null;
        thing_arn?: string | null;
        cert_arn?: string | null;
        manufacture_date?: string | null;
        last_uploaded_ts?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      files?: {
        __typename: 'ModelFileConnection';
        nextToken?: string | null;
      } | null;
    } | null;
    tags?: {
      __typename: 'ModelTagConnection';
      items?: Array<{
        __typename: 'Tag';
        id: string;
        user_id: string;
        tagFileId: string;
        value: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateStripePlanMutationVariables = {
  input?: CreateStripePlanInput;
  condition?: ModelStripePlanConditionInput | null;
};

export type CreateStripePlanMutation = {
  createStripePlan?: {
    __typename: 'StripePlan';
    id: StripePlanId;
    tierId: TierName;
    billingCycle?: BillingCycle | null;
    cost?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    tier: {
      __typename: 'Tier';
      id: TierName;
      costMonthly?: number | null;
      costYearly?: number | null;
      deviceMaxCount?: number | null;
      storageLimitGb?: number | null;
      alertMaxCount?: number | null;
      hasApiAccess?: boolean | null;
      billingCycles?: Array<BillingCycle | null> | null;
      reportMaxCount?: number | null;
      reportTemplate?: ReportTemplate | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stripePlans?: {
        __typename: 'ModelStripePlanConnection';
        nextToken?: string | null;
      } | null;
    };
  } | null;
};

export type UpdateStripePlanMutationVariables = {
  input?: UpdateStripePlanInput;
  condition?: ModelStripePlanConditionInput | null;
};

export type UpdateStripePlanMutation = {
  updateStripePlan?: {
    __typename: 'StripePlan';
    id: StripePlanId;
    tierId: TierName;
    billingCycle?: BillingCycle | null;
    cost?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    tier: {
      __typename: 'Tier';
      id: TierName;
      costMonthly?: number | null;
      costYearly?: number | null;
      deviceMaxCount?: number | null;
      storageLimitGb?: number | null;
      alertMaxCount?: number | null;
      hasApiAccess?: boolean | null;
      billingCycles?: Array<BillingCycle | null> | null;
      reportMaxCount?: number | null;
      reportTemplate?: ReportTemplate | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stripePlans?: {
        __typename: 'ModelStripePlanConnection';
        nextToken?: string | null;
      } | null;
    };
  } | null;
};

export type DeleteStripePlanMutationVariables = {
  input?: DeleteStripePlanInput;
  condition?: ModelStripePlanConditionInput | null;
};

export type DeleteStripePlanMutation = {
  deleteStripePlan?: {
    __typename: 'StripePlan';
    id: StripePlanId;
    tierId: TierName;
    billingCycle?: BillingCycle | null;
    cost?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    tier: {
      __typename: 'Tier';
      id: TierName;
      costMonthly?: number | null;
      costYearly?: number | null;
      deviceMaxCount?: number | null;
      storageLimitGb?: number | null;
      alertMaxCount?: number | null;
      hasApiAccess?: boolean | null;
      billingCycles?: Array<BillingCycle | null> | null;
      reportMaxCount?: number | null;
      reportTemplate?: ReportTemplate | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stripePlans?: {
        __typename: 'ModelStripePlanConnection';
        nextToken?: string | null;
      } | null;
    };
  } | null;
};

export type CreateTagMutationVariables = {
  input?: CreateTagInput;
  condition?: ModelTagConditionInput | null;
};

export type CreateTagMutation = {
  createTag?: {
    __typename: 'Tag';
    id: string;
    user_id: string;
    tagFileId: string;
    value: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateTagMutationVariables = {
  input?: UpdateTagInput;
  condition?: ModelTagConditionInput | null;
};

export type UpdateTagMutation = {
  updateTag?: {
    __typename: 'Tag';
    id: string;
    user_id: string;
    tagFileId: string;
    value: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteTagMutationVariables = {
  input?: DeleteTagInput;
  condition?: ModelTagConditionInput | null;
};

export type DeleteTagMutation = {
  deleteTag?: {
    __typename: 'Tag';
    id: string;
    user_id: string;
    tagFileId: string;
    value: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateTierMutationVariables = {
  input?: CreateTierInput;
  condition?: ModelTierConditionInput | null;
};

export type CreateTierMutation = {
  createTier?: {
    __typename: 'Tier';
    id: TierName;
    costMonthly?: number | null;
    costYearly?: number | null;
    deviceMaxCount?: number | null;
    storageLimitGb?: number | null;
    alertMaxCount?: number | null;
    hasApiAccess?: boolean | null;
    billingCycles?: Array<BillingCycle | null> | null;
    reportMaxCount?: number | null;
    reportTemplate?: ReportTemplate | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    stripePlans?: {
      __typename: 'ModelStripePlanConnection';
      items?: Array<{
        __typename: 'StripePlan';
        id: StripePlanId;
        tierId: TierName;
        billingCycle?: BillingCycle | null;
        cost?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateTierMutationVariables = {
  input?: UpdateTierInput;
  condition?: ModelTierConditionInput | null;
};

export type UpdateTierMutation = {
  updateTier?: {
    __typename: 'Tier';
    id: TierName;
    costMonthly?: number | null;
    costYearly?: number | null;
    deviceMaxCount?: number | null;
    storageLimitGb?: number | null;
    alertMaxCount?: number | null;
    hasApiAccess?: boolean | null;
    billingCycles?: Array<BillingCycle | null> | null;
    reportMaxCount?: number | null;
    reportTemplate?: ReportTemplate | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    stripePlans?: {
      __typename: 'ModelStripePlanConnection';
      items?: Array<{
        __typename: 'StripePlan';
        id: StripePlanId;
        tierId: TierName;
        billingCycle?: BillingCycle | null;
        cost?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteTierMutationVariables = {
  input?: DeleteTierInput;
  condition?: ModelTierConditionInput | null;
};

export type DeleteTierMutation = {
  deleteTier?: {
    __typename: 'Tier';
    id: TierName;
    costMonthly?: number | null;
    costYearly?: number | null;
    deviceMaxCount?: number | null;
    storageLimitGb?: number | null;
    alertMaxCount?: number | null;
    hasApiAccess?: boolean | null;
    billingCycles?: Array<BillingCycle | null> | null;
    reportMaxCount?: number | null;
    reportTemplate?: ReportTemplate | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    stripePlans?: {
      __typename: 'ModelStripePlanConnection';
      items?: Array<{
        __typename: 'StripePlan';
        id: StripePlanId;
        tierId: TierName;
        billingCycle?: BillingCycle | null;
        cost?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type GetUniqueTagListQuery = {
  getUniqueTagList?: Array<{
    __typename: 'TagType';
    value: string;
  } | null> | null;
};

export type GetFilesQueryVariables = {
  sortingField?: string | null;
  sortDirection?: SortDirection | null;
  numberSort?: boolean | null;
  tags?: Array<string | null> | null;
  limit?: number | null;
  nextCursor?: string | null;
};

export type GetFilesQuery = {
  getFiles?: {
    __typename: 'FileType';
    nextCursor?: string | null;
    totalCount?: number | null;
    files: Array<{
      __typename: 'File';
      id: string;
      user_id: string;
      serial_number_id?: string | null;
      sensor_name?: string | null;
      sensor_type?: string | null;
      file_name?: string | null;
      file_size?: number | null;
      s3_bucket_name?: string | null;
      s3_object_key: string;
      device_meta?: string | null;
      recording_length?: number | null;
      recording_ts?: number | null;
      created_ts?: number | null;
      modified_ts?: number | null;
      archived_ts?: number | null;
      file_uploaded: boolean;
      createdAt?: string | null;
      updatedAt?: string | null;
      attributes?: {
        __typename: 'ModelAttributeConnection';
        nextToken?: string | null;
      } | null;
      device?: {
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      tags?: {
        __typename: 'ModelTagConnection';
        nextToken?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAlertQueryVariables = {
  id?: string;
};

export type GetAlertQuery = {
  getAlert?: {
    __typename: 'Alert';
    id: string;
    owner: string;
    customerId: string;
    name?: string | null;
    tagName: string;
    enabled?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer?: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListAlertsQueryVariables = {
  filter?: ModelAlertFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListAlertsQuery = {
  listAlerts?: {
    __typename: 'ModelAlertConnection';
    items?: Array<{
      __typename: 'Alert';
      id: string;
      owner: string;
      customerId: string;
      name?: string | null;
      tagName: string;
      enabled?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer?: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetApiKeyQueryVariables = {
  id?: string;
};

export type GetApiKeyQuery = {
  getApiKey?: {
    __typename: 'ApiKey';
    id: string;
    name?: string | null;
    owner: string;
    revoked: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListApiKeysQueryVariables = {
  filter?: ModelApiKeyFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListApiKeysQuery = {
  listApiKeys?: {
    __typename: 'ModelApiKeyConnection';
    items?: Array<{
      __typename: 'ApiKey';
      id: string;
      name?: string | null;
      owner: string;
      revoked: boolean;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetApiKeyByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelApiKeyFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetApiKeyByOwnerQuery = {
  getApiKeyByOwner?: {
    __typename: 'ModelApiKeyConnection';
    items?: Array<{
      __typename: 'ApiKey';
      id: string;
      name?: string | null;
      owner: string;
      revoked: boolean;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetAttributeQueryVariables = {
  id?: string;
};

export type GetAttributeQuery = {
  getAttribute?: {
    __typename: 'Attribute';
    id: string;
    user_id: string;
    attributeFileId: string;
    name: string;
    type: string;
    value: string;
    thing?: string | null;
    mutable: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListAttributesQueryVariables = {
  filter?: ModelAttributeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListAttributesQuery = {
  listAttributes?: {
    __typename: 'ModelAttributeConnection';
    items?: Array<{
      __typename: 'Attribute';
      id: string;
      user_id: string;
      attributeFileId: string;
      name: string;
      type: string;
      value: string;
      thing?: string | null;
      mutable: boolean;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetAttributeByUserQueryVariables = {
  user_id?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAttributeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetAttributeByUserQuery = {
  getAttributeByUser?: {
    __typename: 'ModelAttributeConnection';
    items?: Array<{
      __typename: 'Attribute';
      id: string;
      user_id: string;
      attributeFileId: string;
      name: string;
      type: string;
      value: string;
      thing?: string | null;
      mutable: boolean;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type ListBillingsQueryVariables = {
  filter?: ModelBillingFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListBillingsQuery = {
  listBillings?: {
    __typename: 'ModelBillingConnection';
    items?: Array<{
      __typename: 'Billing';
      id: string;
      stripe_session_id: string;
      owner: string;
      stripe_customer_id: string;
      stripe_subscription_id?: string | null;
      subscription_status: BillingSubscriptionStatus;
      stripe_plan_id: StripePlanId;
      suspended_at?: number | null;
      next_billing_at?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      };
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetBillingQueryVariables = {
  id?: string;
};

export type GetBillingQuery = {
  getBilling?: {
    __typename: 'Billing';
    id: string;
    stripe_session_id: string;
    owner: string;
    stripe_customer_id: string;
    stripe_subscription_id?: string | null;
    subscription_status: BillingSubscriptionStatus;
    stripe_plan_id: StripePlanId;
    suspended_at?: number | null;
    next_billing_at?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type GetBillingByStripeSessionIdQueryVariables = {
  stripe_session_id?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelBillingFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetBillingByStripeSessionIdQuery = {
  getBillingByStripeSessionId?: {
    __typename: 'ModelBillingConnection';
    items?: Array<{
      __typename: 'Billing';
      id: string;
      stripe_session_id: string;
      owner: string;
      stripe_customer_id: string;
      stripe_subscription_id?: string | null;
      subscription_status: BillingSubscriptionStatus;
      stripe_plan_id: StripePlanId;
      suspended_at?: number | null;
      next_billing_at?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      };
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetBillingByStripeCustomerIdQueryVariables = {
  stripe_customer_id?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelBillingFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetBillingByStripeCustomerIdQuery = {
  getBillingByStripeCustomerId?: {
    __typename: 'ModelBillingConnection';
    items?: Array<{
      __typename: 'Billing';
      id: string;
      stripe_session_id: string;
      owner: string;
      stripe_customer_id: string;
      stripe_subscription_id?: string | null;
      subscription_status: BillingSubscriptionStatus;
      stripe_plan_id: StripePlanId;
      suspended_at?: number | null;
      next_billing_at?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      };
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetBillingByStripeSubscriptionIdQueryVariables = {
  stripe_subscription_id?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelBillingFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetBillingByStripeSubscriptionIdQuery = {
  getBillingByStripeSubscriptionId?: {
    __typename: 'ModelBillingConnection';
    items?: Array<{
      __typename: 'Billing';
      id: string;
      stripe_session_id: string;
      owner: string;
      stripe_customer_id: string;
      stripe_subscription_id?: string | null;
      subscription_status: BillingSubscriptionStatus;
      stripe_plan_id: StripePlanId;
      suspended_at?: number | null;
      next_billing_at?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      };
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetBillingByStripeOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelBillingFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetBillingByStripeOwnerQuery = {
  getBillingByStripeOwner?: {
    __typename: 'ModelBillingConnection';
    items?: Array<{
      __typename: 'Billing';
      id: string;
      stripe_session_id: string;
      owner: string;
      stripe_customer_id: string;
      stripe_subscription_id?: string | null;
      subscription_status: BillingSubscriptionStatus;
      stripe_plan_id: StripePlanId;
      suspended_at?: number | null;
      next_billing_at?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      };
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type ListCustomScriptsQueryVariables = {
  filter?: ModelCustomScriptFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCustomScriptsQuery = {
  listCustomScripts?: {
    __typename: 'ModelCustomScriptConnection';
    items?: Array<{
      __typename: 'CustomScript';
      id: string;
      owner: string;
      error?: {
        __typename: 'CustomScriptError';
        code?: number | null;
        detail: string;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        type: string;
      } | null;
      lines: Array<string>;
      status?: CustomScriptStatus | null;
      title?: string | null;
      type: CustomScriptType;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetCustomScriptQueryVariables = {
  id?: string;
};

export type GetCustomScriptQuery = {
  getCustomScript?: {
    __typename: 'CustomScript';
    id: string;
    owner: string;
    error?: {
      __typename: 'CustomScriptError';
      code?: number | null;
      detail: string;
      message: string;
      status?: string | null;
      traces: Array<{
        __typename: 'StackTrace';
        filename: string;
        lineno: string;
        name?: string | null;
        offset?: number | null;
        text?: string | null;
      }>;
      transactionId?: string | null;
      type: string;
    } | null;
    lines: Array<string>;
    status?: CustomScriptStatus | null;
    title?: string | null;
    type: CustomScriptType;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type GetCustomScriptByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCustomScriptFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetCustomScriptByOwnerQuery = {
  getCustomScriptByOwner?: {
    __typename: 'ModelCustomScriptConnection';
    items?: Array<{
      __typename: 'CustomScript';
      id: string;
      owner: string;
      error?: {
        __typename: 'CustomScriptError';
        code?: number | null;
        detail: string;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        type: string;
      } | null;
      lines: Array<string>;
      status?: CustomScriptStatus | null;
      title?: string | null;
      type: CustomScriptType;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetCustomScriptByTypeQueryVariables = {
  type?: CustomScriptType | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCustomScriptFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetCustomScriptByTypeQuery = {
  getCustomScriptByType?: {
    __typename: 'ModelCustomScriptConnection';
    items?: Array<{
      __typename: 'CustomScript';
      id: string;
      owner: string;
      error?: {
        __typename: 'CustomScriptError';
        code?: number | null;
        detail: string;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        type: string;
      } | null;
      lines: Array<string>;
      status?: CustomScriptStatus | null;
      title?: string | null;
      type: CustomScriptType;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type ListCustomersQueryVariables = {
  filter?: ModelCustomerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCustomersQuery = {
  listCustomers?: {
    __typename: 'ModelCustomerConnection';
    items?: Array<{
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetCustomerQueryVariables = {
  id?: string;
};

export type GetCustomerQuery = {
  getCustomer?: {
    __typename: 'Customer';
    id: string;
    owner: string;
    storageConsumed?: number | null;
    storageSpaceRemaining?: boolean | null;
    tierId: TierName;
    createdAt?: string | null;
    updatedAt?: string | null;
    companyName?: string | null;
    logoImage?: string | null;
    apiKeys?: {
      __typename: 'ModelApiKeyConnection';
      items?: Array<{
        __typename: 'ApiKey';
        id: string;
        name?: string | null;
        owner: string;
        revoked: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    billing?: {
      __typename: 'Billing';
      id: string;
      stripe_session_id: string;
      owner: string;
      stripe_customer_id: string;
      stripe_subscription_id?: string | null;
      subscription_status: BillingSubscriptionStatus;
      stripe_plan_id: StripePlanId;
      suspended_at?: number | null;
      next_billing_at?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      };
    } | null;
    deviceAssociations?: {
      __typename: 'ModelDeviceAssociationConnection';
      items?: Array<{
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    tier?: {
      __typename: 'Tier';
      id: TierName;
      costMonthly?: number | null;
      costYearly?: number | null;
      deviceMaxCount?: number | null;
      storageLimitGb?: number | null;
      alertMaxCount?: number | null;
      hasApiAccess?: boolean | null;
      billingCycles?: Array<BillingCycle | null> | null;
      reportMaxCount?: number | null;
      reportTemplate?: ReportTemplate | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stripePlans?: {
        __typename: 'ModelStripePlanConnection';
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCustomerByOwnerIdQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCustomerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetCustomerByOwnerIdQuery = {
  getCustomerByOwnerId?: {
    __typename: 'ModelCustomerConnection';
    items?: Array<{
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDashboardByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelDashboardFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetDashboardByOwnerQuery = {
  getDashboardByOwner?: {
    __typename: 'ModelDashboardConnection';
    items?: Array<{
      __typename: 'Dashboard';
      id: string;
      owner: string;
      customScriptId?: string | null;
      defaultScript?: string | null;
      error?: {
        __typename: 'DashboardError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        scriptError?: boolean | null;
      } | null;
      output_logs?: string | null;
      lastTransactionId?: string | null;
      maxFiles?: number | null;
      status: DashboardStatus;
      tags: Array<string>;
      file_ids?: Array<string> | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customScript?: {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        lines: Array<string>;
        status?: CustomScriptStatus | null;
        title?: string | null;
        type: CustomScriptType;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      notes?: string | null;
      sequence?: number | null;
      modules?: {
        __typename: 'ModelDashboardModuleConnection';
        nextToken?: string | null;
      } | null;
      shares?: {
        __typename: 'ModelDashboardShareConnection';
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDashboardQueryVariables = {
  id?: string;
};

export type GetDashboardQuery = {
  getDashboard?: {
    __typename: 'Dashboard';
    id: string;
    owner: string;
    customScriptId?: string | null;
    defaultScript?: string | null;
    error?: {
      __typename: 'DashboardError';
      code?: number | null;
      message: string;
      status?: string | null;
      transactionId?: string | null;
      scriptError?: boolean | null;
    } | null;
    output_logs?: string | null;
    lastTransactionId?: string | null;
    maxFiles?: number | null;
    status: DashboardStatus;
    tags: Array<string>;
    file_ids?: Array<string> | null;
    title?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customScript?: {
      __typename: 'CustomScript';
      id: string;
      owner: string;
      error?: {
        __typename: 'CustomScriptError';
        code?: number | null;
        detail: string;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        type: string;
      } | null;
      lines: Array<string>;
      status?: CustomScriptStatus | null;
      title?: string | null;
      type: CustomScriptType;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
    notes?: string | null;
    sequence?: number | null;
    modules?: {
      __typename: 'ModelDashboardModuleConnection';
      items?: Array<{
        __typename: 'DashboardModule';
        id: string;
        owner: string;
        dashboardId: string;
        sequence?: number | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    shares?: {
      __typename: 'ModelDashboardShareConnection';
      items?: Array<{
        __typename: 'DashboardShare';
        id: string;
        dashboardId: string;
        token: string;
        expires?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner: string;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type ListDashboardsQueryVariables = {
  filter?: ModelDashboardFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListDashboardsQuery = {
  listDashboards?: {
    __typename: 'ModelDashboardConnection';
    items?: Array<{
      __typename: 'Dashboard';
      id: string;
      owner: string;
      customScriptId?: string | null;
      defaultScript?: string | null;
      error?: {
        __typename: 'DashboardError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        scriptError?: boolean | null;
      } | null;
      output_logs?: string | null;
      lastTransactionId?: string | null;
      maxFiles?: number | null;
      status: DashboardStatus;
      tags: Array<string>;
      file_ids?: Array<string> | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customScript?: {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        lines: Array<string>;
        status?: CustomScriptStatus | null;
        title?: string | null;
        type: CustomScriptType;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      notes?: string | null;
      sequence?: number | null;
      modules?: {
        __typename: 'ModelDashboardModuleConnection';
        nextToken?: string | null;
      } | null;
      shares?: {
        __typename: 'ModelDashboardShareConnection';
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type ListDashboardModulesQueryVariables = {
  filter?: ModelDashboardModuleFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListDashboardModulesQuery = {
  listDashboardModules?: {
    __typename: 'ModelDashboardModuleConnection';
    items?: Array<{
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDashboardModuleQueryVariables = {
  id?: string;
};

export type GetDashboardModuleQuery = {
  getDashboardModule?: {
    __typename: 'DashboardModule';
    id: string;
    owner: string;
    dashboardId: string;
    error?: {
      __typename: 'DashboardModuleError';
      code?: number | null;
      message: string;
      status?: string | null;
      transactionId?: string | null;
    } | null;
    sequence?: number | null;
    title?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    dashboard: {
      __typename: 'Dashboard';
      id: string;
      owner: string;
      customScriptId?: string | null;
      defaultScript?: string | null;
      error?: {
        __typename: 'DashboardError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        scriptError?: boolean | null;
      } | null;
      output_logs?: string | null;
      lastTransactionId?: string | null;
      maxFiles?: number | null;
      status: DashboardStatus;
      tags: Array<string>;
      file_ids?: Array<string> | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customScript?: {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        lines: Array<string>;
        status?: CustomScriptStatus | null;
        title?: string | null;
        type: CustomScriptType;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      notes?: string | null;
      sequence?: number | null;
      modules?: {
        __typename: 'ModelDashboardModuleConnection';
        nextToken?: string | null;
      } | null;
      shares?: {
        __typename: 'ModelDashboardShareConnection';
        nextToken?: string | null;
      } | null;
    };
    data?: {
      __typename: 'ModelDashboardModuleDataConnection';
      items?: Array<{
        __typename: 'DashboardModuleData';
        id: string;
        owner: string;
        moduleId: string;
        json: string;
        sequence?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    frames?: {
      __typename: 'ModelDashboardModuleFrameConnection';
      items?: Array<{
        __typename: 'DashboardModuleFrame';
        id: string;
        owner: string;
        moduleId: string;
        json: string;
        sequence: number;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    layout?: {
      __typename: 'DashboardModuleLayout';
      id: string;
      owner: string;
      json: string;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
  } | null;
};

export type GetDashboardModuleByDashboardQueryVariables = {
  dashboardId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelDashboardModuleFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetDashboardModuleByDashboardQuery = {
  getDashboardModuleByDashboard?: {
    __typename: 'ModelDashboardModuleConnection';
    items?: Array<{
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDashboardModuleByDashboardSortedQueryVariables = {
  dashboardId?: string | null;
  sequence?: ModelIntKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelDashboardModuleFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetDashboardModuleByDashboardSortedQuery = {
  getDashboardModuleByDashboardSorted?: {
    __typename: 'ModelDashboardModuleConnection';
    items?: Array<{
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDashboardModuleDataQueryVariables = {
  id?: string;
};

export type GetDashboardModuleDataQuery = {
  getDashboardModuleData?: {
    __typename: 'DashboardModuleData';
    id: string;
    owner: string;
    moduleId: string;
    json: string;
    sequence?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    module: {
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type ListDashboardModuleDatasQueryVariables = {
  filter?: ModelDashboardModuleDataFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListDashboardModuleDatasQuery = {
  listDashboardModuleDatas?: {
    __typename: 'ModelDashboardModuleDataConnection';
    items?: Array<{
      __typename: 'DashboardModuleData';
      id: string;
      owner: string;
      moduleId: string;
      json: string;
      sequence?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      module: {
        __typename: 'DashboardModule';
        id: string;
        owner: string;
        dashboardId: string;
        sequence?: number | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDashboardModuleDataByDashboardModuleQueryVariables = {
  moduleId?: string | null;
  sequence?: ModelIntKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelDashboardModuleDataFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetDashboardModuleDataByDashboardModuleQuery = {
  getDashboardModuleDataByDashboardModule?: {
    __typename: 'ModelDashboardModuleDataConnection';
    items?: Array<{
      __typename: 'DashboardModuleData';
      id: string;
      owner: string;
      moduleId: string;
      json: string;
      sequence?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      module: {
        __typename: 'DashboardModule';
        id: string;
        owner: string;
        dashboardId: string;
        sequence?: number | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDashboardModuleFrameQueryVariables = {
  id?: string;
};

export type GetDashboardModuleFrameQuery = {
  getDashboardModuleFrame?: {
    __typename: 'DashboardModuleFrame';
    id: string;
    owner: string;
    moduleId: string;
    json: string;
    sequence: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    module: {
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type ListDashboardModuleFramesQueryVariables = {
  filter?: ModelDashboardModuleFrameFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListDashboardModuleFramesQuery = {
  listDashboardModuleFrames?: {
    __typename: 'ModelDashboardModuleFrameConnection';
    items?: Array<{
      __typename: 'DashboardModuleFrame';
      id: string;
      owner: string;
      moduleId: string;
      json: string;
      sequence: number;
      createdAt?: string | null;
      updatedAt?: string | null;
      module: {
        __typename: 'DashboardModule';
        id: string;
        owner: string;
        dashboardId: string;
        sequence?: number | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDashboardModuleFrameByDashboardModuleQueryVariables = {
  moduleId?: string | null;
  sequence?: ModelIntKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelDashboardModuleFrameFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetDashboardModuleFrameByDashboardModuleQuery = {
  getDashboardModuleFrameByDashboardModule?: {
    __typename: 'ModelDashboardModuleFrameConnection';
    items?: Array<{
      __typename: 'DashboardModuleFrame';
      id: string;
      owner: string;
      moduleId: string;
      json: string;
      sequence: number;
      createdAt?: string | null;
      updatedAt?: string | null;
      module: {
        __typename: 'DashboardModule';
        id: string;
        owner: string;
        dashboardId: string;
        sequence?: number | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type ListDashboardModuleLayoutsQueryVariables = {
  filter?: ModelDashboardModuleLayoutFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListDashboardModuleLayoutsQuery = {
  listDashboardModuleLayouts?: {
    __typename: 'ModelDashboardModuleLayoutConnection';
    items?: Array<{
      __typename: 'DashboardModuleLayout';
      id: string;
      owner: string;
      json: string;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDashboardModuleLayoutQueryVariables = {
  id?: string;
};

export type GetDashboardModuleLayoutQuery = {
  getDashboardModuleLayout?: {
    __typename: 'DashboardModuleLayout';
    id: string;
    owner: string;
    json: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type GetDashboardShareQueryVariables = {
  id?: string;
};

export type GetDashboardShareQuery = {
  getDashboardShare?: {
    __typename: 'DashboardShare';
    id: string;
    dashboardId: string;
    token: string;
    expires?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    owner: string;
    dashboard?: {
      __typename: 'Dashboard';
      id: string;
      owner: string;
      customScriptId?: string | null;
      defaultScript?: string | null;
      error?: {
        __typename: 'DashboardError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        scriptError?: boolean | null;
      } | null;
      output_logs?: string | null;
      lastTransactionId?: string | null;
      maxFiles?: number | null;
      status: DashboardStatus;
      tags: Array<string>;
      file_ids?: Array<string> | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customScript?: {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        lines: Array<string>;
        status?: CustomScriptStatus | null;
        title?: string | null;
        type: CustomScriptType;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      notes?: string | null;
      sequence?: number | null;
      modules?: {
        __typename: 'ModelDashboardModuleConnection';
        nextToken?: string | null;
      } | null;
      shares?: {
        __typename: 'ModelDashboardShareConnection';
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListDashboardSharesQueryVariables = {
  filter?: ModelDashboardShareFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListDashboardSharesQuery = {
  listDashboardShares?: {
    __typename: 'ModelDashboardShareConnection';
    items?: Array<{
      __typename: 'DashboardShare';
      id: string;
      dashboardId: string;
      token: string;
      expires?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      owner: string;
      dashboard?: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDashboardShareByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelDashboardShareFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetDashboardShareByOwnerQuery = {
  getDashboardShareByOwner?: {
    __typename: 'ModelDashboardShareConnection';
    items?: Array<{
      __typename: 'DashboardShare';
      id: string;
      dashboardId: string;
      token: string;
      expires?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      owner: string;
      dashboard?: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDashboardShareByTokenQueryVariables = {
  token?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelDashboardShareFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetDashboardShareByTokenQuery = {
  getDashboardShareByToken?: {
    __typename: 'ModelDashboardShareConnection';
    items?: Array<{
      __typename: 'DashboardShare';
      id: string;
      dashboardId: string;
      token: string;
      expires?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      owner: string;
      dashboard?: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDashboardShareByDashboardQueryVariables = {
  dashboardId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelDashboardShareFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetDashboardShareByDashboardQuery = {
  getDashboardShareByDashboard?: {
    __typename: 'ModelDashboardShareConnection';
    items?: Array<{
      __typename: 'DashboardShare';
      id: string;
      dashboardId: string;
      token: string;
      expires?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      owner: string;
      dashboard?: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type ListDevicesQueryVariables = {
  serial_number_id?: string | null;
  filter?: ModelDeviceFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListDevicesQuery = {
  listDevices?: {
    __typename: 'ModelDeviceConnection';
    items?: Array<{
      __typename: 'Device';
      serial_number_id: string;
      name?: string | null;
      description?: string | null;
      part_number?: string | null;
      hardware_revision?: string | null;
      firmware_revision?: string | null;
      last_calibration_ts?: number | null;
      thing_name?: string | null;
      thing_arn?: string | null;
      cert_arn?: string | null;
      manufacture_date?: string | null;
      last_uploaded_ts?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      associations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDeviceQueryVariables = {
  serial_number_id?: string;
};

export type GetDeviceQuery = {
  getDevice?: {
    __typename: 'Device';
    serial_number_id: string;
    name?: string | null;
    description?: string | null;
    part_number?: string | null;
    hardware_revision?: string | null;
    firmware_revision?: string | null;
    last_calibration_ts?: number | null;
    thing_name?: string | null;
    thing_arn?: string | null;
    cert_arn?: string | null;
    manufacture_date?: string | null;
    last_uploaded_ts?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    associations?: {
      __typename: 'ModelDeviceAssociationConnection';
      items?: Array<{
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type ListDeviceAssociationsQueryVariables = {
  filter?: ModelDeviceAssociationFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListDeviceAssociationsQuery = {
  listDeviceAssociations?: {
    __typename: 'ModelDeviceAssociationConnection';
    items?: Array<{
      __typename: 'DeviceAssociation';
      id: string;
      org_id?: string | null;
      s3_bucket_name?: string | null;
      serial_number_id: string;
      status?: DeviceAssociationStatus | null;
      user_id: string;
      customerId: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer?: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      } | null;
      device: {
        __typename: 'Device';
        serial_number_id: string;
        name?: string | null;
        description?: string | null;
        part_number?: string | null;
        hardware_revision?: string | null;
        firmware_revision?: string | null;
        last_calibration_ts?: number | null;
        thing_name?: string | null;
        thing_arn?: string | null;
        cert_arn?: string | null;
        manufacture_date?: string | null;
        last_uploaded_ts?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      files?: {
        __typename: 'ModelFileConnection';
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDeviceAssociationQueryVariables = {
  id?: string;
};

export type GetDeviceAssociationQuery = {
  getDeviceAssociation?: {
    __typename: 'DeviceAssociation';
    id: string;
    org_id?: string | null;
    s3_bucket_name?: string | null;
    serial_number_id: string;
    status?: DeviceAssociationStatus | null;
    user_id: string;
    customerId: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer?: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null;
    device: {
      __typename: 'Device';
      serial_number_id: string;
      name?: string | null;
      description?: string | null;
      part_number?: string | null;
      hardware_revision?: string | null;
      firmware_revision?: string | null;
      last_calibration_ts?: number | null;
      thing_name?: string | null;
      thing_arn?: string | null;
      cert_arn?: string | null;
      manufacture_date?: string | null;
      last_uploaded_ts?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      associations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
    };
    files?: {
      __typename: 'ModelFileConnection';
      items?: Array<{
        __typename: 'File';
        id: string;
        user_id: string;
        serial_number_id?: string | null;
        sensor_name?: string | null;
        sensor_type?: string | null;
        file_name?: string | null;
        file_size?: number | null;
        s3_bucket_name?: string | null;
        s3_object_key: string;
        device_meta?: string | null;
        recording_length?: number | null;
        recording_ts?: number | null;
        created_ts?: number | null;
        modified_ts?: number | null;
        archived_ts?: number | null;
        file_uploaded: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type GetDeviceAssociationBySerialQueryVariables = {
  serial_number_id?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelDeviceAssociationFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetDeviceAssociationBySerialQuery = {
  getDeviceAssociationBySerial?: {
    __typename: 'ModelDeviceAssociationConnection';
    items?: Array<{
      __typename: 'DeviceAssociation';
      id: string;
      org_id?: string | null;
      s3_bucket_name?: string | null;
      serial_number_id: string;
      status?: DeviceAssociationStatus | null;
      user_id: string;
      customerId: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer?: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      } | null;
      device: {
        __typename: 'Device';
        serial_number_id: string;
        name?: string | null;
        description?: string | null;
        part_number?: string | null;
        hardware_revision?: string | null;
        firmware_revision?: string | null;
        last_calibration_ts?: number | null;
        thing_name?: string | null;
        thing_arn?: string | null;
        cert_arn?: string | null;
        manufacture_date?: string | null;
        last_uploaded_ts?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      files?: {
        __typename: 'ModelFileConnection';
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDeviceAssociationByUserQueryVariables = {
  user_id?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelDeviceAssociationFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetDeviceAssociationByUserQuery = {
  getDeviceAssociationByUser?: {
    __typename: 'ModelDeviceAssociationConnection';
    items?: Array<{
      __typename: 'DeviceAssociation';
      id: string;
      org_id?: string | null;
      s3_bucket_name?: string | null;
      serial_number_id: string;
      status?: DeviceAssociationStatus | null;
      user_id: string;
      customerId: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer?: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      } | null;
      device: {
        __typename: 'Device';
        serial_number_id: string;
        name?: string | null;
        description?: string | null;
        part_number?: string | null;
        hardware_revision?: string | null;
        firmware_revision?: string | null;
        last_calibration_ts?: number | null;
        thing_name?: string | null;
        thing_arn?: string | null;
        cert_arn?: string | null;
        manufacture_date?: string | null;
        last_uploaded_ts?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      files?: {
        __typename: 'ModelFileConnection';
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetFileQueryVariables = {
  id?: string;
};

export type GetFileQuery = {
  getFile?: {
    __typename: 'File';
    id: string;
    user_id: string;
    serial_number_id?: string | null;
    sensor_name?: string | null;
    sensor_type?: string | null;
    file_name?: string | null;
    file_size?: number | null;
    s3_bucket_name?: string | null;
    s3_object_key: string;
    device_meta?: string | null;
    recording_length?: number | null;
    recording_ts?: number | null;
    created_ts?: number | null;
    modified_ts?: number | null;
    archived_ts?: number | null;
    file_uploaded: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
    attributes?: {
      __typename: 'ModelAttributeConnection';
      items?: Array<{
        __typename: 'Attribute';
        id: string;
        user_id: string;
        attributeFileId: string;
        name: string;
        type: string;
        value: string;
        thing?: string | null;
        mutable: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    device?: {
      __typename: 'DeviceAssociation';
      id: string;
      org_id?: string | null;
      s3_bucket_name?: string | null;
      serial_number_id: string;
      status?: DeviceAssociationStatus | null;
      user_id: string;
      customerId: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer?: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      } | null;
      device: {
        __typename: 'Device';
        serial_number_id: string;
        name?: string | null;
        description?: string | null;
        part_number?: string | null;
        hardware_revision?: string | null;
        firmware_revision?: string | null;
        last_calibration_ts?: number | null;
        thing_name?: string | null;
        thing_arn?: string | null;
        cert_arn?: string | null;
        manufacture_date?: string | null;
        last_uploaded_ts?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      files?: {
        __typename: 'ModelFileConnection';
        nextToken?: string | null;
      } | null;
    } | null;
    tags?: {
      __typename: 'ModelTagConnection';
      items?: Array<{
        __typename: 'Tag';
        id: string;
        user_id: string;
        tagFileId: string;
        value: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type ListFilesQueryVariables = {
  filter?: ModelFileFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListFilesQuery = {
  listFiles?: {
    __typename: 'ModelFileConnection';
    items?: Array<{
      __typename: 'File';
      id: string;
      user_id: string;
      serial_number_id?: string | null;
      sensor_name?: string | null;
      sensor_type?: string | null;
      file_name?: string | null;
      file_size?: number | null;
      s3_bucket_name?: string | null;
      s3_object_key: string;
      device_meta?: string | null;
      recording_length?: number | null;
      recording_ts?: number | null;
      created_ts?: number | null;
      modified_ts?: number | null;
      archived_ts?: number | null;
      file_uploaded: boolean;
      createdAt?: string | null;
      updatedAt?: string | null;
      attributes?: {
        __typename: 'ModelAttributeConnection';
        nextToken?: string | null;
      } | null;
      device?: {
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      tags?: {
        __typename: 'ModelTagConnection';
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetFileByUserQueryVariables = {
  user_id?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelFileFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetFileByUserQuery = {
  getFileByUser?: {
    __typename: 'ModelFileConnection';
    items?: Array<{
      __typename: 'File';
      id: string;
      user_id: string;
      serial_number_id?: string | null;
      sensor_name?: string | null;
      sensor_type?: string | null;
      file_name?: string | null;
      file_size?: number | null;
      s3_bucket_name?: string | null;
      s3_object_key: string;
      device_meta?: string | null;
      recording_length?: number | null;
      recording_ts?: number | null;
      created_ts?: number | null;
      modified_ts?: number | null;
      archived_ts?: number | null;
      file_uploaded: boolean;
      createdAt?: string | null;
      updatedAt?: string | null;
      attributes?: {
        __typename: 'ModelAttributeConnection';
        nextToken?: string | null;
      } | null;
      device?: {
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      tags?: {
        __typename: 'ModelTagConnection';
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetFileByS3KeyQueryVariables = {
  s3_object_key?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelFileFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetFileByS3KeyQuery = {
  getFileByS3Key?: {
    __typename: 'ModelFileConnection';
    items?: Array<{
      __typename: 'File';
      id: string;
      user_id: string;
      serial_number_id?: string | null;
      sensor_name?: string | null;
      sensor_type?: string | null;
      file_name?: string | null;
      file_size?: number | null;
      s3_bucket_name?: string | null;
      s3_object_key: string;
      device_meta?: string | null;
      recording_length?: number | null;
      recording_ts?: number | null;
      created_ts?: number | null;
      modified_ts?: number | null;
      archived_ts?: number | null;
      file_uploaded: boolean;
      createdAt?: string | null;
      updatedAt?: string | null;
      attributes?: {
        __typename: 'ModelAttributeConnection';
        nextToken?: string | null;
      } | null;
      device?: {
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      tags?: {
        __typename: 'ModelTagConnection';
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetFileBySerialAndUserQueryVariables = {
  user_id?: string | null;
  serial_number_id?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelFileFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetFileBySerialAndUserQuery = {
  getFileBySerialAndUser?: {
    __typename: 'ModelFileConnection';
    items?: Array<{
      __typename: 'File';
      id: string;
      user_id: string;
      serial_number_id?: string | null;
      sensor_name?: string | null;
      sensor_type?: string | null;
      file_name?: string | null;
      file_size?: number | null;
      s3_bucket_name?: string | null;
      s3_object_key: string;
      device_meta?: string | null;
      recording_length?: number | null;
      recording_ts?: number | null;
      created_ts?: number | null;
      modified_ts?: number | null;
      archived_ts?: number | null;
      file_uploaded: boolean;
      createdAt?: string | null;
      updatedAt?: string | null;
      attributes?: {
        __typename: 'ModelAttributeConnection';
        nextToken?: string | null;
      } | null;
      device?: {
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      tags?: {
        __typename: 'ModelTagConnection';
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetFileByUserSortedQueryVariables = {
  user_id?: string | null;
  created_ts?: ModelIntKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelFileFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetFileByUserSortedQuery = {
  getFileByUserSorted?: {
    __typename: 'ModelFileConnection';
    items?: Array<{
      __typename: 'File';
      id: string;
      user_id: string;
      serial_number_id?: string | null;
      sensor_name?: string | null;
      sensor_type?: string | null;
      file_name?: string | null;
      file_size?: number | null;
      s3_bucket_name?: string | null;
      s3_object_key: string;
      device_meta?: string | null;
      recording_length?: number | null;
      recording_ts?: number | null;
      created_ts?: number | null;
      modified_ts?: number | null;
      archived_ts?: number | null;
      file_uploaded: boolean;
      createdAt?: string | null;
      updatedAt?: string | null;
      attributes?: {
        __typename: 'ModelAttributeConnection';
        nextToken?: string | null;
      } | null;
      device?: {
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      tags?: {
        __typename: 'ModelTagConnection';
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetFileBySerialSortedQueryVariables = {
  serial_number_id?: string | null;
  created_ts?: ModelIntKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelFileFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetFileBySerialSortedQuery = {
  getFileBySerialSorted?: {
    __typename: 'ModelFileConnection';
    items?: Array<{
      __typename: 'File';
      id: string;
      user_id: string;
      serial_number_id?: string | null;
      sensor_name?: string | null;
      sensor_type?: string | null;
      file_name?: string | null;
      file_size?: number | null;
      s3_bucket_name?: string | null;
      s3_object_key: string;
      device_meta?: string | null;
      recording_length?: number | null;
      recording_ts?: number | null;
      created_ts?: number | null;
      modified_ts?: number | null;
      archived_ts?: number | null;
      file_uploaded: boolean;
      createdAt?: string | null;
      updatedAt?: string | null;
      attributes?: {
        __typename: 'ModelAttributeConnection';
        nextToken?: string | null;
      } | null;
      device?: {
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      tags?: {
        __typename: 'ModelTagConnection';
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type SearchFilesQueryVariables = {
  filter?: SearchableFileFilterInput | null;
  sort?: SearchableFileSortInput | null;
  limit?: number | null;
  nextToken?: string | null;
  from?: number | null;
};

export type SearchFilesQuery = {
  searchFiles?: {
    __typename: 'SearchableFileConnection';
    items?: Array<{
      __typename: 'File';
      id: string;
      user_id: string;
      serial_number_id?: string | null;
      sensor_name?: string | null;
      sensor_type?: string | null;
      file_name?: string | null;
      file_size?: number | null;
      s3_bucket_name?: string | null;
      s3_object_key: string;
      device_meta?: string | null;
      recording_length?: number | null;
      recording_ts?: number | null;
      created_ts?: number | null;
      modified_ts?: number | null;
      archived_ts?: number | null;
      file_uploaded: boolean;
      createdAt?: string | null;
      updatedAt?: string | null;
      attributes?: {
        __typename: 'ModelAttributeConnection';
        nextToken?: string | null;
      } | null;
      device?: {
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      tags?: {
        __typename: 'ModelTagConnection';
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
    total?: number | null;
  } | null;
};

export type GetStripePlanQueryVariables = {
  id?: string;
};

export type GetStripePlanQuery = {
  getStripePlan?: {
    __typename: 'StripePlan';
    id: StripePlanId;
    tierId: TierName;
    billingCycle?: BillingCycle | null;
    cost?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    tier: {
      __typename: 'Tier';
      id: TierName;
      costMonthly?: number | null;
      costYearly?: number | null;
      deviceMaxCount?: number | null;
      storageLimitGb?: number | null;
      alertMaxCount?: number | null;
      hasApiAccess?: boolean | null;
      billingCycles?: Array<BillingCycle | null> | null;
      reportMaxCount?: number | null;
      reportTemplate?: ReportTemplate | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stripePlans?: {
        __typename: 'ModelStripePlanConnection';
        nextToken?: string | null;
      } | null;
    };
  } | null;
};

export type ListStripePlansQueryVariables = {
  filter?: ModelStripePlanFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListStripePlansQuery = {
  listStripePlans?: {
    __typename: 'ModelStripePlanConnection';
    items?: Array<{
      __typename: 'StripePlan';
      id: StripePlanId;
      tierId: TierName;
      billingCycle?: BillingCycle | null;
      cost?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      tier: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetTagQueryVariables = {
  id?: string;
};

export type GetTagQuery = {
  getTag?: {
    __typename: 'Tag';
    id: string;
    user_id: string;
    tagFileId: string;
    value: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListTagsQueryVariables = {
  filter?: ModelTagFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListTagsQuery = {
  listTags?: {
    __typename: 'ModelTagConnection';
    items?: Array<{
      __typename: 'Tag';
      id: string;
      user_id: string;
      tagFileId: string;
      value: string;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetTagByUserQueryVariables = {
  user_id?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelTagFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetTagByUserQuery = {
  getTagByUser?: {
    __typename: 'ModelTagConnection';
    items?: Array<{
      __typename: 'Tag';
      id: string;
      user_id: string;
      tagFileId: string;
      value: string;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type ListTiersQueryVariables = {
  filter?: ModelTierFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListTiersQuery = {
  listTiers?: {
    __typename: 'ModelTierConnection';
    items?: Array<{
      __typename: 'Tier';
      id: TierName;
      costMonthly?: number | null;
      costYearly?: number | null;
      deviceMaxCount?: number | null;
      storageLimitGb?: number | null;
      alertMaxCount?: number | null;
      hasApiAccess?: boolean | null;
      billingCycles?: Array<BillingCycle | null> | null;
      reportMaxCount?: number | null;
      reportTemplate?: ReportTemplate | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stripePlans?: {
        __typename: 'ModelStripePlanConnection';
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetTierQueryVariables = {
  id?: string;
};

export type GetTierQuery = {
  getTier?: {
    __typename: 'Tier';
    id: TierName;
    costMonthly?: number | null;
    costYearly?: number | null;
    deviceMaxCount?: number | null;
    storageLimitGb?: number | null;
    alertMaxCount?: number | null;
    hasApiAccess?: boolean | null;
    billingCycles?: Array<BillingCycle | null> | null;
    reportMaxCount?: number | null;
    reportTemplate?: ReportTemplate | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    stripePlans?: {
      __typename: 'ModelStripePlanConnection';
      items?: Array<{
        __typename: 'StripePlan';
        id: StripePlanId;
        tierId: TierName;
        billingCycle?: BillingCycle | null;
        cost?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateAlertSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateAlertSubscription = {
  onCreateAlert?: {
    __typename: 'Alert';
    id: string;
    owner: string;
    customerId: string;
    name?: string | null;
    tagName: string;
    enabled?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer?: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateAlertSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateAlertSubscription = {
  onUpdateAlert?: {
    __typename: 'Alert';
    id: string;
    owner: string;
    customerId: string;
    name?: string | null;
    tagName: string;
    enabled?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer?: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteAlertSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteAlertSubscription = {
  onDeleteAlert?: {
    __typename: 'Alert';
    id: string;
    owner: string;
    customerId: string;
    name?: string | null;
    tagName: string;
    enabled?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer?: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateApiKeySubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateApiKeySubscription = {
  onCreateApiKey?: {
    __typename: 'ApiKey';
    id: string;
    name?: string | null;
    owner: string;
    revoked: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateApiKeySubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateApiKeySubscription = {
  onUpdateApiKey?: {
    __typename: 'ApiKey';
    id: string;
    name?: string | null;
    owner: string;
    revoked: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteApiKeySubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteApiKeySubscription = {
  onDeleteApiKey?: {
    __typename: 'ApiKey';
    id: string;
    name?: string | null;
    owner: string;
    revoked: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreateAttributeSubscriptionVariables = {
  user_id?: string | null;
};

export type OnCreateAttributeSubscription = {
  onCreateAttribute?: {
    __typename: 'Attribute';
    id: string;
    user_id: string;
    attributeFileId: string;
    name: string;
    type: string;
    value: string;
    thing?: string | null;
    mutable: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateAttributeSubscriptionVariables = {
  user_id?: string | null;
};

export type OnUpdateAttributeSubscription = {
  onUpdateAttribute?: {
    __typename: 'Attribute';
    id: string;
    user_id: string;
    attributeFileId: string;
    name: string;
    type: string;
    value: string;
    thing?: string | null;
    mutable: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteAttributeSubscriptionVariables = {
  user_id?: string | null;
};

export type OnDeleteAttributeSubscription = {
  onDeleteAttribute?: {
    __typename: 'Attribute';
    id: string;
    user_id: string;
    attributeFileId: string;
    name: string;
    type: string;
    value: string;
    thing?: string | null;
    mutable: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreateBillingSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateBillingSubscription = {
  onCreateBilling?: {
    __typename: 'Billing';
    id: string;
    stripe_session_id: string;
    owner: string;
    stripe_customer_id: string;
    stripe_subscription_id?: string | null;
    subscription_status: BillingSubscriptionStatus;
    stripe_plan_id: StripePlanId;
    suspended_at?: number | null;
    next_billing_at?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type OnUpdateBillingSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateBillingSubscription = {
  onUpdateBilling?: {
    __typename: 'Billing';
    id: string;
    stripe_session_id: string;
    owner: string;
    stripe_customer_id: string;
    stripe_subscription_id?: string | null;
    subscription_status: BillingSubscriptionStatus;
    stripe_plan_id: StripePlanId;
    suspended_at?: number | null;
    next_billing_at?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type OnDeleteBillingSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteBillingSubscription = {
  onDeleteBilling?: {
    __typename: 'Billing';
    id: string;
    stripe_session_id: string;
    owner: string;
    stripe_customer_id: string;
    stripe_subscription_id?: string | null;
    subscription_status: BillingSubscriptionStatus;
    stripe_plan_id: StripePlanId;
    suspended_at?: number | null;
    next_billing_at?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type OnCreateCustomScriptSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateCustomScriptSubscription = {
  onCreateCustomScript?: {
    __typename: 'CustomScript';
    id: string;
    owner: string;
    error?: {
      __typename: 'CustomScriptError';
      code?: number | null;
      detail: string;
      message: string;
      status?: string | null;
      traces: Array<{
        __typename: 'StackTrace';
        filename: string;
        lineno: string;
        name?: string | null;
        offset?: number | null;
        text?: string | null;
      }>;
      transactionId?: string | null;
      type: string;
    } | null;
    lines: Array<string>;
    status?: CustomScriptStatus | null;
    title?: string | null;
    type: CustomScriptType;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateCustomScriptSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateCustomScriptSubscription = {
  onUpdateCustomScript?: {
    __typename: 'CustomScript';
    id: string;
    owner: string;
    error?: {
      __typename: 'CustomScriptError';
      code?: number | null;
      detail: string;
      message: string;
      status?: string | null;
      traces: Array<{
        __typename: 'StackTrace';
        filename: string;
        lineno: string;
        name?: string | null;
        offset?: number | null;
        text?: string | null;
      }>;
      transactionId?: string | null;
      type: string;
    } | null;
    lines: Array<string>;
    status?: CustomScriptStatus | null;
    title?: string | null;
    type: CustomScriptType;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteCustomScriptSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteCustomScriptSubscription = {
  onDeleteCustomScript?: {
    __typename: 'CustomScript';
    id: string;
    owner: string;
    error?: {
      __typename: 'CustomScriptError';
      code?: number | null;
      detail: string;
      message: string;
      status?: string | null;
      traces: Array<{
        __typename: 'StackTrace';
        filename: string;
        lineno: string;
        name?: string | null;
        offset?: number | null;
        text?: string | null;
      }>;
      transactionId?: string | null;
      type: string;
    } | null;
    lines: Array<string>;
    status?: CustomScriptStatus | null;
    title?: string | null;
    type: CustomScriptType;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreateCustomerSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateCustomerSubscription = {
  onCreateCustomer?: {
    __typename: 'Customer';
    id: string;
    owner: string;
    storageConsumed?: number | null;
    storageSpaceRemaining?: boolean | null;
    tierId: TierName;
    createdAt?: string | null;
    updatedAt?: string | null;
    companyName?: string | null;
    logoImage?: string | null;
    apiKeys?: {
      __typename: 'ModelApiKeyConnection';
      items?: Array<{
        __typename: 'ApiKey';
        id: string;
        name?: string | null;
        owner: string;
        revoked: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    billing?: {
      __typename: 'Billing';
      id: string;
      stripe_session_id: string;
      owner: string;
      stripe_customer_id: string;
      stripe_subscription_id?: string | null;
      subscription_status: BillingSubscriptionStatus;
      stripe_plan_id: StripePlanId;
      suspended_at?: number | null;
      next_billing_at?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      };
    } | null;
    deviceAssociations?: {
      __typename: 'ModelDeviceAssociationConnection';
      items?: Array<{
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    tier?: {
      __typename: 'Tier';
      id: TierName;
      costMonthly?: number | null;
      costYearly?: number | null;
      deviceMaxCount?: number | null;
      storageLimitGb?: number | null;
      alertMaxCount?: number | null;
      hasApiAccess?: boolean | null;
      billingCycles?: Array<BillingCycle | null> | null;
      reportMaxCount?: number | null;
      reportTemplate?: ReportTemplate | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stripePlans?: {
        __typename: 'ModelStripePlanConnection';
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateCustomerSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateCustomerSubscription = {
  onUpdateCustomer?: {
    __typename: 'Customer';
    id: string;
    owner: string;
    storageConsumed?: number | null;
    storageSpaceRemaining?: boolean | null;
    tierId: TierName;
    createdAt?: string | null;
    updatedAt?: string | null;
    companyName?: string | null;
    logoImage?: string | null;
    apiKeys?: {
      __typename: 'ModelApiKeyConnection';
      items?: Array<{
        __typename: 'ApiKey';
        id: string;
        name?: string | null;
        owner: string;
        revoked: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    billing?: {
      __typename: 'Billing';
      id: string;
      stripe_session_id: string;
      owner: string;
      stripe_customer_id: string;
      stripe_subscription_id?: string | null;
      subscription_status: BillingSubscriptionStatus;
      stripe_plan_id: StripePlanId;
      suspended_at?: number | null;
      next_billing_at?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      };
    } | null;
    deviceAssociations?: {
      __typename: 'ModelDeviceAssociationConnection';
      items?: Array<{
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    tier?: {
      __typename: 'Tier';
      id: TierName;
      costMonthly?: number | null;
      costYearly?: number | null;
      deviceMaxCount?: number | null;
      storageLimitGb?: number | null;
      alertMaxCount?: number | null;
      hasApiAccess?: boolean | null;
      billingCycles?: Array<BillingCycle | null> | null;
      reportMaxCount?: number | null;
      reportTemplate?: ReportTemplate | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stripePlans?: {
        __typename: 'ModelStripePlanConnection';
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteCustomerSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteCustomerSubscription = {
  onDeleteCustomer?: {
    __typename: 'Customer';
    id: string;
    owner: string;
    storageConsumed?: number | null;
    storageSpaceRemaining?: boolean | null;
    tierId: TierName;
    createdAt?: string | null;
    updatedAt?: string | null;
    companyName?: string | null;
    logoImage?: string | null;
    apiKeys?: {
      __typename: 'ModelApiKeyConnection';
      items?: Array<{
        __typename: 'ApiKey';
        id: string;
        name?: string | null;
        owner: string;
        revoked: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    billing?: {
      __typename: 'Billing';
      id: string;
      stripe_session_id: string;
      owner: string;
      stripe_customer_id: string;
      stripe_subscription_id?: string | null;
      subscription_status: BillingSubscriptionStatus;
      stripe_plan_id: StripePlanId;
      suspended_at?: number | null;
      next_billing_at?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      };
    } | null;
    deviceAssociations?: {
      __typename: 'ModelDeviceAssociationConnection';
      items?: Array<{
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    tier?: {
      __typename: 'Tier';
      id: TierName;
      costMonthly?: number | null;
      costYearly?: number | null;
      deviceMaxCount?: number | null;
      storageLimitGb?: number | null;
      alertMaxCount?: number | null;
      hasApiAccess?: boolean | null;
      billingCycles?: Array<BillingCycle | null> | null;
      reportMaxCount?: number | null;
      reportTemplate?: ReportTemplate | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stripePlans?: {
        __typename: 'ModelStripePlanConnection';
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateDashboardSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateDashboardSubscription = {
  onCreateDashboard?: {
    __typename: 'Dashboard';
    id: string;
    owner: string;
    customScriptId?: string | null;
    defaultScript?: string | null;
    error?: {
      __typename: 'DashboardError';
      code?: number | null;
      message: string;
      status?: string | null;
      transactionId?: string | null;
      scriptError?: boolean | null;
    } | null;
    output_logs?: string | null;
    lastTransactionId?: string | null;
    maxFiles?: number | null;
    status: DashboardStatus;
    tags: Array<string>;
    file_ids?: Array<string> | null;
    title?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customScript?: {
      __typename: 'CustomScript';
      id: string;
      owner: string;
      error?: {
        __typename: 'CustomScriptError';
        code?: number | null;
        detail: string;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        type: string;
      } | null;
      lines: Array<string>;
      status?: CustomScriptStatus | null;
      title?: string | null;
      type: CustomScriptType;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
    notes?: string | null;
    sequence?: number | null;
    modules?: {
      __typename: 'ModelDashboardModuleConnection';
      items?: Array<{
        __typename: 'DashboardModule';
        id: string;
        owner: string;
        dashboardId: string;
        sequence?: number | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    shares?: {
      __typename: 'ModelDashboardShareConnection';
      items?: Array<{
        __typename: 'DashboardShare';
        id: string;
        dashboardId: string;
        token: string;
        expires?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner: string;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateDashboardSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateDashboardSubscription = {
  onUpdateDashboard?: {
    __typename: 'Dashboard';
    id: string;
    owner: string;
    customScriptId?: string | null;
    defaultScript?: string | null;
    error?: {
      __typename: 'DashboardError';
      code?: number | null;
      message: string;
      status?: string | null;
      transactionId?: string | null;
      scriptError?: boolean | null;
    } | null;
    output_logs?: string | null;
    lastTransactionId?: string | null;
    maxFiles?: number | null;
    status: DashboardStatus;
    tags: Array<string>;
    file_ids?: Array<string> | null;
    title?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customScript?: {
      __typename: 'CustomScript';
      id: string;
      owner: string;
      error?: {
        __typename: 'CustomScriptError';
        code?: number | null;
        detail: string;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        type: string;
      } | null;
      lines: Array<string>;
      status?: CustomScriptStatus | null;
      title?: string | null;
      type: CustomScriptType;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
    notes?: string | null;
    sequence?: number | null;
    modules?: {
      __typename: 'ModelDashboardModuleConnection';
      items?: Array<{
        __typename: 'DashboardModule';
        id: string;
        owner: string;
        dashboardId: string;
        sequence?: number | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    shares?: {
      __typename: 'ModelDashboardShareConnection';
      items?: Array<{
        __typename: 'DashboardShare';
        id: string;
        dashboardId: string;
        token: string;
        expires?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner: string;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteDashboardSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteDashboardSubscription = {
  onDeleteDashboard?: {
    __typename: 'Dashboard';
    id: string;
    owner: string;
    customScriptId?: string | null;
    defaultScript?: string | null;
    error?: {
      __typename: 'DashboardError';
      code?: number | null;
      message: string;
      status?: string | null;
      transactionId?: string | null;
      scriptError?: boolean | null;
    } | null;
    output_logs?: string | null;
    lastTransactionId?: string | null;
    maxFiles?: number | null;
    status: DashboardStatus;
    tags: Array<string>;
    file_ids?: Array<string> | null;
    title?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    customScript?: {
      __typename: 'CustomScript';
      id: string;
      owner: string;
      error?: {
        __typename: 'CustomScriptError';
        code?: number | null;
        detail: string;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        type: string;
      } | null;
      lines: Array<string>;
      status?: CustomScriptStatus | null;
      title?: string | null;
      type: CustomScriptType;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
    notes?: string | null;
    sequence?: number | null;
    modules?: {
      __typename: 'ModelDashboardModuleConnection';
      items?: Array<{
        __typename: 'DashboardModule';
        id: string;
        owner: string;
        dashboardId: string;
        sequence?: number | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    shares?: {
      __typename: 'ModelDashboardShareConnection';
      items?: Array<{
        __typename: 'DashboardShare';
        id: string;
        dashboardId: string;
        token: string;
        expires?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        owner: string;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateDashboardModuleSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateDashboardModuleSubscription = {
  onCreateDashboardModule?: {
    __typename: 'DashboardModule';
    id: string;
    owner: string;
    dashboardId: string;
    error?: {
      __typename: 'DashboardModuleError';
      code?: number | null;
      message: string;
      status?: string | null;
      transactionId?: string | null;
    } | null;
    sequence?: number | null;
    title?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    dashboard: {
      __typename: 'Dashboard';
      id: string;
      owner: string;
      customScriptId?: string | null;
      defaultScript?: string | null;
      error?: {
        __typename: 'DashboardError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        scriptError?: boolean | null;
      } | null;
      output_logs?: string | null;
      lastTransactionId?: string | null;
      maxFiles?: number | null;
      status: DashboardStatus;
      tags: Array<string>;
      file_ids?: Array<string> | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customScript?: {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        lines: Array<string>;
        status?: CustomScriptStatus | null;
        title?: string | null;
        type: CustomScriptType;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      notes?: string | null;
      sequence?: number | null;
      modules?: {
        __typename: 'ModelDashboardModuleConnection';
        nextToken?: string | null;
      } | null;
      shares?: {
        __typename: 'ModelDashboardShareConnection';
        nextToken?: string | null;
      } | null;
    };
    data?: {
      __typename: 'ModelDashboardModuleDataConnection';
      items?: Array<{
        __typename: 'DashboardModuleData';
        id: string;
        owner: string;
        moduleId: string;
        json: string;
        sequence?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    frames?: {
      __typename: 'ModelDashboardModuleFrameConnection';
      items?: Array<{
        __typename: 'DashboardModuleFrame';
        id: string;
        owner: string;
        moduleId: string;
        json: string;
        sequence: number;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    layout?: {
      __typename: 'DashboardModuleLayout';
      id: string;
      owner: string;
      json: string;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
  } | null;
};

export type OnUpdateDashboardModuleSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateDashboardModuleSubscription = {
  onUpdateDashboardModule?: {
    __typename: 'DashboardModule';
    id: string;
    owner: string;
    dashboardId: string;
    error?: {
      __typename: 'DashboardModuleError';
      code?: number | null;
      message: string;
      status?: string | null;
      transactionId?: string | null;
    } | null;
    sequence?: number | null;
    title?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    dashboard: {
      __typename: 'Dashboard';
      id: string;
      owner: string;
      customScriptId?: string | null;
      defaultScript?: string | null;
      error?: {
        __typename: 'DashboardError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        scriptError?: boolean | null;
      } | null;
      output_logs?: string | null;
      lastTransactionId?: string | null;
      maxFiles?: number | null;
      status: DashboardStatus;
      tags: Array<string>;
      file_ids?: Array<string> | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customScript?: {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        lines: Array<string>;
        status?: CustomScriptStatus | null;
        title?: string | null;
        type: CustomScriptType;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      notes?: string | null;
      sequence?: number | null;
      modules?: {
        __typename: 'ModelDashboardModuleConnection';
        nextToken?: string | null;
      } | null;
      shares?: {
        __typename: 'ModelDashboardShareConnection';
        nextToken?: string | null;
      } | null;
    };
    data?: {
      __typename: 'ModelDashboardModuleDataConnection';
      items?: Array<{
        __typename: 'DashboardModuleData';
        id: string;
        owner: string;
        moduleId: string;
        json: string;
        sequence?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    frames?: {
      __typename: 'ModelDashboardModuleFrameConnection';
      items?: Array<{
        __typename: 'DashboardModuleFrame';
        id: string;
        owner: string;
        moduleId: string;
        json: string;
        sequence: number;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    layout?: {
      __typename: 'DashboardModuleLayout';
      id: string;
      owner: string;
      json: string;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
  } | null;
};

export type OnDeleteDashboardModuleSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteDashboardModuleSubscription = {
  onDeleteDashboardModule?: {
    __typename: 'DashboardModule';
    id: string;
    owner: string;
    dashboardId: string;
    error?: {
      __typename: 'DashboardModuleError';
      code?: number | null;
      message: string;
      status?: string | null;
      transactionId?: string | null;
    } | null;
    sequence?: number | null;
    title?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    dashboard: {
      __typename: 'Dashboard';
      id: string;
      owner: string;
      customScriptId?: string | null;
      defaultScript?: string | null;
      error?: {
        __typename: 'DashboardError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        scriptError?: boolean | null;
      } | null;
      output_logs?: string | null;
      lastTransactionId?: string | null;
      maxFiles?: number | null;
      status: DashboardStatus;
      tags: Array<string>;
      file_ids?: Array<string> | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customScript?: {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        lines: Array<string>;
        status?: CustomScriptStatus | null;
        title?: string | null;
        type: CustomScriptType;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      notes?: string | null;
      sequence?: number | null;
      modules?: {
        __typename: 'ModelDashboardModuleConnection';
        nextToken?: string | null;
      } | null;
      shares?: {
        __typename: 'ModelDashboardShareConnection';
        nextToken?: string | null;
      } | null;
    };
    data?: {
      __typename: 'ModelDashboardModuleDataConnection';
      items?: Array<{
        __typename: 'DashboardModuleData';
        id: string;
        owner: string;
        moduleId: string;
        json: string;
        sequence?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    frames?: {
      __typename: 'ModelDashboardModuleFrameConnection';
      items?: Array<{
        __typename: 'DashboardModuleFrame';
        id: string;
        owner: string;
        moduleId: string;
        json: string;
        sequence: number;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    layout?: {
      __typename: 'DashboardModuleLayout';
      id: string;
      owner: string;
      json: string;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
  } | null;
};

export type OnCreateDashboardModuleDataSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateDashboardModuleDataSubscription = {
  onCreateDashboardModuleData?: {
    __typename: 'DashboardModuleData';
    id: string;
    owner: string;
    moduleId: string;
    json: string;
    sequence?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    module: {
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type OnUpdateDashboardModuleDataSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateDashboardModuleDataSubscription = {
  onUpdateDashboardModuleData?: {
    __typename: 'DashboardModuleData';
    id: string;
    owner: string;
    moduleId: string;
    json: string;
    sequence?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    module: {
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type OnDeleteDashboardModuleDataSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteDashboardModuleDataSubscription = {
  onDeleteDashboardModuleData?: {
    __typename: 'DashboardModuleData';
    id: string;
    owner: string;
    moduleId: string;
    json: string;
    sequence?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    module: {
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type OnCreateDashboardModuleFrameSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateDashboardModuleFrameSubscription = {
  onCreateDashboardModuleFrame?: {
    __typename: 'DashboardModuleFrame';
    id: string;
    owner: string;
    moduleId: string;
    json: string;
    sequence: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    module: {
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type OnUpdateDashboardModuleFrameSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateDashboardModuleFrameSubscription = {
  onUpdateDashboardModuleFrame?: {
    __typename: 'DashboardModuleFrame';
    id: string;
    owner: string;
    moduleId: string;
    json: string;
    sequence: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    module: {
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type OnDeleteDashboardModuleFrameSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteDashboardModuleFrameSubscription = {
  onDeleteDashboardModuleFrame?: {
    __typename: 'DashboardModuleFrame';
    id: string;
    owner: string;
    moduleId: string;
    json: string;
    sequence: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    module: {
      __typename: 'DashboardModule';
      id: string;
      owner: string;
      dashboardId: string;
      error?: {
        __typename: 'DashboardModuleError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
      } | null;
      sequence?: number | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      dashboard: {
        __typename: 'Dashboard';
        id: string;
        owner: string;
        customScriptId?: string | null;
        defaultScript?: string | null;
        output_logs?: string | null;
        lastTransactionId?: string | null;
        maxFiles?: number | null;
        status: DashboardStatus;
        tags: Array<string>;
        file_ids?: Array<string> | null;
        title?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        notes?: string | null;
        sequence?: number | null;
      };
      data?: {
        __typename: 'ModelDashboardModuleDataConnection';
        nextToken?: string | null;
      } | null;
      frames?: {
        __typename: 'ModelDashboardModuleFrameConnection';
        nextToken?: string | null;
      } | null;
      layout?: {
        __typename: 'DashboardModuleLayout';
        id: string;
        owner: string;
        json: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    };
  } | null;
};

export type OnCreateDashboardModuleLayoutSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateDashboardModuleLayoutSubscription = {
  onCreateDashboardModuleLayout?: {
    __typename: 'DashboardModuleLayout';
    id: string;
    owner: string;
    json: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateDashboardModuleLayoutSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateDashboardModuleLayoutSubscription = {
  onUpdateDashboardModuleLayout?: {
    __typename: 'DashboardModuleLayout';
    id: string;
    owner: string;
    json: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteDashboardModuleLayoutSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteDashboardModuleLayoutSubscription = {
  onDeleteDashboardModuleLayout?: {
    __typename: 'DashboardModuleLayout';
    id: string;
    owner: string;
    json: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreateDashboardShareSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateDashboardShareSubscription = {
  onCreateDashboardShare?: {
    __typename: 'DashboardShare';
    id: string;
    dashboardId: string;
    token: string;
    expires?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    owner: string;
    dashboard?: {
      __typename: 'Dashboard';
      id: string;
      owner: string;
      customScriptId?: string | null;
      defaultScript?: string | null;
      error?: {
        __typename: 'DashboardError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        scriptError?: boolean | null;
      } | null;
      output_logs?: string | null;
      lastTransactionId?: string | null;
      maxFiles?: number | null;
      status: DashboardStatus;
      tags: Array<string>;
      file_ids?: Array<string> | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customScript?: {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        lines: Array<string>;
        status?: CustomScriptStatus | null;
        title?: string | null;
        type: CustomScriptType;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      notes?: string | null;
      sequence?: number | null;
      modules?: {
        __typename: 'ModelDashboardModuleConnection';
        nextToken?: string | null;
      } | null;
      shares?: {
        __typename: 'ModelDashboardShareConnection';
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateDashboardShareSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateDashboardShareSubscription = {
  onUpdateDashboardShare?: {
    __typename: 'DashboardShare';
    id: string;
    dashboardId: string;
    token: string;
    expires?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    owner: string;
    dashboard?: {
      __typename: 'Dashboard';
      id: string;
      owner: string;
      customScriptId?: string | null;
      defaultScript?: string | null;
      error?: {
        __typename: 'DashboardError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        scriptError?: boolean | null;
      } | null;
      output_logs?: string | null;
      lastTransactionId?: string | null;
      maxFiles?: number | null;
      status: DashboardStatus;
      tags: Array<string>;
      file_ids?: Array<string> | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customScript?: {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        lines: Array<string>;
        status?: CustomScriptStatus | null;
        title?: string | null;
        type: CustomScriptType;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      notes?: string | null;
      sequence?: number | null;
      modules?: {
        __typename: 'ModelDashboardModuleConnection';
        nextToken?: string | null;
      } | null;
      shares?: {
        __typename: 'ModelDashboardShareConnection';
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteDashboardShareSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteDashboardShareSubscription = {
  onDeleteDashboardShare?: {
    __typename: 'DashboardShare';
    id: string;
    dashboardId: string;
    token: string;
    expires?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    owner: string;
    dashboard?: {
      __typename: 'Dashboard';
      id: string;
      owner: string;
      customScriptId?: string | null;
      defaultScript?: string | null;
      error?: {
        __typename: 'DashboardError';
        code?: number | null;
        message: string;
        status?: string | null;
        transactionId?: string | null;
        scriptError?: boolean | null;
      } | null;
      output_logs?: string | null;
      lastTransactionId?: string | null;
      maxFiles?: number | null;
      status: DashboardStatus;
      tags: Array<string>;
      file_ids?: Array<string> | null;
      title?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      customScript?: {
        __typename: 'CustomScript';
        id: string;
        owner: string;
        lines: Array<string>;
        status?: CustomScriptStatus | null;
        title?: string | null;
        type: CustomScriptType;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      notes?: string | null;
      sequence?: number | null;
      modules?: {
        __typename: 'ModelDashboardModuleConnection';
        nextToken?: string | null;
      } | null;
      shares?: {
        __typename: 'ModelDashboardShareConnection';
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateDeviceSubscription = {
  onCreateDevice?: {
    __typename: 'Device';
    serial_number_id: string;
    name?: string | null;
    description?: string | null;
    part_number?: string | null;
    hardware_revision?: string | null;
    firmware_revision?: string | null;
    last_calibration_ts?: number | null;
    thing_name?: string | null;
    thing_arn?: string | null;
    cert_arn?: string | null;
    manufacture_date?: string | null;
    last_uploaded_ts?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    associations?: {
      __typename: 'ModelDeviceAssociationConnection';
      items?: Array<{
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateDeviceSubscription = {
  onUpdateDevice?: {
    __typename: 'Device';
    serial_number_id: string;
    name?: string | null;
    description?: string | null;
    part_number?: string | null;
    hardware_revision?: string | null;
    firmware_revision?: string | null;
    last_calibration_ts?: number | null;
    thing_name?: string | null;
    thing_arn?: string | null;
    cert_arn?: string | null;
    manufacture_date?: string | null;
    last_uploaded_ts?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    associations?: {
      __typename: 'ModelDeviceAssociationConnection';
      items?: Array<{
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteDeviceSubscription = {
  onDeleteDevice?: {
    __typename: 'Device';
    serial_number_id: string;
    name?: string | null;
    description?: string | null;
    part_number?: string | null;
    hardware_revision?: string | null;
    firmware_revision?: string | null;
    last_calibration_ts?: number | null;
    thing_name?: string | null;
    thing_arn?: string | null;
    cert_arn?: string | null;
    manufacture_date?: string | null;
    last_uploaded_ts?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    associations?: {
      __typename: 'ModelDeviceAssociationConnection';
      items?: Array<{
        __typename: 'DeviceAssociation';
        id: string;
        org_id?: string | null;
        s3_bucket_name?: string | null;
        serial_number_id: string;
        status?: DeviceAssociationStatus | null;
        user_id: string;
        customerId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateDeviceAssociationSubscriptionVariables = {
  user_id?: string | null;
};

export type OnCreateDeviceAssociationSubscription = {
  onCreateDeviceAssociation?: {
    __typename: 'DeviceAssociation';
    id: string;
    org_id?: string | null;
    s3_bucket_name?: string | null;
    serial_number_id: string;
    status?: DeviceAssociationStatus | null;
    user_id: string;
    customerId: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer?: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null;
    device: {
      __typename: 'Device';
      serial_number_id: string;
      name?: string | null;
      description?: string | null;
      part_number?: string | null;
      hardware_revision?: string | null;
      firmware_revision?: string | null;
      last_calibration_ts?: number | null;
      thing_name?: string | null;
      thing_arn?: string | null;
      cert_arn?: string | null;
      manufacture_date?: string | null;
      last_uploaded_ts?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      associations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
    };
    files?: {
      __typename: 'ModelFileConnection';
      items?: Array<{
        __typename: 'File';
        id: string;
        user_id: string;
        serial_number_id?: string | null;
        sensor_name?: string | null;
        sensor_type?: string | null;
        file_name?: string | null;
        file_size?: number | null;
        s3_bucket_name?: string | null;
        s3_object_key: string;
        device_meta?: string | null;
        recording_length?: number | null;
        recording_ts?: number | null;
        created_ts?: number | null;
        modified_ts?: number | null;
        archived_ts?: number | null;
        file_uploaded: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateDeviceAssociationSubscriptionVariables = {
  user_id?: string | null;
};

export type OnUpdateDeviceAssociationSubscription = {
  onUpdateDeviceAssociation?: {
    __typename: 'DeviceAssociation';
    id: string;
    org_id?: string | null;
    s3_bucket_name?: string | null;
    serial_number_id: string;
    status?: DeviceAssociationStatus | null;
    user_id: string;
    customerId: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer?: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null;
    device: {
      __typename: 'Device';
      serial_number_id: string;
      name?: string | null;
      description?: string | null;
      part_number?: string | null;
      hardware_revision?: string | null;
      firmware_revision?: string | null;
      last_calibration_ts?: number | null;
      thing_name?: string | null;
      thing_arn?: string | null;
      cert_arn?: string | null;
      manufacture_date?: string | null;
      last_uploaded_ts?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      associations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
    };
    files?: {
      __typename: 'ModelFileConnection';
      items?: Array<{
        __typename: 'File';
        id: string;
        user_id: string;
        serial_number_id?: string | null;
        sensor_name?: string | null;
        sensor_type?: string | null;
        file_name?: string | null;
        file_size?: number | null;
        s3_bucket_name?: string | null;
        s3_object_key: string;
        device_meta?: string | null;
        recording_length?: number | null;
        recording_ts?: number | null;
        created_ts?: number | null;
        modified_ts?: number | null;
        archived_ts?: number | null;
        file_uploaded: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteDeviceAssociationSubscriptionVariables = {
  user_id?: string | null;
};

export type OnDeleteDeviceAssociationSubscription = {
  onDeleteDeviceAssociation?: {
    __typename: 'DeviceAssociation';
    id: string;
    org_id?: string | null;
    s3_bucket_name?: string | null;
    serial_number_id: string;
    status?: DeviceAssociationStatus | null;
    user_id: string;
    customerId: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer?: {
      __typename: 'Customer';
      id: string;
      owner: string;
      storageConsumed?: number | null;
      storageSpaceRemaining?: boolean | null;
      tierId: TierName;
      createdAt?: string | null;
      updatedAt?: string | null;
      companyName?: string | null;
      logoImage?: string | null;
      apiKeys?: {
        __typename: 'ModelApiKeyConnection';
        nextToken?: string | null;
      } | null;
      billing?: {
        __typename: 'Billing';
        id: string;
        stripe_session_id: string;
        owner: string;
        stripe_customer_id: string;
        stripe_subscription_id?: string | null;
        subscription_status: BillingSubscriptionStatus;
        stripe_plan_id: StripePlanId;
        suspended_at?: number | null;
        next_billing_at?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      deviceAssociations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
      tier?: {
        __typename: 'Tier';
        id: TierName;
        costMonthly?: number | null;
        costYearly?: number | null;
        deviceMaxCount?: number | null;
        storageLimitGb?: number | null;
        alertMaxCount?: number | null;
        hasApiAccess?: boolean | null;
        billingCycles?: Array<BillingCycle | null> | null;
        reportMaxCount?: number | null;
        reportTemplate?: ReportTemplate | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
    } | null;
    device: {
      __typename: 'Device';
      serial_number_id: string;
      name?: string | null;
      description?: string | null;
      part_number?: string | null;
      hardware_revision?: string | null;
      firmware_revision?: string | null;
      last_calibration_ts?: number | null;
      thing_name?: string | null;
      thing_arn?: string | null;
      cert_arn?: string | null;
      manufacture_date?: string | null;
      last_uploaded_ts?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      associations?: {
        __typename: 'ModelDeviceAssociationConnection';
        nextToken?: string | null;
      } | null;
    };
    files?: {
      __typename: 'ModelFileConnection';
      items?: Array<{
        __typename: 'File';
        id: string;
        user_id: string;
        serial_number_id?: string | null;
        sensor_name?: string | null;
        sensor_type?: string | null;
        file_name?: string | null;
        file_size?: number | null;
        s3_bucket_name?: string | null;
        s3_object_key: string;
        device_meta?: string | null;
        recording_length?: number | null;
        recording_ts?: number | null;
        created_ts?: number | null;
        modified_ts?: number | null;
        archived_ts?: number | null;
        file_uploaded: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateFileSubscriptionVariables = {
  user_id?: string | null;
};

export type OnCreateFileSubscription = {
  onCreateFile?: {
    __typename: 'File';
    id: string;
    user_id: string;
    serial_number_id?: string | null;
    sensor_name?: string | null;
    sensor_type?: string | null;
    file_name?: string | null;
    file_size?: number | null;
    s3_bucket_name?: string | null;
    s3_object_key: string;
    device_meta?: string | null;
    recording_length?: number | null;
    recording_ts?: number | null;
    created_ts?: number | null;
    modified_ts?: number | null;
    archived_ts?: number | null;
    file_uploaded: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
    attributes?: {
      __typename: 'ModelAttributeConnection';
      items?: Array<{
        __typename: 'Attribute';
        id: string;
        user_id: string;
        attributeFileId: string;
        name: string;
        type: string;
        value: string;
        thing?: string | null;
        mutable: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    device?: {
      __typename: 'DeviceAssociation';
      id: string;
      org_id?: string | null;
      s3_bucket_name?: string | null;
      serial_number_id: string;
      status?: DeviceAssociationStatus | null;
      user_id: string;
      customerId: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer?: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      } | null;
      device: {
        __typename: 'Device';
        serial_number_id: string;
        name?: string | null;
        description?: string | null;
        part_number?: string | null;
        hardware_revision?: string | null;
        firmware_revision?: string | null;
        last_calibration_ts?: number | null;
        thing_name?: string | null;
        thing_arn?: string | null;
        cert_arn?: string | null;
        manufacture_date?: string | null;
        last_uploaded_ts?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      files?: {
        __typename: 'ModelFileConnection';
        nextToken?: string | null;
      } | null;
    } | null;
    tags?: {
      __typename: 'ModelTagConnection';
      items?: Array<{
        __typename: 'Tag';
        id: string;
        user_id: string;
        tagFileId: string;
        value: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateFileSubscriptionVariables = {
  user_id?: string | null;
};

export type OnUpdateFileSubscription = {
  onUpdateFile?: {
    __typename: 'File';
    id: string;
    user_id: string;
    serial_number_id?: string | null;
    sensor_name?: string | null;
    sensor_type?: string | null;
    file_name?: string | null;
    file_size?: number | null;
    s3_bucket_name?: string | null;
    s3_object_key: string;
    device_meta?: string | null;
    recording_length?: number | null;
    recording_ts?: number | null;
    created_ts?: number | null;
    modified_ts?: number | null;
    archived_ts?: number | null;
    file_uploaded: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
    attributes?: {
      __typename: 'ModelAttributeConnection';
      items?: Array<{
        __typename: 'Attribute';
        id: string;
        user_id: string;
        attributeFileId: string;
        name: string;
        type: string;
        value: string;
        thing?: string | null;
        mutable: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    device?: {
      __typename: 'DeviceAssociation';
      id: string;
      org_id?: string | null;
      s3_bucket_name?: string | null;
      serial_number_id: string;
      status?: DeviceAssociationStatus | null;
      user_id: string;
      customerId: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer?: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      } | null;
      device: {
        __typename: 'Device';
        serial_number_id: string;
        name?: string | null;
        description?: string | null;
        part_number?: string | null;
        hardware_revision?: string | null;
        firmware_revision?: string | null;
        last_calibration_ts?: number | null;
        thing_name?: string | null;
        thing_arn?: string | null;
        cert_arn?: string | null;
        manufacture_date?: string | null;
        last_uploaded_ts?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      files?: {
        __typename: 'ModelFileConnection';
        nextToken?: string | null;
      } | null;
    } | null;
    tags?: {
      __typename: 'ModelTagConnection';
      items?: Array<{
        __typename: 'Tag';
        id: string;
        user_id: string;
        tagFileId: string;
        value: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteFileSubscriptionVariables = {
  user_id?: string | null;
};

export type OnDeleteFileSubscription = {
  onDeleteFile?: {
    __typename: 'File';
    id: string;
    user_id: string;
    serial_number_id?: string | null;
    sensor_name?: string | null;
    sensor_type?: string | null;
    file_name?: string | null;
    file_size?: number | null;
    s3_bucket_name?: string | null;
    s3_object_key: string;
    device_meta?: string | null;
    recording_length?: number | null;
    recording_ts?: number | null;
    created_ts?: number | null;
    modified_ts?: number | null;
    archived_ts?: number | null;
    file_uploaded: boolean;
    createdAt?: string | null;
    updatedAt?: string | null;
    attributes?: {
      __typename: 'ModelAttributeConnection';
      items?: Array<{
        __typename: 'Attribute';
        id: string;
        user_id: string;
        attributeFileId: string;
        name: string;
        type: string;
        value: string;
        thing?: string | null;
        mutable: boolean;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    device?: {
      __typename: 'DeviceAssociation';
      id: string;
      org_id?: string | null;
      s3_bucket_name?: string | null;
      serial_number_id: string;
      status?: DeviceAssociationStatus | null;
      user_id: string;
      customerId: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer?: {
        __typename: 'Customer';
        id: string;
        owner: string;
        storageConsumed?: number | null;
        storageSpaceRemaining?: boolean | null;
        tierId: TierName;
        createdAt?: string | null;
        updatedAt?: string | null;
        companyName?: string | null;
        logoImage?: string | null;
      } | null;
      device: {
        __typename: 'Device';
        serial_number_id: string;
        name?: string | null;
        description?: string | null;
        part_number?: string | null;
        hardware_revision?: string | null;
        firmware_revision?: string | null;
        last_calibration_ts?: number | null;
        thing_name?: string | null;
        thing_arn?: string | null;
        cert_arn?: string | null;
        manufacture_date?: string | null;
        last_uploaded_ts?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      files?: {
        __typename: 'ModelFileConnection';
        nextToken?: string | null;
      } | null;
    } | null;
    tags?: {
      __typename: 'ModelTagConnection';
      items?: Array<{
        __typename: 'Tag';
        id: string;
        user_id: string;
        tagFileId: string;
        value: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateStripePlanSubscription = {
  onCreateStripePlan?: {
    __typename: 'StripePlan';
    id: StripePlanId;
    tierId: TierName;
    billingCycle?: BillingCycle | null;
    cost?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    tier: {
      __typename: 'Tier';
      id: TierName;
      costMonthly?: number | null;
      costYearly?: number | null;
      deviceMaxCount?: number | null;
      storageLimitGb?: number | null;
      alertMaxCount?: number | null;
      hasApiAccess?: boolean | null;
      billingCycles?: Array<BillingCycle | null> | null;
      reportMaxCount?: number | null;
      reportTemplate?: ReportTemplate | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stripePlans?: {
        __typename: 'ModelStripePlanConnection';
        nextToken?: string | null;
      } | null;
    };
  } | null;
};

export type OnUpdateStripePlanSubscription = {
  onUpdateStripePlan?: {
    __typename: 'StripePlan';
    id: StripePlanId;
    tierId: TierName;
    billingCycle?: BillingCycle | null;
    cost?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    tier: {
      __typename: 'Tier';
      id: TierName;
      costMonthly?: number | null;
      costYearly?: number | null;
      deviceMaxCount?: number | null;
      storageLimitGb?: number | null;
      alertMaxCount?: number | null;
      hasApiAccess?: boolean | null;
      billingCycles?: Array<BillingCycle | null> | null;
      reportMaxCount?: number | null;
      reportTemplate?: ReportTemplate | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stripePlans?: {
        __typename: 'ModelStripePlanConnection';
        nextToken?: string | null;
      } | null;
    };
  } | null;
};

export type OnDeleteStripePlanSubscription = {
  onDeleteStripePlan?: {
    __typename: 'StripePlan';
    id: StripePlanId;
    tierId: TierName;
    billingCycle?: BillingCycle | null;
    cost?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    tier: {
      __typename: 'Tier';
      id: TierName;
      costMonthly?: number | null;
      costYearly?: number | null;
      deviceMaxCount?: number | null;
      storageLimitGb?: number | null;
      alertMaxCount?: number | null;
      hasApiAccess?: boolean | null;
      billingCycles?: Array<BillingCycle | null> | null;
      reportMaxCount?: number | null;
      reportTemplate?: ReportTemplate | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stripePlans?: {
        __typename: 'ModelStripePlanConnection';
        nextToken?: string | null;
      } | null;
    };
  } | null;
};

export type OnCreateTagSubscriptionVariables = {
  user_id?: string | null;
};

export type OnCreateTagSubscription = {
  onCreateTag?: {
    __typename: 'Tag';
    id: string;
    user_id: string;
    tagFileId: string;
    value: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateTagSubscriptionVariables = {
  user_id?: string | null;
};

export type OnUpdateTagSubscription = {
  onUpdateTag?: {
    __typename: 'Tag';
    id: string;
    user_id: string;
    tagFileId: string;
    value: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteTagSubscriptionVariables = {
  user_id?: string | null;
};

export type OnDeleteTagSubscription = {
  onDeleteTag?: {
    __typename: 'Tag';
    id: string;
    user_id: string;
    tagFileId: string;
    value: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreateTierSubscription = {
  onCreateTier?: {
    __typename: 'Tier';
    id: TierName;
    costMonthly?: number | null;
    costYearly?: number | null;
    deviceMaxCount?: number | null;
    storageLimitGb?: number | null;
    alertMaxCount?: number | null;
    hasApiAccess?: boolean | null;
    billingCycles?: Array<BillingCycle | null> | null;
    reportMaxCount?: number | null;
    reportTemplate?: ReportTemplate | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    stripePlans?: {
      __typename: 'ModelStripePlanConnection';
      items?: Array<{
        __typename: 'StripePlan';
        id: StripePlanId;
        tierId: TierName;
        billingCycle?: BillingCycle | null;
        cost?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateTierSubscription = {
  onUpdateTier?: {
    __typename: 'Tier';
    id: TierName;
    costMonthly?: number | null;
    costYearly?: number | null;
    deviceMaxCount?: number | null;
    storageLimitGb?: number | null;
    alertMaxCount?: number | null;
    hasApiAccess?: boolean | null;
    billingCycles?: Array<BillingCycle | null> | null;
    reportMaxCount?: number | null;
    reportTemplate?: ReportTemplate | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    stripePlans?: {
      __typename: 'ModelStripePlanConnection';
      items?: Array<{
        __typename: 'StripePlan';
        id: StripePlanId;
        tierId: TierName;
        billingCycle?: BillingCycle | null;
        cost?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteTierSubscription = {
  onDeleteTier?: {
    __typename: 'Tier';
    id: TierName;
    costMonthly?: number | null;
    costYearly?: number | null;
    deviceMaxCount?: number | null;
    storageLimitGb?: number | null;
    alertMaxCount?: number | null;
    hasApiAccess?: boolean | null;
    billingCycles?: Array<BillingCycle | null> | null;
    reportMaxCount?: number | null;
    reportTemplate?: ReportTemplate | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    stripePlans?: {
      __typename: 'ModelStripePlanConnection';
      items?: Array<{
        __typename: 'StripePlan';
        id: StripePlanId;
        tierId: TierName;
        billingCycle?: BillingCycle | null;
        cost?: number | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};
