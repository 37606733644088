import gql from 'graphql-tag';
import { get } from 'lodash';
import * as log from 'loglevel';
import React, {
  createContext,
  ReactChild,
  useCallback,
  useContext,
  useMemo
} from 'react';
import { ApolloContext } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useStripeSessionApi from '.';
import { BillingSubscriptionStatus } from '../../API';
import { GlobalSiteAlertTypes } from '../../components/Header';
import { createCustomer, updateCustomer } from '../../graphql/mutations';
import { routeDashboard } from '../../helpers/RouteHelper';
import { AuthContext } from '../AuthContext';
import { LayoutContext } from '../LayoutContext';
import { ApiTypes, generateTierId, PostParams } from './ApiHelper';
import useCustomerApi from '../UseCustomerApi';
import { ApiTypes as CustomerApiTypes } from '../UseCustomerApi/ApiHelper';

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
// @ts-ignore

const stripe = stripeKey && new Stripe(stripeKey, {});

export interface StripeSessionContextExports {
  initializing: boolean;
  chooseTier: (
    tierCost: number,
    tierId: string,
    paymentPeriod: string,
    couponCode: string | null,
    handleError: (error?: Error) => void
  ) => void;
  switchActivePlan: (
    tierCost: number,
    tierId: string,
    paymentPeriod: string,
    couponCode: string | null,
    handleError: (error?: Error) => void
  ) => void;
  switchBilling: (backUrl: string, handleError?: () => void) => void;
  pushStripeError: (errorMessage: string, e?: Error) => void;
}

const StripeSessionContext = createContext<
  StripeSessionContextExports | undefined
>(undefined);
const { Provider, Consumer } = StripeSessionContext;

export { Consumer as StripeSessionConsumer, StripeSessionContext };

interface Props extends RouteComponentProps {
  children?: ReactChild;
}

export const StripeSessionProvider = withRouter(
  ({ history, children }: Props) => {
    const authContext = useContext(AuthContext);
    const layoutContext = useContext(LayoutContext);
    const apolloContext = useContext(ApolloContext);
    if (!authContext) {
      throw new Error(
        'StripeSessionProvider component must be used within an Auth Context Provider'
      );
    }
    if (!layoutContext) {
      throw new Error('Layout context is missing');
    }
    if (!apolloContext) {
      throw new Error(
        'AuthContext component must be used within an Apollo Context Provider'
      );
    }
    const { userInfo, customerData, refetchCustomerData } = authContext;
    const { pushGlobalSiteError, pushGlobalSiteAlerts } = layoutContext;
    const { client } = apolloContext;
    const currentStripeStatus = get(
      customerData,
      'billing.subscription_status'
    );
    const currentFrequency = get(customerData, 'billingFrequency');
    const ownerId = get(userInfo, 'sub');
    const { requestPost: initRequest } = useStripeSessionApi(
      ApiTypes['CREATE']
    );
    const { requestPost: cancelRequest } = useStripeSessionApi(
      ApiTypes['CANCEL']
    );
    const { requestPost: updateSubscription } = useStripeSessionApi(
      ApiTypes['UPDATE_SUB']
    );
    const { requestPost: updateBillingInfo } = useStripeSessionApi(
      ApiTypes['UPDATE_BILLING']
    );
    const { requestPost: completeFreeCustomer } = useCustomerApi(
      CustomerApiTypes['COMPLETE_FREE']
    );

    const pushStripeError = useCallback(
      (errorMessage: string, e?: Error) => {
        const errorDetailMessage = get(e, 'response.data.message');
        let fullErrorMessage: string | React.ReactChild = errorMessage;
        if (errorDetailMessage) {
          fullErrorMessage = (
            <span>
              {fullErrorMessage}:
              <br />
              {errorDetailMessage}
            </span>
          );
        }
        pushGlobalSiteError(fullErrorMessage);
      },
      [pushGlobalSiteError]
    );

    const choosePaidTier = useMemo(
      () => async (
        tierId: string,
        paymentPeriod: string,
        couponCode: string | null,
        handleError: (error?: Error) => void
      ) => {
        const selectedPlanId = generateTierId(tierId, paymentPeriod);
        refetchCustomerData();
        const body: PostParams['create'] = {
          success_url: `${window.location.origin}/dashboard`,
          cancel_url: `${window.location.origin}/plans`,
          promo_code: couponCode,
          plan_id: selectedPlanId
        };
        initRequest(body)
          .then((result: any) => {
            const { sessionId } = result;
            if (!stripe) {
              pushGlobalSiteError(
                'Stripe integration has not been set up on this portal.'
              );
            }
            return stripe.redirectToCheckout({
              sessionId
            });
          })
          .then((result: any) => {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          })
          .catch(handleError);
      },
      [initRequest, refetchCustomerData, pushGlobalSiteError]
    );

    const chooseFreeTier = useMemo(
      () => async (tierId: string, handleError: (e?: Error) => void) => {
        const completeSwitch = () => {
          refetchCustomerData();
          history.push({
            pathname: routeDashboard(),
            search: '?session_id=free'
          });
        };
        if (get(customerData, 'billing.stripe_subscription_id')) {
          const body: PostParams['cancel'] = {};
          await cancelRequest(body)
            .then(completeSwitch)
            .catch(handleError);
        } else {
          if (!client) {
            handleError();
            return;
          }
          if (!customerData) {
            await client
              .mutate({
                mutation: gql(createCustomer),
                variables: {
                  input: {
                    owner: ownerId,
                    tierId
                  }
                }
              })
              // post to the complete-free-customer API
              .then(completeFreeCustomer)
              .then(completeSwitch)
              .catch(handleError);
          } else {
            await client
              .mutate({
                mutation: gql(updateCustomer),
                variables: {
                  input: {
                    id: get(customerData, 'id'),
                    tierId
                  }
                }
              })
              .then(completeSwitch)
              .catch(handleError);
          }
        }
      },
      [
        cancelRequest,
        client,
        customerData,
        history,
        ownerId,
        refetchCustomerData,
        completeFreeCustomer
      ]
    );

    const switchActivePlan = useMemo(
      () => async (
        tierCost: number,
        tierId: string,
        paymentPeriod: string,
        couponCode: string | null,
        handleError: (error?: Error) => void
      ) => {
        const selectedPlanId = generateTierId(tierId, paymentPeriod);
        const body: PostParams['update'] = {
          plan_id: selectedPlanId
        };
        updateSubscription(body)
          .then((result: any) => {
            refetchCustomerData();
            pushGlobalSiteAlerts([GlobalSiteAlertTypes.frequencySelected]);
            history.push({
              pathname: routeDashboard()
            });
          })
          .catch(e => {
            log.error(
              'Error switching user plan. Attempting to select the plan directly.',
              e
            );
            if (tierCost > 0) {
              choosePaidTier(tierId, paymentPeriod, couponCode, handleError);
            } else {
              chooseFreeTier(tierId, handleError);
            }
          });
      },
      [
        updateSubscription,
        refetchCustomerData,
        history,
        pushGlobalSiteAlerts,
        chooseFreeTier,
        choosePaidTier
      ]
    );

    const chooseTier = useMemo(
      () => async (
        tierCost: number,
        tierId: string,
        paymentPeriod: string,
        couponCode: string | null,
        handleError: () => void
      ) => {
        const currentFrequencySet =
          !!currentFrequency && currentFrequency !== 'none';
        const frequencyChange =
          tierCost !== 0 &&
          currentFrequencySet &&
          currentFrequency !== paymentPeriod;

        if (tierCost === 0) {
          chooseFreeTier(tierId, handleError);
        } else if (
          frequencyChange ||
          currentStripeStatus === BillingSubscriptionStatus.active
        ) {
          switchActivePlan(
            tierCost,
            tierId,
            paymentPeriod,
            couponCode,
            handleError
          );
        } else {
          choosePaidTier(tierId, paymentPeriod, couponCode, handleError);
        }
      },
      [
        chooseFreeTier,
        choosePaidTier,
        currentStripeStatus,
        switchActivePlan,
        currentFrequency
      ]
    );

    const switchBilling = useMemo(
      () => async (backUrl: string, handleError?: () => void) => {
        const body: PostParams['updateBilling'] = {
          success_url: backUrl,
          cancel_url: backUrl
        };
        updateBillingInfo(body)
          .then((result: any) => {
            const { sessionId } = result;
            if (!stripe) {
              pushGlobalSiteError(
                'Stripe integration has not been set up on this portal.'
              );
            }
            return stripe.redirectToCheckout({
              sessionId
            });
          })
          .catch(e => {
            log.error('Error opening billing info', e);
            const errorDetailMessage = get(e, 'response.data.message');
            let fullErrorMessage: string | React.ReactChild =
              'Billing info could not be opened at this time';
            if (errorDetailMessage) {
              fullErrorMessage = (
                <span>
                  {fullErrorMessage}:
                  <br />
                  {errorDetailMessage}
                </span>
              );
            }
            pushGlobalSiteError(fullErrorMessage);
            if (handleError) {
              handleError();
            }
          });
      },
      [updateBillingInfo, pushGlobalSiteError]
    );

    const defaultContext = {
      initializing: customerData === null,
      chooseTier,
      switchActivePlan,
      switchBilling,
      pushStripeError
    };
    return <Provider value={defaultContext}>{children && children}</Provider>;
  }
);
