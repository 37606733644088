export const customerApiName = 'customer';
export const customerApiPathRoot = '/customer';

export interface PostParams {
  completeFree: {};
}

export enum ApiTypes {
  COMPLETE_FREE = 'completeFree'
}

export type ApiCallback = () => string;

export type ApiUrlsSignature = { [key in ApiTypes]: ApiCallback };

export const apiUrls: ApiUrlsSignature = {
  completeFree: () => `/complete-free`
};
