import {
  createStyles,
  Drawer,
  IconButton,
  List,
  ListSubheader,
  Theme,
  useMediaQuery,
  withStyles,
  WithStyles
} from '@material-ui/core';
import classnames from 'classnames';
import React, { useContext } from 'react';
import { drawerWidth } from '../../helpers/NavHelper';
import {
  routeAccount,
  routeAccountAlerts,
  routeAccountApiKeys,
  routeAccountBilling,
  routeAccountSubscription,
  routeDashboard,
  routeDeviceListAll,
  routeRecordingListAll,
  routeUserDashboard,
  routeUploadRecording
} from '../../helpers/RouteHelper';
import { AuthContext } from '../../hooks/AuthContext';
import { LayoutContext } from '../../hooks/LayoutContext';
import { MenuIcon } from '../../images/Icons';
import NavPrimaryItem from './NavPrimaryItem';

const styles = (theme: Theme) =>
  createStyles({
    menuButtonContainer: {
      height: 0,
      textAlign: 'left'
    },
    menuButton: {
      left: theme.spacing(1.5),
      top: theme.spacing(2),
      width: 28,
      zIndex: 31,
      padding: theme.spacing(0.5),
      background: theme.palette.primary.main,
      transform: 'rotate(45deg)',
      borderRadius: '4px',
      boxShadow:
        '2px 2px 4px -1px rgba(0, 0, 0, 0.2), 1px 1px 10px 0 rgba(0, 0, 0, 0.12), 4px 4px 5px 0 rgba(0, 0, 0, 0.14)',
      transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      '&:hover': {
        background: theme.palette.primary.main
      }
    },
    menuOpen: {
      left: 206
    },
    menuIcon: {
      transform: 'rotate(-45deg)',
      color: theme.palette.common.white
    },
    menuSubheader: {
      marginTop: theme.spacing(1),
      textAlign: 'left',
      color: '#ffffff',
      fontSize: 9,
      lineHeight: '28px',
      borderBottom: '1px solid rgba(255, 255, 255, 0.11)',
      margin: '0 20px'
    },
    authStatus: {
      padding: theme.spacing(2)
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      'z-index': '30 !important'
    },
    drawerPaper: {
      top: 'auto',
      width: drawerWidth,
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
      zIndex: 30
    },
    toolbar: theme.mixins.toolbar,
    toolbarSpacing: {
      minHeight: theme.spacing(8),
      [theme.breakpoints.up('sm')]: {
        minHeight: theme.spacing(9)
      },
      [theme.breakpoints.up('md')]: {
        minHeight: theme.spacing(0.5)
      }
    },
    customIcons: {},
    navListContainer: {
      overflow: 'auto',

      '@media print': {
        display: 'none'
      }
    }
  });

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
}

const NavPrimary = ({ classes, theme }: Props) => {
  const breakpointIsMdPlus = useMediaQuery(theme.breakpoints.up('md'));
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error(
      'Navigation component must be used within a Auth Context Provider'
    );
  }
  const { isAuthorized, signedIn, customerNeedsTier } = authContext;
  const layoutContext = useContext(LayoutContext);
  if (!layoutContext) {
    throw new Error(
      'Navigation component must be used within a Layout Context Provider'
    );
  }
  const { leftDrawerOpen, setLeftDrawerOpen } = layoutContext;
  const subItems: { [key: string]: any[] } = {
    'MAIN MENU': [
      {
        label: 'Home',
        pathname: routeDashboard()
      },
      {
        label: 'My Reports',
        pathname: routeUserDashboard()
      },
      {
        label: 'Upload Files',
        pathname: routeUploadRecording()
      },
      {
        label: 'My Recordings',
        pathname: routeRecordingListAll()
      },
      {
        label: 'My Sensors',
        pathname: routeDeviceListAll()
      }
    ],
    ACCOUNT: [
      {
        label: 'My Profile',
        pathname: routeAccount()
      },
      {
        label: 'Subscription',
        pathname: routeAccountSubscription()
      },
      {
        label: 'Billing Information',
        pathname: routeAccountBilling()
      }
    ],
    SETTINGS: [
      {
        label: 'API Access',
        pathname: routeAccountApiKeys()
      },
      {
        label: 'Alerts',
        pathname: routeAccountAlerts()
      }
    ],
    SUPPORT: [
      {
        label: 'Contact Us',
        pathname: 'https://endaq.com/pages/contact',
        external: true
      },
      {
        label: 'Help Center',
        pathname: 'https://support.endaq.com/',
        external: true
      },
      {
        label: 'Privacy Policy',
        pathname: 'https://endaq.com/pages/privacy-policy',
        external: true
      },
      {
        label: 'Terms and Conditions',
        pathname: 'https://endaq.com/pages/terms-conditions',
        external: true
      },
      {
        label: 'enDAQ.com',
        pathname: 'https://endaq.com/',
        external: true
      }
    ]
  };

  const handleDrawerToggle = () => {
    setLeftDrawerOpen(!leftDrawerOpen);
  };
  const closeDrawer = () => {
    setLeftDrawerOpen(false);
  };

  if (customerNeedsTier || !signedIn) return null;
  return (
    <div role="navigation">
      {isAuthorized && signedIn && (
        <div className={classes.menuButtonContainer}>
          <IconButton
            aria-haspopup
            aria-expanded={leftDrawerOpen}
            aria-controls="nav-primary-drawer"
            color="inherit"
            aria-label="Open navigation drawer"
            onClick={handleDrawerToggle}
            classes={{ root: classes.menuButton }}
            className={leftDrawerOpen ? classes.menuOpen : ''}
          >
            <MenuIcon fontSize="small" className={classes.menuIcon} />
          </IconButton>
        </div>
      )}
      <Drawer
        id="nav-primary-drawer"
        variant={breakpointIsMdPlus ? 'persistent' : 'temporary'}
        open={leftDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
          root: classes.drawer
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        onClose={closeDrawer}
      >
        <div className={classnames(classes.toolbarSpacing, classes.toolbar)} />
        <div className={classes.navListContainer}>
          <List>
            {Object.keys(subItems).map(key => (
              <React.Fragment key={`nav-item-${key}`}>
                <ListSubheader
                  disableSticky
                  disableGutters
                  className={classes.menuSubheader}
                >
                  {key}
                </ListSubheader>
                {subItems[key].map(({ label, pathname, external }, n) => (
                  <NavPrimaryItem
                    label={label}
                    path={pathname}
                    pathname={pathname}
                    name={`subitems-${n}`}
                    key={`subitems-${n}`}
                    closeDrawer={breakpointIsMdPlus ? null : closeDrawer}
                    external={external}
                  />
                ))}
              </React.Fragment>
            ))}
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(NavPrimary);
