export const fileApiName = 'file';
export const fileApiPathRoot = '/file';

export interface FileInputType {
  name: string;
  type: string;
  size: number;
  url: string;
}
export interface PostParams {
  download:
    | {
        file_id: string;
        expiration?: string;
      }
    | { [key: string]: any }[];
  upload: {
    files: FileInputType[];
  };
}

export interface PostResponses {
  download: {
    url: string;
    file_name: string;
  };
  upload: {
    files: {
      file: FileInputType;
      id: string;
      s3_object_key: string;
      expiration: number;
      signed_url: {
        url: string;
        fields: {
          key: string;
          AWSAccessKeyId: string;
          'x-amz-security-token': string;
          policy: string;
          signature: string;
        };
      };
    }[];
    errors: {
      file: {
        name: string;
        size: number;
        type: string;
      };
      error: {
        status: number;
        message: string;
      };
    }[];
  };
}

export enum ApiTypes {
  DOWNLOAD = 'download',
  UPLOAD = 'upload',
  DELETE = 'delete'
}
export type ApiCallback = () => string;
export type ApiUrlsSignature = { [key in ApiTypes]: ApiCallback };

export const apiUrls: ApiUrlsSignature = {
  download: () => `/download`,
  upload: () => `/upload-url`,
  delete: () => `/remove`
};
