import React from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom';
import { routePlans } from '../../helpers/RouteHelper';
import { StripeSessionProvider } from '../../hooks/UseStripeSessionApi/StripeSessionContext';
import PlanListAll from './ListAll';

interface Props extends RouteComponentProps {
  ListAll?: React.ComponentType;
}

const Plans: React.FC<Props> = () => {
  return (
    <StripeSessionProvider>
      <Switch>
        <Route exact path={routePlans()} component={PlanListAll} />
      </Switch>
    </StripeSessionProvider>
  );
};
export default withRouter(Plans);
