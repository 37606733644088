import { FormHelperText, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import {
  OnStateChange,
  signInNavigate,
  signUpAction
} from '../../../helpers/AmplifyAuthHelpers';
import { ErrorOutlineIcon } from '../../../images/Icons';
import { ReactComponent as Logotype } from '../../../images/Logos/Logotype.svg';
import Copyright from '../../Copyright';
import FieldPassword from '../FieldPassword';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  paper: {
    padding: theme.spacing(3, 3, 4),
    marginBottom: theme.spacing(3),
    minWidth: 400,
    borderColor: '#999'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(3, 0),
    fontSize: 16
  },
  logoWrapper: {
    marginBottom: theme.spacing(3.5),
    width: 250
  },
  helperText: {
    margin: theme.spacing(-0.5, 0, 2),
    alignItems: 'center',
    display: 'flex'
  },
  formHelperIcon: {
    height: 16
  },
  bottomButtons: {
    flexDirection: 'column'
  },
  pageError: {
    margin: theme.spacing(2, 0)
  }
}));

interface Props {
  authState?: string;
  onStateChange?: OnStateChange;
  override?: string;
}

const CustomSignUp = (props: Props) => {
  const { authState, onStateChange } = props;
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [alertUsername, setAlertUsername] = useState('');
  const [alertPassword, setAlertPassword] = useState('');
  const [alertAcceptTerms, setAlertAcceptTerms] = useState('');
  const [pageError, setPageError] = useState('');

  useEffect(() => {
    setAlertUsername('');
  }, [username]);
  useEffect(() => {
    setAlertAcceptTerms('');
  }, [acceptTerms]);
  useEffect(() => {
    if (authState !== 'signUp') return;
    setUsername('');
    setPassword('');
    setAcceptTerms(false);
    setAlertUsername('');
    setAlertPassword('');
    setAlertAcceptTerms('');
    setPageError('');
  }, [authState]);

  const handleSignIn = useCallback(() => {
    signInNavigate(onStateChange);
  }, [onStateChange]);

  const handleSignUp = useCallback(
    (event: any) => {
      if (event) {
        event.preventDefault();
      }
      signUpAction({
        username,
        password,
        acceptTerms,
        setAlertUsername,
        setAlertPassword,
        setAlertAcceptTerms,
        setLoading,
        setPageError,
        onStateChange
      });
    },
    [
      username,
      password,
      acceptTerms,
      setAlertUsername,
      setAlertPassword,
      setAlertAcceptTerms,
      setLoading,
      setPageError,
      onStateChange
    ]
  );

  const classes = useStyles();

  if (authState !== 'signUp') return null;
  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.container}>
          <div className={classes.logoWrapper}>
            <Logotype />
          </div>
          <Paper className={classes.paper} variant="outlined">
            <Typography component="h1" variant="h5" align="center">
              Start using enDAQ Cloud
            </Typography>
            {pageError && (
              <Alert
                className={classes.pageError}
                variant="filled"
                severity="error"
                onClose={() => setPageError('')}
              >
                {pageError}
              </Alert>
            )}
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="off"
                inputProps={{
                  autoCapitalize: 'none'
                }}
                value={username}
                onChange={event => {
                  setUsername(event.currentTarget.value);
                }}
                error={!!alertUsername}
              />
              <FormHelperText
                className={classes.helperText}
                error={!!alertUsername}
              >
                {alertUsername && (
                  <ErrorOutlineIcon className={classes.formHelperIcon} />
                )}
                {alertUsername || 'Required'}
              </FormHelperText>
              <FieldPassword
                alertPassword={alertPassword}
                password={password}
                requirementTexts="Required and must be at least 8 characters"
                setAlertPassword={setAlertPassword}
                setPassword={setPassword}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="acceptTerms"
                    color="primary"
                    checked={acceptTerms}
                    onChange={event => {
                      setAcceptTerms(!acceptTerms);
                    }}
                    value={acceptTerms}
                    inputProps={{
                      'aria-label': 'I agree to Privacy Policy and Terms of Use'
                    }}
                  />
                }
                label={[
                  'I agree to ',
                  <Link
                    href="https://endaq.com/pages/privacy-policy"
                    target="_blank"
                    rel="noopener"
                    color="secondary"
                    underline="always"
                  >
                    Privacy Policy
                  </Link>,
                  ' and ',
                  <Link
                    href="https://endaq.com/pages/terms-conditions"
                    target="_blank"
                    rel="noopener"
                    color="secondary"
                    underline="always"
                  >
                    Terms of Use
                  </Link>
                ]}
              />
              {alertAcceptTerms && (
                <FormHelperText className={classes.helperText} error>
                  <ErrorOutlineIcon className={classes.formHelperIcon} />
                  {alertAcceptTerms}
                </FormHelperText>
              )}
              <Button
                disabled={loading}
                disableFocusRipple
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.submit}
                onClick={handleSignUp}
              >
                Set up a Free Account
              </Button>
              <Grid
                container
                justify="flex-end"
                className={classes.bottomButtons}
              >
                <Grid item container alignItems="center">
                  <Typography component="span" variant="body1">
                    Already have an account?{' '}
                    <Button
                      disableFocusRipple
                      color="secondary"
                      onClick={handleSignIn}
                    >
                      Sign In
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </Paper>
          <Copyright />
        </div>
      </Container>
    </>
  );
};

export default CustomSignUp;
