import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme
} from '@material-ui/core';
import React from 'react';
import { stylesLayout } from '../../helpers/StyleHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...stylesLayout
  })
);

interface Props {
  children?: React.ReactChild;
}

const TextOnly: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <Container
      maxWidth="md"
      className={classes.container}
      data-testid="template-text-only"
    >
      <Grid
        className={classes.grid}
        container
        direction="column"
        justify="space-around"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={9}
          zeroMinWidth
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.gridTop}
        >
          {children && children}
        </Grid>
      </Grid>
    </Container>
  );
};
export default TextOnly;
