import {
  FormHelperText,
  IconButton,
  InputAdornment,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import {
  ErrorOutlineIcon,
  Visibility,
  VisibilityOff
} from '../../../images/Icons';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 20
  },
  helperText: {
    margin: theme.spacing(-0.5, 0, 2),
    alignItems: 'center',
    display: 'flex'
  },
  helperTextPortal: {
    margin: theme.spacing(0.5, 0, 0, 0),
    maxWidth: 230,
    lineHeight: '1.25em',
    display: 'flex',
    alignItems: 'flex-start'
  },
  formHelperIcon: {
    height: 16
  }
}));

interface Props {
  alertPassword: string;
  label?: string;
  password: string;
  requirementTexts?: string;
  setAlertPassword: React.Dispatch<any>;
  setPassword: React.Dispatch<any>;
  inPortal?: boolean;
  inputRef?: any;
  inputId?: string;
}

const FieldPassword = (props: Props) => {
  const {
    alertPassword,
    label,
    password,
    requirementTexts,
    setAlertPassword,
    setPassword,
    inPortal,
    inputRef,
    inputId
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setAlertPassword('');
  }, [password, setAlertPassword]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const classes = useStyles();
  const passwordLabel = label || 'Password';

  let textFieldProps = {};
  if (inPortal) {
    textFieldProps = {
      ...textFieldProps
    };
  } else {
    textFieldProps = {
      ...textFieldProps,
      label: passwordLabel,
      margin: 'normal'
    };
  }

  return (
    <div className={classes.container}>
      {inPortal && (
        <Typography
          variant="subtitle1"
          component="p"
          color={alertPassword ? 'error' : 'initial'}
        >
          <label htmlFor={inputId || 'password'}>{passwordLabel}</label>
        </Typography>
      )}
      <TextField
        variant="outlined"
        fullWidth
        name="password"
        type={showPassword ? 'text' : 'password'}
        autoComplete="off"
        inputProps={{
          autoCapitalize: 'none'
        }}
        value={password}
        onChange={event => {
          setPassword(event.currentTarget.value);
        }}
        error={!!alertPassword}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
        ref={inputRef}
        id={inputId || 'password'}
        {...textFieldProps}
      />
      <FormHelperText
        className={classnames(
          classes.helperText,
          inPortal && classes.helperTextPortal
        )}
        error={!!alertPassword}
      >
        {alertPassword && (
          <ErrorOutlineIcon className={classes.formHelperIcon} />
        )}
        {alertPassword || requirementTexts}
      </FormHelperText>
    </div>
  );
};

export default FieldPassword;
