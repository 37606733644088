import {
  Button,
  Checkbox,
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import { findIndex, get, map, remove, startCase } from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import { pluralizeString } from '../../../../helpers/DataHelper';
import { LayoutContext } from '../../../../hooks/LayoutContext';
import UsePairingApi from '../../../../hooks/UsePairingApi';
import {
  ApiTypes,
  PostParams
} from '../../../../hooks/UsePairingApi/ApiHelper';

interface Props {
  newTierId: string;
  customerSensors: any[] | null;
  maxSensors: number;
  leaveDowngrade: () => void;
  continueDowngrade: () => void;
  setLoading: React.Dispatch<any>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogText: {
      margin: 0
    },
    checkboxLabelRoot: {
      margin: 0,
      display: 'flex',
      flex: '1 0 0'
    },
    checkboxLabel: {
      textTransform: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      flex: '1 0 auto',
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    customerItem: {
      justifyContent: 'space-between',
      paddingRight: theme.spacing(3),
      flexWrap: 'nowrap',
      borderBottom: `1px solid ${theme.palette.grey[500]}`
    },
    listContainer: {
      maxHeight: 210,
      overflowY: 'scroll',
      padding: theme.spacing(1, 1.5)
    }
  })
);

const SensorsError = (props: Props) => {
  const {
    newTierId,
    customerSensors,
    maxSensors,
    leaveDowngrade,
    continueDowngrade,
    setLoading
  } = props;

  const classes = useStyles();

  const layoutContext = useContext(LayoutContext);
  if (!layoutContext) {
    throw new Error(
      'App component must be used within a Layout Context Provider'
    );
  }
  const { pushGlobalSiteError } = layoutContext;

  const [enabledItems, setEnabledItems] = useState<string[]>([]);

  const generateCheckToggleAction = (itemId: string) => () => {
    let newEnabledItems: string[] = [...enabledItems];
    const isDisabled = remove(
      newEnabledItems,
      enabledItem => enabledItem === itemId
    );
    if (isDisabled.length <= 0) {
      newEnabledItems = [...newEnabledItems, itemId];
    }
    setEnabledItems(newEnabledItems);
  };
  const errorEnabledAtMax = enabledItems.length >= maxSensors;
  const { requestPost: unlinkRequest } = UsePairingApi(ApiTypes['UNLINK']);

  const applyAndContinue = useCallback(async () => {
    if (!customerSensors) return;
    setLoading(true);
    const sensorsToUnlink = customerSensors.filter(
      customerItem =>
        findIndex(
          enabledItems,
          enabledSensor =>
            enabledSensor === get(customerItem, 'serial_number_id')
        ) < 0
    );
    await Promise.all(
      map(sensorsToUnlink, (customerItem: any) => {
        const body: PostParams['unlink'] = {
          serial_number_id: get(customerItem, 'serial_number_id'),
          thing_name: get(customerItem, 'device.thing_name'),
          factory_reset: true
        };
        return unlinkRequest(body);
      })
    )
      .then(() => {
        continueDowngrade();
      })
      .catch(err => {
        setLoading(false);
        pushGlobalSiteError(
          'Error encountered while unlinking sensors. Please try again.'
        );
      });
  }, [
    customerSensors,
    enabledItems,
    continueDowngrade,
    pushGlobalSiteError,
    unlinkRequest,
    setLoading
  ]);

  if (!customerSensors) return null;

  return (
    <>
      <DialogTitle id="form-dialog-title">Manage Sensors</DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: classes.dialogText }}>
          You can only link up to {maxSensors}{' '}
          {pluralizeString('sensor', maxSensors)} for the {startCase(newTierId)}{' '}
          Plan. Please select the {pluralizeString('sensor', maxSensors)} you
          want to keep linked to your account.
        </DialogContentText>
      </DialogContent>
      <Grid container className={classes.listContainer}>
        {customerSensors.map((customerItem: any) => {
          const itemSerialNum = get(customerItem, 'serial_number_id');
          const itemName = get(customerItem, 'device.name');
          const itemIsEnabled =
            findIndex(
              enabledItems,
              enabledSensor => enabledSensor === itemSerialNum
            ) >= 0;

          return (
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justify="center"
              key={`sensor-item-${customerItem.id}`}
              className={classes.customerItem}
            >
              <FormControlLabel
                classes={{
                  root: classes.checkboxLabelRoot,
                  label: classes.checkboxLabel
                }}
                control={
                  <Checkbox
                    value="sensorCheck"
                    color="secondary"
                    checked={itemIsEnabled}
                    disabled={errorEnabledAtMax && !itemIsEnabled}
                    onClick={generateCheckToggleAction(itemSerialNum)}
                  />
                }
                label={
                  <>
                    <Typography variant="body2" component="div">
                      {itemSerialNum}
                    </Typography>
                    <Typography variant="body2" component="div">
                      {itemName}
                    </Typography>
                  </>
                }
              />
            </Grid>
          );
        })}
      </Grid>
      <DialogActions disableSpacing>
        <Button
          variant="text"
          size="large"
          color="default"
          onClick={leaveDowngrade}
        >
          Cancel
        </Button>
        <Button
          variant="text"
          size="large"
          color="default"
          onClick={applyAndContinue}
        >
          Apply
        </Button>
      </DialogActions>
    </>
  );
};

export default SensorsError;
