import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, {
  createContext,
  ReactChild,
  useEffect,
  useMemo,
  useState
} from 'react';
import { GlobalSiteAlertTypes } from '../../components/Header';

export interface LayoutContextExports {
  darkerBackground: boolean;
  globalSiteAlerts: GlobalSiteAlertTypes[];
  globalSiteErrors: (string | React.ReactChild)[];
  leftDrawerOpen: boolean;
  loading: boolean;
  pageTitle: string;
  prevPageTitle: string[];
  rightDrawerOpen: boolean;
  setDarkerBackground: React.Dispatch<any>;
  pushGlobalSiteAlerts: (newAlerts: GlobalSiteAlertTypes[]) => void;
  pushGlobalSiteError: (newError: string | React.ReactChild) => void;
  removeGlobalSiteAlert: (alertId: number) => void;
  removeGlobalSiteError: (errorId: number) => void;
  setLeftDrawerOpen: React.Dispatch<any>;
  setLoading: React.Dispatch<any>;
  setPageTitle: React.Dispatch<any>;
  setPrevPageTitle: React.Dispatch<string[] | []>;
  setRightDrawerOpen: React.Dispatch<any>;
}

export const LayoutContext = createContext<LayoutContextExports | undefined>(
  undefined
);
const { Provider } = LayoutContext;

export interface LayoutProviderProps {
  children?: ReactChild;
}

export const LayoutProvider = (props: LayoutProviderProps) => {
  const theme = useTheme();
  const newIsMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [pageTitle, setPageTitle] = useState('');
  const [prevPageTitle, setPrevPageTitle] = useState<string[] | []>([]);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [darkerBackground, setDarkerBackground] = useState(false);
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(!isMobile);
  const [globalSiteAlerts, setGlobalSiteAlerts] = useState<
    GlobalSiteAlertTypes[]
  >([]);
  const [globalSiteErrors, setGlobalSiteErrors] = useState<
    (string | React.ReactChild)[]
  >([]);
  const pushGlobalSiteAlerts = useMemo(
    () => (newAlerts: GlobalSiteAlertTypes[]) => {
      setGlobalSiteAlerts(currentAlerts => [...currentAlerts, ...newAlerts]);
    },
    []
  );
  const removeGlobalSiteAlert = useMemo(
    () => (alertId: number) => {
      setGlobalSiteAlerts(currentAlerts => {
        const newAlerts = [...currentAlerts];
        newAlerts.splice(alertId, 1);
        return newAlerts;
      });
    },
    []
  );
  const pushGlobalSiteError = useMemo(
    () => (newError: string | React.ReactChild) => {
      setGlobalSiteErrors(currentErrors => [...currentErrors, newError]);
    },
    []
  );
  const removeGlobalSiteError = useMemo(
    () => (errorId: number) => {
      setGlobalSiteErrors(currentErrors => {
        const newErrors = [...currentErrors];
        newErrors.splice(errorId, 1);
        return newErrors;
      });
    },
    []
  );

  if (isMobile !== newIsMobile) {
    setIsMobile(newIsMobile);
  }
  useEffect(() => {
    setLeftDrawerOpen(!isMobile);
  }, [isMobile]);

  const defaultContext = {
    darkerBackground,
    loading,
    leftDrawerOpen,
    globalSiteAlerts,
    globalSiteErrors,
    pageTitle,
    prevPageTitle,
    rightDrawerOpen,
    setDarkerBackground,
    pushGlobalSiteAlerts,
    pushGlobalSiteError,
    removeGlobalSiteAlert,
    removeGlobalSiteError,
    setLeftDrawerOpen,
    setLoading,
    setPageTitle,
    setPrevPageTitle,
    setRightDrawerOpen
  };
  return (
    <Provider value={defaultContext}>
      {props.children && props.children}
    </Provider>
  );
};
