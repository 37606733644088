import { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import Plans from '../screens/Plans';

export enum DeviceCreateStep {
  Form = 'form',
  FormError = 'form-error',
  Challenge = 'challenge',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Expired = 'expired',
  Wait = 'wait'
}

export enum DeviceCreateVariant {
  Default = 'default',
  Duplicate = 'duplicate',
  Conflict = 'conflict'
}

export enum StripeSessionStep {}

const UserDashboard = lazy(() => import('../screens/Account/Dashboard'));
const UserCustomScripts = lazy(() =>
  import('../screens/Account/Dashboard/ManageCustomScripts')
);
const UserDefaultScripts = lazy(() =>
  import('../screens/Account/Dashboard/ViewDefaultScripts')
);
const Dashboard = lazy(() => import('../screens/Dashboard'));
const Account = lazy(() => import('../screens/Account'));
const DashboardShare = lazy(() => import('../screens/SharedDashboard'));
const File = lazy(() => import('../screens/File'));
const Device = lazy(() => import('../screens/Device'));

export const routeUserDashboard: () => string = () => '/user/dashboard';
export const routeSingleDashboard: () => string = () =>
  '/user/dashboard/:dashboardId';
export const routeSingleDashboardUrl: (
  dashboardId: string
) => string = dashboardId => `/user/dashboard/${dashboardId}`;
export const routeViewSharedDashboard: () => string = () =>
  '/shared/dashboard/:shareToken';
export const routeViewSharedDashboardUrl: (
  shareToken: string
) => string = shareToken => `/shared/dashboard/${shareToken}`;
export const routeUserCustomScripts: () => string = () =>
  '/user/manage-scripts';
export const routeDefaultScripts: () => string = () =>
  '/user/view-default-scripts';
export const routeDashboard: () => string = () => '/dashboard';
export const routeAccount: () => string = () => '/account';
export const routeAccountAlerts: () => string = () => '/account/alerts';
export const routeAccountApiKeys: () => string = () => '/account/api-keys';
export const routeDevice: () => string = () => '/sensors';
export const routeDeviceAdd: (
  regex: boolean,
  step?: DeviceCreateStep,
  variant?: DeviceCreateVariant
) => string = (regex, step, variant) => {
  const stepMatch = `/:step(${Object.values(DeviceCreateStep).join('|')})?`;
  const variantMatch = `/:variant(${Object.values(DeviceCreateVariant).join(
    '|'
  )})?`;
  let exp = `/sensors/add${stepMatch}${variantMatch}`;
  let path =
    step || !regex
      ? exp.replace(
          stepMatch,
          typeof step === 'string' && step.length ? `/${step}` : ''
        )
      : exp;
  path =
    variant || !regex
      ? path.replace(
          variantMatch,
          typeof variant === 'string' && variant.length ? `/${variant}` : ''
        )
      : path;
  return path;
};
export const routeDeviceListAll: () => string = () => '/sensors/list-all';
export const routeRecording: () => string = () => '/file';
export const routeRecordingListAll: () => string = () => '/file/list-all';
export const routeUploadRecording: () => string = (): string =>
  '/file/list-all?upload=True';
export const routePlans: () => string = () => '/plans';
export const routeAccountBilling: () => string = () => '/account/billing';
export const routeAccountSubscription: () => string = () =>
  '/account/subscription';
export const routeAccountChangePassword: () => string = () =>
  '/account/change-password';
export const routeAccountReportSettings: () => string = () =>
  '/account/custom-report-settings';

export const PrivateScreenRoutes: RouteProps[] = [
  {
    path: routeDashboard(),
    component: Dashboard,
    exact: true
  },
  {
    path: routeAccount(),
    component: Account,
    exact: true
  },
  {
    path: routeAccountAlerts(),
    component: Account,
    exact: true
  },
  {
    path: routeAccountApiKeys(),
    component: Account,
    exact: true
  },
  {
    path: routeAccountBilling(),
    component: Account,
    exact: true
  },
  {
    path: routeAccountSubscription(),
    component: Account,
    exact: true
  },
  {
    path: routeAccountChangePassword(),
    component: Account,
    exact: true
  },
  {
    path: routeAccountReportSettings(),
    component: Account,
    exact: true
  },
  {
    path: routeDevice(),
    component: Device,
    exact: true
  },
  {
    path: routeDeviceAdd(true),
    component: Device,
    exact: true
  },
  {
    path: routeDeviceListAll(),
    component: Device,
    exact: true
  },
  {
    path: routeRecording(),
    component: File,
    exact: true
  },
  {
    path: routeRecordingListAll(),
    component: File,
    exact: true
  },
  {
    path: routePlans(),
    component: Plans,
    exact: true
  },
  {
    path: routeUserDashboard(),
    component: UserDashboard,
    exact: true
  },
  {
    path: routeSingleDashboard(),
    component: UserDashboard,
    exact: true
  },
  {
    path: routeUserCustomScripts(),
    component: UserCustomScripts,
    exact: true
  },
  {
    path: routeDefaultScripts(),
    component: UserDefaultScripts,
    exact: true
  }
];

export const PublicScreenRoutes: RouteProps[] = [
  {
    path: routeViewSharedDashboard(),
    component: DashboardShare,
    exact: false
  }
];
