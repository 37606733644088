import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import React from 'react';
import { startCase } from 'lodash';

interface Props {
  newTierId?: string;
  leaveDowngrade: () => void;
  continueDowngrade: () => void;
}

const StorageError = (props: Props) => {
  const { newTierId, leaveDowngrade, continueDowngrade } = props;

  return (
    <>
      <DialogTitle id="form-dialog-title">Manage Recordings</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You will exceed the storage limit for {startCase(newTierId)} Plan.
          Once your account is downgraded, you will not be able to continue to
          upload additional recordings. Your sensors will stop uploading data
          automatically. Existing recordings will remain in My Recordings.
        </DialogContentText>
      </DialogContent>
      <DialogActions disableSpacing>
        <Button
          variant="text"
          size="large"
          color="default"
          onClick={leaveDowngrade}
        >
          Cancel
        </Button>
        <Button
          variant="text"
          size="large"
          color="default"
          onClick={continueDowngrade}
        >
          OK
        </Button>
      </DialogActions>
    </>
  );
};

export default StorageError;
