import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import classnames from 'classnames';
import React, { useContext, useState } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { getPageTitle } from '../../helpers/DataHelper';
import { routePlans } from '../../helpers/RouteHelper';
import { StripeSessionContext } from '../../hooks/UseStripeSessionApi/StripeSessionContext';
import PlanCard from './Components/PlanCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexShrink: 0,
      flexDirection: 'column',
      alignItems: 'center'
    },
    paper: {
      padding: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(12.5)
      }
    },
    cardHeader: {
      marginBottom: theme.spacing(1),
      textAlign: 'center'
    },
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: '#fff'
    }
  })
);

interface Props {}

const SuspendedOverlay = (props: Props) => {
  const pageTitle = 'Suspended Account';
  const stripeSessionContext = useContext(StripeSessionContext);
  if (!stripeSessionContext) {
    throw new Error('Stripe session context is missing');
  }
  const { initializing } = stripeSessionContext;
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  if (initializing) return null;

  return (
    <>
      <Helmet>
        <title>{getPageTitle(pageTitle)}</title>
      </Helmet>
      <Backdrop className={classes.backdrop} open>
        {loading && <CircularProgress color="inherit" />}
        {!loading && (
          <Paper className={classes.paper}>
            <Grid container className={classes.header} spacing={2}>
              <Grid container item alignItems="center" direction="column">
                <Typography variant="h1" className={classes.cardHeader}>
                  Your enDAQ Cloud Service Account is Suspended
                </Typography>
                <Typography variant="body1">
                  Choose one of the following to reactivate your account.
                </Typography>
              </Grid>
              <Grid item>
                <PlanCard disableCta={initializing} setLoading={setLoading} />
              </Grid>
              <Grid item>
                <Typography variant="body1">OR</Typography>
              </Grid>
              <Grid item>
                <Button
                  component={Link}
                  to={routePlans()}
                  variant="outlined"
                  color="primary"
                  size="large"
                  className={classnames(classes.button, classes.planButton)}
                >
                  Change Plan
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Backdrop>
    </>
  );
};

export default SuspendedOverlay;
