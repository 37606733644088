export const pairingApiName = 'pairing';
export const pairingApiPathRoot = '/pairing';

export enum ApiInitErrorStatus {
  PAIRING_INIT_DUPLICATE = 'PAIRING_INIT_DUPLICATE',
  PAIRING_INIT_CONFLICT = 'PAIRING_INIT_CONFLICT'
}

export interface PostParams {
  init: {
    serial_number_id: string;
    user_id: string;
    identity_id: string;
  };
  confirm: {
    user_id: string;
    association_id: string;
  };
  unlink: {
    serial_number_id: string;
    thing_name: string;
    factory_reset: boolean;
  };
  wait: {
    user_id: string;
    association_id: string;
  };
}

export enum ApiTypes {
  INIT = 'init',
  CONFIRM = 'confirm',
  UNLINK = 'unlink',
  WAIT = 'wait'
}

export type ApiCallback = () => string;

export type ApiUrlsSignature = { [key in ApiTypes]: ApiCallback };

export const apiUrls: ApiUrlsSignature = {
  init: () => `/init`,
  confirm: () => `/confirm`,
  unlink: () => `/remove`,
  wait: () => '/confirm-device-response'
};
