import {
  createStyles,
  LinearProgress,
  makeStyles,
  Snackbar,
  Theme,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import classnames from 'classnames';
import { get, toString } from 'lodash';
import queryString from 'query-string';
import React, { lazy, Suspense, useContext, useEffect } from 'react';
import Div100vh from 'react-div-100vh';
import Helmet from 'react-helmet';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom';
import { BillingSubscriptionStatus } from './API';
import BreadCrumbNavigation from './components/BreadCrumbNavigation';
import ErrorBoundary from './components/ErrorBoundary';
import Footer from './components/Footer';
import Header, { GlobalSiteAlertTypes } from './components/Header';
import PrivateRoute from './components/PrivateRoute';
import { getPageTitle } from './helpers/DataHelper';
import { drawerWidth } from './helpers/NavHelper';
import { PrivateScreenRoutes, PublicScreenRoutes } from './helpers/RouteHelper';
import { AuthContext } from './hooks/AuthContext';
import { LayoutContext } from './hooks/LayoutContext';

const NotFound = lazy(() => import('./screens/NotFound'));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    App: {
      textAlign: 'left'
    },
    hasDarkerBackground: {
      background: theme.palette.grey[100]
    },
    hasRightDrawer: {},
    leftDrawerOpen: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: drawerWidth - 24
      }
    },
    mainContentSignedIn: {
      transition: theme.transitions.create(['padding', 'margin'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    Site: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    SiteContent: {
      flex: '1 0 auto',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '&::after': {
        content: "' '",
        display: 'block',
        height: 0,
        visibility: 'hidden'
      }
    },
    pageTitle: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(5)
      },
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    mainContent: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 0px',
      margin: theme.spacing(2, 0, 0, 2),
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(2, 0, 0, 7.25)
      }
    },
    mainContentPublic: {
      margin: 0,
      [theme.breakpoints.up('md')]: {
        margin: 0
      }
    },
    screenContainer: {
      overflow: 'auto',
      marginTop: theme.spacing(1.5),
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 0px'
    },
    screenContainerPublic: {
      marginTop: 0
    },
    suspenseLoader: {
      zIndex: theme.zIndex.appBar,
      marginTop: theme.spacing(-0.5)
    },
    errorSnackbar: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    errorAlert: {
      marginTop: theme.spacing(1)
    }
  })
);

interface Props extends RouteComponentProps {
  authState?: string;
  isPublicRoute: boolean;
}

const App = (props: Props) => {
  const { authState, location, history, isPublicRoute } = props;
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  if (!authContext) {
    throw new Error(
      'App component must be used within a Auth Context Provider'
    );
  }
  if (!layoutContext) {
    throw new Error(
      'App component must be used within a Layout Context Provider'
    );
  }
  const {
    signedIn,
    isAuthorized,
    subscriptionStatus,
    isSuspended
  } = authContext;
  const {
    darkerBackground,
    globalSiteErrors,
    leftDrawerOpen,
    pageTitle,
    rightDrawerOpen,
    pushGlobalSiteAlerts,
    removeGlobalSiteError
  } = layoutContext;

  useEffect(() => {
    if (isSuspended) {
      pushGlobalSiteAlerts([GlobalSiteAlertTypes.accountSuspended]);
    } else if (subscriptionStatus === BillingSubscriptionStatus.past_due) {
      pushGlobalSiteAlerts([GlobalSiteAlertTypes.accountPastDue]);
    }
  }, [pushGlobalSiteAlerts, subscriptionStatus, isSuspended]);

  const { replace: historyReplace } = history;
  const { search: locationSearch, pathname: locationPathname } = location;
  const queryParams = queryString.parse(locationSearch);
  const querySessionId = toString(get(queryParams, 'session_id'));
  const queryBillingUpdate = toString(get(queryParams, 'billing_update'));
  useEffect(() => {
    if (querySessionId) {
      if (queryBillingUpdate === 'unsuspended') {
        pushGlobalSiteAlerts([GlobalSiteAlertTypes.accountReactivated]);
      } else if (queryBillingUpdate) {
        pushGlobalSiteAlerts([GlobalSiteAlertTypes.billingUpdated]);
      } else {
        pushGlobalSiteAlerts([GlobalSiteAlertTypes.tierSelected]);
      }
      historyReplace({
        pathname: locationPathname
      });
    }
  }, [
    historyReplace,
    locationPathname,
    pushGlobalSiteAlerts,
    querySessionId,
    queryBillingUpdate
  ]);

  if (authState !== 'signedIn' && !isPublicRoute) {
    return null;
  }
  if (!(signedIn && isAuthorized) && !isPublicRoute) {
    return <LinearProgress />;
  }

  return (
    <ErrorBoundary>
      <Div100vh>
        <div
          className={classnames([
            classes.App,
            classes.Site,
            {
              [classes.hasRightDrawer]: rightDrawerOpen
            },
            {
              [classes.hasDarkerBackground]: darkerBackground
            }
          ])}
        >
          <Helmet>
            <title>{getPageTitle(pageTitle)}</title>
          </Helmet>
          <div className={classes.SiteContent}>
            {!isPublicRoute && <Header />}
            <Suspense
              fallback={<LinearProgress className={classes.suspenseLoader} />}
            >
              <div
                role="main"
                className={classnames(classes.mainContent, {
                  [classes.contentShift]: rightDrawerOpen && !isPublicRoute,
                  [classes.mainContentSignedIn]: isAuthorized && signedIn,
                  [classes.leftDrawerOpen]: leftDrawerOpen && !isPublicRoute,
                  [classes.mainContentPublic]: isPublicRoute
                })}
              >
                {pageTitle && !isPublicRoute && (
                  <Typography
                    variant="h5"
                    component="h1"
                    className={classes.pageTitle}
                  >
                    <BreadCrumbNavigation classes={classes} />
                    {pageTitle}
                  </Typography>
                )}

                <div
                  className={classnames(classes.screenContainer, {
                    [classes.screenContainerPublic]: isPublicRoute
                  })}
                >
                  <Switch>
                    <Redirect exact={true} path="/" to="/dashboard" />
                    {PrivateScreenRoutes.map((props, index) => (
                      <PrivateRoute {...props} key={index} />
                    ))}
                    {PublicScreenRoutes.map((props, index) => (
                      <Route {...props} key={index} />
                    ))}
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </div>
            </Suspense>
          </div>
          {globalSiteErrors.length > 0 && (
            <Snackbar
              open
              className={classes.errorSnackbar}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              autoHideDuration={30000}
            >
              <>
                {globalSiteErrors.map(
                  (
                    globalSiteError: string | React.ReactChild,
                    errorIndex: number
                  ) => (
                    <Alert
                      className={classes.errorAlert}
                      key={`error-alert-${errorIndex}`}
                      variant="filled"
                      severity="error"
                      onClose={() => removeGlobalSiteError(errorIndex)}
                    >
                      {globalSiteError}
                    </Alert>
                  )
                )}
              </>
            </Snackbar>
          )}
          {!isPublicRoute && <div role="contentinfo">{<Footer />}</div>}
        </div>
      </Div100vh>
    </ErrorBoundary>
  );
};

export default withRouter(App);
